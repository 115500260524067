class i18n {
	#language = 'ru';

	#storage = {};

	constructor(language) {
		this.#language = language;

		this.#storage[language] = {};
	}

	/** @return this */
	set(key, value) {
		this.#storage[this.#language][key] = value;

		return this;
	}

	/** @return any */
	get(key, ...data) {

		let str = this.#storage[this.#language][key];

		if(typeof str == 'undefined'){ return ''; }

		let param = '%';

		for(let i = 0; i < data.length; i++){
			let value = data[i];

			let search = str.indexOf(param);

			if(search === -1 || typeof value == 'undefined'){ break; }

			let replace1 = str.substr(0, search);
			let replace2 = str.substr(search+1);

			str = replace1+value+replace2;
		}

		return str;
	}

	/** @return this */
	delete(key) {

		if(!this.has(key)){ return this; }

		delete this.#storage[this.#language][key];

		return this;
	}

	/** @return boolean */
	has(key) {
		return typeof this.#storage[this.#language][key] != 'undefined';
	}
}

class PipUI {
	static VERSION = '2.0.0';

	static enable_compatible = true;

	static #dependencies = {}

	static #components = {}

	static #i18 = {};

	static language = 'ru';



	static required(component, needle, version, operator) {
		if(typeof operator == 'undefined'){
			operator = '=';
		}

		this.#dependencies[component].push({
			name: needle,
			version: version,
			operator: operator
		});
	}



	static addComponent(name, version) {
		this.#components[name] = version;
		this.#dependencies[name] = [];
	}



	/** @return {boolean} */
	static componentExists(name) {
		return typeof this.#components[name] != 'undefined';
	}



	static componentVersion(name) {
		if(!this.componentExists(name)){ return null; }

		return this.#components[name];
	}



	// <, >, <=, >=, =, !=, <>
	static version_compare(v1, v2, operator) {

		if(typeof operator == 'undefined'){
			operator = '=';
		}

		if(operator == '=' || operator == '==' || operator == '==='){
			return v1 === v2;
		}

		if(operator == '!=' || operator == '<>' || operator == '!=='){
			return v1 !== v2;
		}

		let i, x;
		let compare = 0;

		let vm = {
			'dev': -6,
			'alpha': -5,
			'a': -5,
			'beta': -4,
			'b': -4,
			'RC': -3,
			'rc': -3,
			'#': -2,
			'p': 1,
			'pl': 1
		};

		let _prepVersion = (v) => {
			v = ('' + v).replace(/[_\-+]/g, '.');
			v = v.replace(/([^.\d]+)/g, '.$1.').replace(/\.{2,}/g, '.');
			return (!v.length ? [-8] : v.split('.'))
		};

		let _numVersion = (v) => {
			return !v ? 0 : (isNaN(v) ? vm[v] || -7 : parseInt(v, 10))
		};

		v1 = _prepVersion(v1);
		v2 = _prepVersion(v2);
		x = Math.max(v1.length, v2.length);

		for(i = 0; i < x; i++){
			if(v1[i] === v2[i]){ continue }

			v1[i] = _numVersion(v1[i]);
			v2[i] = _numVersion(v2[i]);

			if(v1[i] < v2[i]){
				compare = -1;
				break
			}else if(v1[i] > v2[i]){
				compare = 1;
				break
			}
		}

		if(!operator){ return compare; }

		switch(operator){
			case '>':
			case 'gt': return (compare > 0);
			case '>=':
			case 'ge': return (compare >= 0);
			case '<=':
			case 'le': return (compare <= 0);
			case '<':
			case 'lt': return (compare < 0);
		}

		return null;
	}



	static compatible() {
		Object.keys(this.#dependencies).forEach(k => {
			if(!this.#dependencies[k].length){ return; }

			this.#dependencies[k].forEach(require => {
				if(!this.componentExists(require.name)){
					console.warn('[PipUI] '+ this.i18n().get('base.requires', k, require.name));

					return;
				}

				if(!this.version_compare(this.componentVersion(require.name), require.version, require.operator)){
					console.warn('[PipUI] '+this.i18n().get('base.requires_version', k, require.name, require.operator, require.version));
				}
			});
		});
	}



	/** @return {i18n} */
	static i18n(language) {
		if(typeof language == 'undefined'){ language = this.language; }

		if(typeof this.#i18[language] != 'undefined'){ return this.#i18[language]; }

		this.#i18[language] = new i18n(language);

		return this.#i18[language];
	}



	/** @return {HTMLCollection|NodeList|array.<HTMLElement>} */
	static e(element) {
		if(typeof element == 'object'){
			return Array.isArray(element) ? element : [element];
		}

		return document.querySelectorAll(element);
	}



	/** @return {boolean} */
	static isObject(object) {
		return object !== null && typeof object == 'object' && !Array.isArray(object);
	}



	/**
	 * @return {object}
	 * */
	static #assignCallback(result, object) {

		for(const [k, v] of Object.entries(object)){

			if(!this.isObject(v) || HTMLElement.prototype.isPrototypeOf(v)){
				result[k] = v;

				continue;
			}

			if(!this.isObject(result[k])){
				result[k] = {};
			}

			result[k] = this.#assignCallback(result[k], v);
		}

		return result;
	}



	/**
	 * @param {object} source
	 *
	 * @param [args]
	 *
	 * @return {object}
	 * */
	static assign(source, ...args) {

		if(args.length <= 0){ return source; }

		if(typeof source != 'object'){ source = {}; }

		let result = source;

		for(let arg of args){
			if(typeof arg != 'object'){ continue; }

			result = this.#assignCallback(result, arg);
		}

		return result;
	}



	/**
	 * @param {array} array
	 *
	 * @return {array}
	 * */
	static arrayUnique(array){
		var result = [];

		for(var i = 0; i < array.length; i++){
			if(result.indexOf(array[i]) === -1){
				result.push(array[i]);
			}
		}

		return result;
	}



	/**
	 * @param {array} haystack
	 *
	 * @param {any} needle
	 *
	 * @return {int}
	 * */
	static indexOfCase(haystack, needle) {
		var index = -1;

		needle = needle.toLowerCase();

		if(typeof haystack == 'object'){
			for(var i = 0; i < haystack.length; i++){
				if(haystack[i].toLowerCase() == needle){
					index = i; break;
				}
			}
		}else{
			index = haystack.toLowerCase().indexOf(needle);
		}

		return index;
	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @param {string} query
	 *
	 * @return {array.<HTMLElement>}
	 *
	 * */
	static children(element, query) {
		if(typeof element != 'object' || element === null){ return []; }

		return element.querySelectorAll(':scope > '+query);
	}



	/**
	 * @param {HTMLElement|NodeList|HTMLCollection|Array<HTMLElement>|string} elements
	 *
	 * @param {string} classname
	 *
	 * @return {HTMLElement|NodeList|HTMLCollection|Array<HTMLElement>}
	 * */
	static addClass(elements, classname) {
		if(typeof elements == 'string'){
			elements = this.e(elements);
		}

		if(typeof elements != 'object' || elements === null){ return elements; }

		classname = classname.replaceAll(/\s+/g, ' ').trim();

		if(typeof classname == 'string'){
			classname = classname.split(' ');
		}

		if(HTMLElement.prototype.isPrototypeOf(elements)){
			elements.classList.add(...classname);

			return elements;
		}

		elements.forEach(item => {
			item.classList.add(...classname);
		});

		return elements;
	}



	/**
	 * @param {HTMLElement|NodeList|HTMLCollection|Array|string} elements
	 *
	 * @param {string} classname
	 *
	 * @return {HTMLElement|NodeList|HTMLCollection|Array}
	 * */
	static removeClass(elements, classname) {
		if(typeof elements == 'string'){
			elements = this.e(elements);
		}

		if(typeof elements != 'object' || elements === null){ return elements; }

		classname = classname.replaceAll(/\s+/g, ' ').trim();

		if(typeof classname == 'string'){
			classname = classname.split(' ');
		}

		if(HTMLElement.prototype.isPrototypeOf(elements)){
			elements.classList.remove(...classname);

			return elements;
		}

		elements.forEach(item => {
			item.classList.remove(...classname);
		});

		return elements;
	}



	/**
	 * @param {string} html
	 *
	 * @return {HTMLElement}
	 * */
	static create(html) {
		let div = document.createElement('div');

		div.innerHTML = html.trim();

		return div.firstChild;
	}



	/**
	 * @param {HTMLElement|string|Array<HTMLElement>|HTMLCollection} element
	 *
	 * @param {string|object|array} key
	 *
	 * @param {any} value
	 *
	 * @return {HTMLElement|string|array.<string>}
	 * */
	static style(element, key, value) {
		if(typeof element == 'string'){
			element = this.e(element);
		}

		if(HTMLElement.prototype.isPrototypeOf(element)){
			element = [element];
		}

		if(typeof element != 'object' || element === null){ return element; }

		if(typeof value == 'undefined'){

			if(Array.isArray(key)){

				let list = [];

				element.forEach((item, k) => {
					list[k] = {};

					let computed = window.getComputedStyle(item);

					key.forEach(value => {
						list[k][value] = computed.getPropertyValue(value);
					});
				});

				return list;
			}else if(typeof key == 'string'){

				let list = [];

				element.forEach(item => {
					let obj = {};

					obj[key] = window.getComputedStyle(item).getPropertyValue(key);

					list.push(obj);
				});

				return list;
			}

			element.forEach(item => {
				Object.assign(item.style, key);
			});

			return element;
		}

		element.forEach(item => {
			item.style[key] = value;
		});

		return element;
	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @param {string} eventName
	 *
	 * @param {function|undefined} callback
	 *
	 * @param {boolean} remove
	 * */
	static listenEvent(element, eventName, callback, remove) {
		if(typeof element != 'object' || element === null){ return; }

		if(remove){
			element.removeEventListener(eventName, callback);

			return;
		}

		let cb = (e) => {

			var details = e.detail;

			if(typeof callback == 'function'){
				if(typeof e.detail != 'object' || typeof e.detail[Symbol.iterator] !== 'function') {

					details = [e.detail];
				}

				callback(e, ...details);
			}
		}

		element.addEventListener(eventName, cb);
	}



	/**
	 * @param {HTMLElement|Array<HTMLElement>|string} element
	 *
	 * @param {string} eventName
	 *
	 * @param {any} details
	 * */
	static trigger(element, eventName, ...details) {
		if(typeof element == 'string'){
			element = this.e(element);
		}

		if(typeof element != 'object' || element === null){ return; }

		let params = {
			bubbles: true,
			cancelable: true,
			detail: details
		};

		if(HTMLElement.prototype.isPrototypeOf(element)){
			element.dispatchEvent(new CustomEvent(eventName, params));
		}else{
			element.forEach(item => {
				item.dispatchEvent(new CustomEvent(eventName, params));
			});
		}

	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @return {boolean}
	 * */
	static isVisible(element) {
		if(typeof element != 'object' || element === null){ return false; }

		let styles = this.style(element, ['display', 'visibility', 'opacity', 'width', 'height'])[0];

		return !(styles.display == 'none' ||
			styles.visibility == 'hidden' ||
			styles.opacity == '0' ||
			styles.opacity == '0%');
	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @param {string} classname
	 *
	 * @return {boolean}
	 * */
	static hasClass(element, classname) {
		if(typeof element != 'object' || element === null){ return false; }

		return element.classList.contains(classname);
	}



	/**
	 * @param {HTMLElement|string|Array<HTMLElement>} element
	 *
	 * @param {string} classname
	 *
	 * @return {HTMLElement}
	 * */
	static toggleClass(element, classname) {
		if(typeof element == 'string'){
			element = this.e(element);
		}

		if(HTMLElement.prototype.isPrototypeOf(element)){
			if(this.hasClass(element, classname)){
				this.removeClass(element, classname);
			}else{
				this.addClass(element, classname);
			}
		}else{
			element.forEach(item => {
				if(PipUI.hasClass(item)){
					this.removeClass(item, classname);
				}else{
					this.addClass(item, classname);
				}
			});
		}


		return element;
	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @param {string|undefined} classname
	 *
	 * @return {array}
	 * */
	static siblings(element, classname) {

		let nextSibling = element.nextElementSibling;

		let list = [];

		while(nextSibling){
			if(classname){
				if(this.hasClass(nextSibling, classname)){
					list.push(nextSibling);
				}
			}else{
				list.push(nextSibling);
			}

			nextSibling = nextSibling.nextElementSibling;
		}

		let prevSibling = element.previousElementSibling;

		while(prevSibling){
			if(classname){
				if(this.hasClass(prevSibling, classname)){
					list.unshift(prevSibling);
				}
			}else{
				list.unshift(prevSibling);
			}

			prevSibling = prevSibling.previousElementSibling;
		}

		return list;
	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @param {function|undefined} callback
	 * */
	static ready(element, callback) {
		if(typeof element != 'object' || element === null){ return; }

		this.listenEvent(element, 'DOMContentLoaded', (event) => {
			if(typeof callback == 'function'){
				callback(event);
			}
		});
	}



	/**
	 * @param {HTMLElement} element
	 *
	 * @param {string|null|HTMLElement} selector
	 *
	 * */
	static closest(element, selector) {
		if(typeof selector == 'string'){
			return element.closest(selector);
		}

		let node = selector;

		while(node !== null){

			if(element == node){
				return node;
			}

			node = node.parentNode;
		}

		return null;
	}



	/**
	 * @param {object} event
	 *
	 * @param {HTMLElement|string} element
	 *
	 * @param {function} callback
	 * */
	static body(event, element, callback) {

		if(typeof callback != 'function'){ return; }

		document.body.addEventListener(event, e => {

			let target = e.target;

			let find = target.closest(element);

			if(find){
				callback(e, find);
			}
		});
	}


}

PipUI.i18n()
	.set('base.requires', '[Base] Компонент "%" требует наличия компонента %')
	.set('base.requires_version', '[Base] Компонент % требует наличия компонента % версии % %');

/** @return {PipUI} */
const pipui = p = PipUI;

PipUI.addComponent('Base', PipUI.VERSION);

PipUI.ready(document, () => {
	if(PipUI.enable_compatible){
		PipUI.compatible();
	}

	PipUI.body('click', '.preventDefault', e => { e.preventDefault(); });
});
if(typeof PipUI != 'undefined'){
    PipUI.i18n()
        .set('date._jan', 'Янв')
        .set('date._feb', 'Фев')
        .set('date._mar', 'Мар')
        .set('date._apr', 'Апр')
        .set('date._may', 'Май')
        .set('date._jun', 'Июн')
        .set('date._jul', 'Июл')
        .set('date._aug', 'Авг')
        .set('date._sep', 'Сен')
        .set('date._oct', 'Окт')
        .set('date._nov', 'Ноя')
        .set('date._dec', 'Дек')

        .set('date.jan', 'Январь')
        .set('date.feb', 'Февраль')
        .set('date.mar', 'Март')
        .set('date.apr', 'Апрель')
        .set('date.may', 'Май')
        .set('date.jun', 'Июнь')
        .set('date.jul', 'Июль')
        .set('date.aug', 'Август')
        .set('date.sep', 'Сентябрь')
        .set('date.oct', 'Октябрь')
        .set('date.nov', 'Ноябрь')
        .set('date.dec', 'Декабрь')

        .set('date._mo', 'Пн')
        .set('date._tu', 'Вт')
        .set('date._we', 'Ср')
        .set('date._th', 'Чт')
        .set('date._fr', 'Пт')
        .set('date._sa', 'Сб')
        .set('date._su', 'Вс')

        .set('date.mo', 'Понедельник')
        .set('date.tu', 'Вторник')
        .set('date.we', 'Среда')
        .set('date.th', 'Четверг')
        .set('date.fr', 'Пятница')
        .set('date.sa', 'Суббота')
        .set('date.su', 'Воскресенье')

        .set('date.hours', 'Часы')
        .set('date.minutes', 'Минуты')
        .set('date.seconds', 'Секунды');
}

class DateComponent {
    static VERSION = '1.0.0';

    static #tmp = new Date();

    static months = [
        PipUI.i18n().get('date.jan'), PipUI.i18n().get('date.feb'), PipUI.i18n().get('date.mar'),
        PipUI.i18n().get('date.apr'), PipUI.i18n().get('date.may'), PipUI.i18n().get('date.jun'),
        PipUI.i18n().get('date.jul'), PipUI.i18n().get('date.aug'), PipUI.i18n().get('date.sep'),
        PipUI.i18n().get('date.oct'), PipUI.i18n().get('date.nov'), PipUI.i18n().get('date.dec')
    ];

    static _months = [
        PipUI.i18n().get('date._jan'), PipUI.i18n().get('date._feb'), PipUI.i18n().get('date._mar'),
        PipUI.i18n().get('date._apr'), PipUI.i18n().get('date._may'), PipUI.i18n().get('date._jun'),
        PipUI.i18n().get('date._jul'), PipUI.i18n().get('date._aug'), PipUI.i18n().get('date._sep'),
        PipUI.i18n().get('date._oct'), PipUI.i18n().get('date._nov'), PipUI.i18n().get('date._dec')
    ];

    static _week = [
        PipUI.i18n().get('date._mo'), PipUI.i18n().get('date._tu'), PipUI.i18n().get('date._we'),
        PipUI.i18n().get('date._th'), PipUI.i18n().get('date._fr'), PipUI.i18n().get('date._sa'),
        PipUI.i18n().get('date._su')
    ];

    static week = [
        PipUI.i18n().get('date.mo'), PipUI.i18n().get('date.tu'), PipUI.i18n().get('date.we'),
        PipUI.i18n().get('date.th'), PipUI.i18n().get('date.fr'), PipUI.i18n().get('date.sa'),
        PipUI.i18n().get('date.su')
    ];



    /**
     * @param {number} num
     *
     * @return {number}
     * */
    static leadingZero(num){
        return num < 10 ? '0'+num : num;
    }



    /**
     * @param {int} year
     *
     * @param {int} month
     *
     * @return {int}
     * */
    static daysInMonth(year, month){
        this.#tmp.setFullYear(year);

        this.#tmp.setMonth(month);

        this.#tmp.setDate(32);

        return 32 - this.#tmp.getDate();
    }



    /**
     * @see https://www.php.net/manual/datetime.format.php
     *
     * @param {Date} date
     *
     * @param {string} str
     *
     * @return {string}
     * */
    static format(date, str){
        if(!date){ return str; }

        let _d = date.getDate();

        let _m = date.getMonth();

        let _Y = date.getFullYear();

        let _D = date.getDay();

        let _G = date.getHours();

        let _i = date.getMinutes();

        let _s = date.getSeconds();


        let d = this.leadingZero(_d);

        let D = this._week[_D];

        let l = this.week[D];

        let N = _D == 0 ? 7 : _D;

        let start = new Date(_Y, 0, 0).getTime();

        let days = (d - start) / 1000 / 24;

        let z = Math.floor(days);

        let W = Math.floor(days / 7);

        let F = this.months[_d];

        let m = this.leadingZero(_m+1);

        let M = this._months[_m];

        let t = this.daysInMonth(_Y, _m);

        let L = _Y % 400 == 0 || (_Y % 4 == 0 && _Y % 100 != 0) ? 1 : 0;

        let y = _Y.toString().substr(-2);

        let a = _G >= 12 ? 'pm' : 'am';

        let A = a.toUpperCase();

        let g = a == 'pm' && _G == 0 ? 12 : _G % 12;

        let h = this.leadingZero(g);

        let H = this.leadingZero(_G);

        let i = this.leadingZero(_i);

        let s = this.leadingZero(_s);

        let v = date.getMilliseconds();

        let u = v*1000;


        let offset = date.getTimezoneOffset();

        let ofDiv = parseInt(offset / 60);

        let otDiv = offset % 60;

        let sign = '+';

        if(offset < 0){
            ofDiv *= -1;
            otDiv *= -1;
            sign = '-';
        }

        let of = [sign, this.leadingZero(ofDiv), otDiv];

        let O = of.join();

        let P = of[0]+of[1]+':'+of[2];

        let p = P == '+00:00' ? 'Z' : P;

        let Z = offset * 60;

        let c = date.toISOString();

        let r = D+', '+_d+' '+M+' '+_Y+' '+H+':'+i+':'+s;

        let U = parseInt(date.getTime() / 1000);

        let seconds = _s + (_i * 60) + (_G * 3600);

        let B = parseInt(seconds / 86.4);



        // День месяца, 2 цифры с ведущим нулём от 01 до 31
        str = str.replace(/d/gm, d);

        // Текстовое представление дня недели, 3 символа 	от Mon до Sun
        str = str.replace(/D/gm, D);

        // День месяца без ведущего нуля от 1 до 31
        str = str.replace(/j/gm, _d);

        // Полное наименование дня недели 	от Sunday до Saturday
        str = str.replace(/l/gm, l);

        // Порядковый номер дня недели в соответствии со стандартом ISO 8601 	от 1 (понедельник) до 7 (воскресенье)
        str = str.replace(/N/gm, N);

        // Порядковый номер дня недели 	от 0 (воскресенье) до 6 (суббота)
        str = str.replace(/w/gm, _D);

        // Порядковый номер дня в году (начиная с 0) 	От 0 до 365
        str = str.replace(/z/gm, z);

        // Порядковый номер недели года в соответствии со стандартом ISO 8601; недели начинаются с понедельника 	Например: 42 (42-я неделя года)
        str = str.replace(/W/gm, W);

        // Полное наименование месяца, например, January или March 	от January до December
        str = str.replace(/F/gm, F);

        // Порядковый номер месяца с ведущим нулём 	от 01 до 12
        str = str.replace(/m/gm, m);

        // Сокращённое наименование месяца, 3 символа 	от Jan до Dec
        str = str.replace(/M/gm, M);

        // Порядковый номер месяца без ведущего нуля 	от 1 до 12
        str = str.replace(/n/gm, _m);

        // Количество дней в указанном месяце 	от 28 до 31
        str = str.replace(/t/gm, t);

        // Признак високосного года 	1, если год високосный, иначе 0.
        str = str.replace(/L/gm, L);

        // Полное числовое представление года, не менее 4 цифр, с - для годов до нашей эры. 	Примеры: -0055, 0787, 1999, 2003, 10191.
        str = str.replace(/Y/gm, _Y);

        // Номер года, 2 цифры 	Примеры: 99, 03
        str = str.replace(/y/gm, y);

        // Ante meridiem (лат. "до полудня") или Post meridiem (лат. "после полудня") в нижнем регистре 	am или pm
        str = str.replace(/a/gm, a);

        // Ante meridiem или Post meridiem в верхнем регистре 	AM или PM
        str = str.replace(/A/gm, A);

        // Время в формате Интернет-времени (альтернативной системы отсчёта времени суток) 	от 000 до 999
        str = str.replace(/B/gm, B);

        // Часы в 12-часовом формате без ведущего нуля 	от 1 до 12
        str = str.replace(/g/gm, g);

        // Часы в 24-часовом формате без ведущего нуля 	от 0 до 23
        str = str.replace(/G/gm, _G);

        // Часы в 12-часовом формате с ведущим нулём 	от 01 до 12
        str = str.replace(/h/gm, h);

        // Часы в 24-часовом формате с ведущим нулём 	от 00 до 23
        str = str.replace(/H/gm, H);

        // Минуты с ведущим нулём 	от 00 до 59
        str = str.replace(/i/gm, i);

        // Секунды с ведущим нулём 	от 00 до 59
        str = str.replace(/s/gm, s);

        // Микросекунды. Учтите, что date() всегда будет возвращать 000000, т.к. она принимает целочисленный (int) параметр, тогда как DateTime::format() поддерживает микросекунды, если DateTime создан с ними. 	Например: 654321
        str = str.replace(/u/gm, u);

        // Миллисекунды. Замечание такое же как и для u. 	Пример: 654
        str = str.replace(/v/gm, v);

        // Разница с временем по Гринвичу без двоеточия между часами и минутами 	Например: +0200
        str = str.replace(/O/gm, O);

        // Разница с временем по Гринвичу с двоеточием между часами и минутами 	Например: +02:00
        str = str.replace(/P/gm, P);

        // То же, что и P, но возвращает Z вместо +00:00 (доступен, начиная с PHP 8.0.0) 	Например: Z или +02:00
        str = str.replace(/p/gm, p);

        // Смещение часового пояса в секундах. Для часовых поясов, расположенных западнее UTC, возвращаются отрицательные числа, а для расположенных восточнее UTC - положительные.
        str = str.replace(/Z/gm, Z);

        // Дата в формате стандарта ISO 8601 	2004-02-12T15:19:21+00:00
        str = str.replace(/c/gm, c);

        // Дата в формате » RFC 222/» RFC 5322 	Например: Thu, 21 Dec 2000 16:01:07 +0200
        str = str.replace(/r/gm, r);

        // Количество секунд, прошедших с начала Эпохи Unix (1 января 1970 00:00:00 GMT)
        str = str.replace(/U/gm, U);

        return str;
    }
}

if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Date', DateComponent.VERSION);
    /** @return {DateComponent} */
    PipUI.Date = DateComponent;
}
class LoggerComponent {
    static VERSION = '1.0.0';


    /**
     * @param {string} message
     *
     * @param {any} args
     * */
    static info = (message, ...args) => {
        console.info('[PipUI] '+message);

        for(let i = 0; i < args.length; i++) {
            console.info(args[i]);
        }
    }


    /**
     * @param {string} message
     *
     * @param {any} args
     * */
    static warn = (message, ...args) => {
        console.warn('[PipUI] '+message);

        for(let i = 0; i < args.length; i++) {
            console.warn(args[i]);
        }
    }


    /**
     * @param {string} message
     *
     * @param {any} args
     * */
    static error = (message, ...args) => {
        console.error('[PipUI] '+message);

        for(let i = 0; i < args.length; i++) {
            console.error(args[i]);
        }
    }


    /**
     * @param {string} message
     *
     * @param {any} args
     * */
    static log = (message, ...args) => {
        console.log('[PipUI] '+message);

        for(let i = 0; i < args.length; i++) {
            console.log(args[i]);
        }
    }
}

if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Logger', LoggerComponent.VERSION);
    /** @return {LoggerComponent} */
    PipUI.Logger = LoggerComponent;
}
class StorageComponent {
    static VERSION = '1.0.0';

    static #storage = {}

    /**
     * @param {string} key
     *
     * @param {string|undefined} subkey
     *
     * @return {any}
     * */
    static get(key, subkey) {

        if(typeof subkey != 'undefined'){
            if(typeof this.#storage[key] == 'undefined'){ return; }

            return this.#storage[key][subkey];
        }

        return this.#storage[key];
    }

    /**
     * @param {string} key
     *
     * @param {any} value
     *
     * @param {string|undefined} subkey
     * */
    static set(key, value, subkey) {

        if(typeof subkey != 'undefined'){
            if(typeof this.#storage[key] == 'undefined'){
                this.#storage[key] = {};
            }

            this.#storage[key][subkey] = value;
        }else{
            this.#storage[key] = value;
        }
    }

    /**
     * @param {string} key
     *
     * @param {string|undefined} subkey
     * */
    static delete(key, subkey) {
        if(typeof this.#storage[key] == 'undefined'){ return; }

        if(typeof subkey != 'undefined'){
            if(typeof this.#storage[key][subkey] != 'undefined'){
                delete this.#storage[key][subkey];
            }

            return;
        }

        delete this.#storage[key];
    }

    /**
     * @return {object}
     * */
    static list() {
        return this.#storage;
    }
}

if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Storage', StorageComponent.VERSION);
    /** @return {StorageComponent} */
    PipUI.Storage = StorageComponent;
}
class AnimationComponent {
    static VERSION = '1.0.0';

    #options = {
        debug: false
    }

    /** @return {HTMLElement} */
    #target;

    #animations = {};

    #effects = {};

    #callbacks = {};



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    getOptions() {
        return this.#options;
    }

    constructor(element, options) {
        this.#target = PipUI.e(element);

        this.fadeIn = this.fadeIn.bind(this);

        this.fadeOut = this.fadeOut.bind(this);

        this.slideUp = this.slideUp.bind(this);

        this.slideDown = this.slideDown.bind(this);

        this.slideLeft = this.slideLeft.bind(this);

        this.slideRight = this.slideRight.bind(this);

        this.hide = this.hide.bind(this);

        this.show = this.show.bind(this);

        this.animate = this.animate.bind(this);

        this.setOptions(options);

        if(this.#options.debug){ PipUI.Logger.info('[Animation] Animation inited'); }
    }

    #run(name, keyframes, options, finishCallback, completeCallback){
        if(this.#options.debug){ PipUI.Logger.info('[Animation] Starting '+name+' animation'); }

        for(let i = 0; i < this.#target.length; i++){

            let target = this.#target[i];

            if(typeof target.animation_id == 'undefined'){
                target.animation_id = Math.random().toString();
            }

            keyframes = keyframes(target);

            let self = this;

            this.#effects[target.animation_id] = new KeyframeEffect(target, keyframes, options);

            this.#animations[target.animation_id] = new Animation(this.#effects[target.animation_id], target.ownerDocument.timeline);

            this.#animations[target.animation_id].play();

            this.#callbacks[target.animation_id] = () => {
                if(self.#options.debug){ PipUI.Logger.info('[Animation] Animation '+name+' complete'); }

                if(typeof finishCallback == 'function'){
                    finishCallback(target);
                }

                if(typeof completeCallback == 'function'){ completeCallback(target); }

                this.#animations[target.animation_id].removeEventListener('finish', this.#callbacks[target.animation_id]);

                this.#callbacks[target.animation_id] = null;

                this.#animations[target.animation_id] = null;

                this.#effects[target.animation_id] = null;
            }

            this.#animations[target.animation_id].addEventListener("finish", this.#callbacks[target.animation_id]);
        }

        return this;
    }



    /**
     * @param {object} options
     *
     * @param {array} keyframes
     *
     * @param {function|undefined} complete
     * */
    animate(options, keyframes, complete){
        return this.#run('animate', () => keyframes, options, null, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    fadeIn(options, complete){
        return this.#run('fadeIn', target => {
            let styles = PipUI.style(target, ['display', 'opacity', 'visibility'])[0];

            PipUI.style(target, {
                display: styles.display == 'none' ? 'block' : null,
                visibility: styles.visibility == 'hidden' ? 'visible' : null,
            });

            return [{opacity: 0}, {opacity: styles.opacity}];
        }, options, null, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    fadeOut(options, complete){
        return this.#run('fadeOut', () => [{opacity: 0}], options, target => {
            PipUI.style(target, {display: 'none'});
        }, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    slideDown(options, complete){

        return this.#run('slideDown', target => {
            let styles = PipUI.style(target, ['height', 'display', 'min-height', 'visibility', 'padding-top', 'padding-bottom', 'margin-top', 'margin-bottom', 'border-width'])[0];

            PipUI.style(target, {
                display: styles.display == 'none' ? 'block' : null,
                visibility: styles.visibility == 'hidden' ? 'visible' : null,
                overflow: 'hidden'
            });

            return [
                {height: 0, minHeight: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, borderWidth: 0},
                {height: target.offsetHeight+'px', minHeight: styles['min-height'], paddingTop: styles['padding-top'], paddingBottom: styles['padding-bottom'], marginTop: styles['margin-top'], marginBottom: styles['margin-bottom'], borderWidth: styles['border-width']}
            ];
        }, options, target => {
            PipUI.style(target, {overflow: null});
        }, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    slideUp(options, complete){

        return this.#run('slideUp', target => {
            PipUI.style(target, {overflow: 'hidden', width: target.offsetWidth+'px'});

            return [
                {height: target.offsetHeight+'px'},
                {paddingTop: 0, height: 0, minHeight: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, borderWidth: 0}
            ]
        }, options, target => {
            PipUI.style(target, {display: 'none', width: null, overflow: null});
        }, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    slideLeft(options, complete){
        return this.#run('slideLeft', target => {
            PipUI.style(target, {overflow: 'hidden', height: target.offsetHeight+'px'});

            return [
                {width: target.offsetWidth+'px'},
                {paddingLeft: 0, width: 0, minWidth: 0, paddingRight: 0, marginLeft: 0, marginRight: 0, borderWidth: 0},
            ];
        }, options, target => {
            PipUI.style(target, {display: 'none', height: null, overflow: null});
        }, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    slideRight(options, complete){

        return this.#run('slideRight', target => {
            let styles = PipUI.style(target, ['width', 'height', 'min-width', 'visibility', 'display', 'padding-left', 'padding-right', 'margin-left', 'margin-right', 'border-width'])[0];

            PipUI.style(target, {
                display: styles.display == 'none' ? 'block' : null,
                visibility: styles.visibility == 'hidden' ? 'visible' : null,
                overflow: 'hidden'
            });

            PipUI.style(target, 'height', target.offsetHeight+'px');

            return [
                {paddingLeft: 0, width: 0, minWidth: 0, paddingRight: 0, marginLeft: 0, marginRight: 0, borderWidth: 0},
                {width: target.offsetWidth+'px', minWidth: styles['min-width'], paddingLeft: styles['padding-left'], paddingRight: styles['padding-right'], marginLeft: styles['margin-left'], marginRight: styles['margin-right'], borderWidth: styles['border-width']}
            ]
        }, options, target => {
            PipUI.style(target, {height: null, overflow: null});
        }, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    hide(options, complete){

        return this.#run('hide', target => {
            PipUI.style(target, {overflow: 'hidden'});

            return [
                {width: target.offsetWidth+'px', height: target.offsetHeight+'px'},
                {padding: 0, fontSize: 0, width: 0, height: 0, minWidth: 0, minHeight: 0, opacity: 0, margin: 0, borderWidth: 0},
            ];
        }, options, target => {
            PipUI.style(target, {display: 'none', overflow: null});
        }, complete);
    }



    /**
     * @param {object} options
     *
     * @param {function|undefined} complete
     * */
    show(options, complete){

        this.#run('show', target => {

            PipUI.style(target, {
                display: PipUI.style(target, 'display') == 'none' ? 'block' : null,
                visibility: 'visible',
                overflow: 'hidden'
            });

            let styles = PipUI.style(target, ['opacity', 'min-width', 'min-height', 'visibility', 'font-size', 'display', 'padding', 'margin', 'border-width'])[0];

            return [
                {width: 0, height: 0, opacity: 0, fontSize: 0, minWidth: 0, minHeight: 0, margin: 0, padding: 0, borderWidth: 0},
                {
                    width: target.offsetWidth+'px',
                    height: target.offsetHeight+'px',
                    opacity: styles.opacity,
                    fontSize: styles['font-size'],
                    minWidth: styles['min-width'],
                    minHeight: styles['min-height'],
                    padding: styles['padding'],
                    margin: styles['margin'],
                    borderWidth: styles['border-width']
                }
            ];
        }, options, target => {
            PipUI.style(target, {
                display: null,
                visibility: null,
                overflow: null
            });
        }, complete);
    }
}

if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Animation', AnimationComponent.VERSION);
    PipUI.required('Animation', 'Logger', '1.0.0', '>=');
    /** @return {AnimationComponent} */
    PipUI.Animation = AnimationComponent;
}
class TabsComponent {
	static VERSION = '2.0.0';

	static debug = false;

	static #lock = false;

	static active = e => {
		let id = e.getAttribute('data-tabs-id');

		if(TabsComponent.#lock || typeof id != 'string'){
			if(PipUI.Logger && TabsComponent.debug){
				PipUI.Logger.info(PipUI.i18n().get('tabs.d_lock'), TabsComponent.#lock, id);
			}

			return false;
		}

		let tabs = e.closest('.tabs');

		let links = PipUI.children(tabs, '.tab-links')[0];

		let list = PipUI.children(tabs, '.tab-list')[0];

		let currentTrigger = links.querySelector('.tab-link.active');

		let currentTarget = PipUI.children(list, '.tab-id.active')[0];

		if(currentTrigger.getAttribute('data-tabs-id') == id){ return false; }

		let nextTrigger = links.querySelector('.tab-link[data-tabs-id="'+id+'"]');

		let nextTarget = PipUI.children(list, '.tab-id[data-tabs-id="'+id+'"]')[0];

		if(typeof nextTarget == 'undefined'){
			if(PipUI.Logger && TabsComponent.debug){
				PipUI.Logger.info(PipUI.i18n().get('tabs.d_next_undefined'));
			}

			return false;
		}

		TabsComponent.#lock = true;


		PipUI.removeClass(currentTarget, 'active');

		PipUI.removeClass(currentTrigger, 'active');

		PipUI.trigger(currentTarget, 'hide-tabs-pipui', nextTarget, nextTrigger, currentTarget, currentTrigger);

		if(PipUI.Logger && TabsComponent.debug){
			PipUI.Logger.info(PipUI.i18n().get('tabs.d_active_start_complete'));
		}

		PipUI.addClass(nextTarget, 'active');

		PipUI.addClass(nextTrigger, 'active');

		PipUI.trigger(nextTarget, 'show-tabs-pipui', nextTarget, nextTrigger, currentTarget, currentTrigger);

		if(PipUI.Logger && TabsComponent.debug){
			PipUI.Logger.info(PipUI.i18n().get('tabs.d_active'), tabs);
		}

		TabsComponent.#lock = false;

		return true;
	}
}

PipUI.ready(document, () => {
	if(PipUI.Logger && TabsComponent.debug){
		PipUI.Logger.info(PipUI.i18n().get('tabs.d_init'));
	}

	PipUI.body('click', '.tabs .tab-link[data-tabs-id]', (e, target) => {
		e.preventDefault();

		TabsComponent.active(target);
	});
});

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Tabs', TabsComponent.VERSION);
	PipUI.required('Tabs', 'Animations', '1.0.0', '>=');
	/** @return {TabsComponent} */
	PipUI.Tabs = TabsComponent;

	PipUI.i18n()
		.set('tabs.d_lock', '[Tabs] Активация заблокирована из-за отсутсвия идентификатора или незаконченности анимации')
		.set('tabs.d_init', '[Tabs] Инициализация событий')
		.set('tabs.d_active', '[Tabs] Активация вкладки')
		.set('tabs.d_next_undefined', '[Tabs] Выбранный элемент не найден. Активация заблокирована')
		.set('tabs.d_active_start_complete', '[Tabs] Анимация скрытия завешена');
}
class AlertblockComponent {
	static VERSION = '2.0.0';

	/** @return {HTMLElement} */
	#element;

	#id;

	#eventCallback;

	#options = {
		debug: false,
		wrapperTemplate: '<div class="alertblock-wrapper"></div>',
		closeTemplate: '<a href="#" class="alertblock-close" rel="nofollow">&times;</a>',
		before: '',
		after: '',
		class: '',
		closeCallback: undefined,
		closedCallback: undefined,
		showCallback: undefined,
		hideCallback: undefined,
		showedCallback: undefined,
		hidedCallback: undefined,
		hiddenClass: 'alertblock-hidden',
		closedClass: 'alertblock-closed',
		noneClass: 'alertblock-none',
		canClose: false,
		message: ''
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} object
	 *
	 * @param {object|undefined} options
	 *
	 * @param {boolean|undefined} update
	 * */
	constructor(object, options, update) {

		this.#element = PipUI.e(object)[0];

		this.setOptions(options);

		options = this.getOptions();

		if(typeof this.#element == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('alertblock.d_element_not_found'), this.#options);
			}

			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alertblock.d_create_instance'), this.#options);
		}

		this.#id = Math.random().toString();

		this.#element.setAttribute('data-alertblock-id', this.#id);

		PipUI.addClass(this.#element, 'alertblock ' + this.#options.class);

		PipUI.Storage.set('alertblock', this, this.#id);

		if(update){
			if(PipUI.Logger && options.debug){
				PipUI.Logger.info(PipUI.i18n().get('alertblock.d_create_create'), this.#options);
			}

			this.update();
		}else{
			if(PipUI.Logger && options.debug){
				PipUI.Logger.info(PipUI.i18n().get('alertblock.d_create_init'), this.#options);
			}

			this.#init();
		}

		this.#events();
	}


	#events(){

		let self = this;

		this.#eventCallback = (e) => {
			e.preventDefault();

			self.close();
		};

		let close = this.#element.querySelector('.alertblock-close');

		if(close){
			close.addEventListener('click', this.#eventCallback);
		}


		this.#element.addEventListener('transitionend', () => {
			if(PipUI.hasClass(self.#element, self.#options.closedClass)){
				if(typeof self.#options.closedCallback == 'function'){
					self.#options.closedCallback(self.#id, self.#options, self.#element);
				}

				PipUI.trigger(self.#element, 'closed-alertblock-pipui', self.#id, self.#options, self.#element);

				let close = this.#element.querySelector('.alertblock-close');

				if(close){ close.removeEventListener('click', this.#eventCallback); }

				self.#element.remove();
			}else if(PipUI.hasClass(self.#element, self.#options.hiddenClass)){
				if(typeof self.#options.hidedCallback == 'function'){
					self.#options.hided(self.#id, self.#options, self.#element);
				}

				PipUI.trigger(self.#element, 'hided-alertblock-pipui', self.#id, self.#options, self.#element);

				PipUI.addClass(self.#element, self.#options.noneClass);
			}else{
				if(typeof self.#options.showedCallback == 'function'){
					self.#options.showedCallback(self.#id, self.#options, self.#element);
				}

				PipUI.trigger(self.#element, 'showed-alertblock-pipui', self.#id, self.#options, self.#element);
			}
		});

		return this;
	}



	/**
	 * @param {string} message
	 *
	 * @return {this}
	 * */
	setMessage(message) {

		this.#options.message = message;

		PipUI.children(this.#element, '.alertblock-wrapper')[0].innerHTML = this.#options.message;

		return this;
	}



	/** @return {this} */
	update() {
		let close = this.#element.querySelector('.alertblock-close');

		if(close){
			close.removeEventListener('click', this.#eventCallback);
		}

		this.#element.innerHTML = '';

		if(this.#options.before){
			this.#element.innerHTML += '<div class="before">'+this.#options.before+'</div>';
		}

		this.#element.innerHTML += this.#options.wrapperTemplate;

		if(this.#options.after){
			this.#element.innerHTML += '<div class="after">'+this.#options.after+'</div>';
		}

		if(this.#options.canClose){
			this.#element.innerHTML += this.#options.closeTemplate;
		}

		this.setMessage(this.#options.message);

		this.#element.querySelector('.alertblock-close').addEventListener('click', this.#eventCallback);

		PipUI.trigger(this.#element, 'update-alertblock-pipui', this, this.#id, this.#options);

		return this;
	}



	/** @return {this} */
	#init() {

		let wrapper = PipUI.children(this.#element, '.alertblock-wrapper');

		this.#options.message = wrapper.length <= 0 ? '' : wrapper[0].innerHTML;

		this.#options.canClose = PipUI.children(this.#element, '.alertblock-close').length == 1;

		let classlist = [];

		for(let name of this.#element.classList){
			if(name == 'alertblock'){ continue; }

			classlist.push(name);
		}

		this.#options.class = classlist.join(' ');

		let before = PipUI.children(this.#element, '.before');

		let after = PipUI.children(this.#element, '.after');

		if(before.length == 1){
			this.#options.before = before[0].innerHTML;
		}

		if(after.length == 1){
			this.#options.after = after[0].innerHTML;
		}

		return this;
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	close(callback) {
		let self = this;

		if(typeof self.#element == 'undefined'){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alertblock.d_close'), this.#id, this.#options, this.#element);
		}

		PipUI.trigger(this.#element, 'close-alertblock-pipui', this.#id, this.#options, this.#element);

		PipUI.addClass(this.#element, this.#options.closedClass);

		return this;
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	hide(callback) {
		if(typeof this.#element == 'undefined'){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alertblock.d_hide'), this.#id, this.#options, this.#element);
		}

		PipUI.trigger(this.#element, 'hide-alertblock-pipui', this.#id, this.#options, this.#element);

		PipUI.addClass(this.#element, this.#options.hiddenClass);

		return this;
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	show(callback) {
		if(typeof this.#element == 'undefined'){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alertblock.d_show'), this.#id, this.#options, this.#element);
		}

		PipUI.trigger(this.#element, 'show-alertblock-pipui', this.#id, this.#options, this.#element);

		PipUI.removeClass(this.#element, this.#options.noneClass);

		setTimeout(() => PipUI.removeClass(this.#element, this.#options.hiddenClass), 0);

		return this;
	}
}

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Alertblock', AlertblockComponent.VERSION);
	PipUI.required('Alertblock', 'Storage', '1.0.0', '>=');
	/** @return {AlertblockComponent} */
	PipUI.Alertblock = AlertblockComponent;

	PipUI.i18n()
		.set('alertblock.d_element_not_found', '[Alertblock] Элементы не найдены')
		.set('alertblock.d_create_instance', '[Alertblock] Создание объекта')
		.set('alertblock.d_create_init', '[Alertblock] Создание объекта по стратегии инициализации')
		.set('alertblock.d_create_create', '[Alertblock] Создание объекта по стратегии создания')
		.set('alertblock.d_create_wrapper', '[Alertblock] Враппер не найден, создаём новый')
		.set('alertblock.d_close', '[Alertblock] Закрытие блока с полным удалением')
		.set('alertblock.d_hide', '[Alertblock] Скрытие блока')
		.set('alertblock.d_show', '[Alertblock] Отображение блока');
}
class TooltipComponent {
	static VERSION = '2.0.0';

	#id;

	#target;

	#trigger;

	#container;

	#options = {
		debug: false,

		direction: 'up',

		content: '',

		template: '<div class="tooltip"></div>',

		showedClass: 'tooltip-active',

		showCallback: undefined,

		hideCallback: undefined,

		showedCallback: undefined,

		hidedCallback: undefined,

		updateCallback: undefined,

		directions: {
			up: {
				offset: function(self){
					let rect = self.#trigger.getBoundingClientRect();

					return {
						top: rect.top - self.#target.offsetHeight,
						left: rect.left - (self.#target.offsetWidth / 2) + self.#trigger.offsetWidth / 2
					};
				}
			},
			down: {
				offset: function(self){
					let rect = self.#trigger.getBoundingClientRect();

					return {
						top: rect.top + self.#trigger.offsetHeight,
						left: rect.left - (self.#target.offsetWidth / 2) + self.#trigger.offsetWidth / 2
					};
				}
			},
			left: {
				offset: function(self){
					let rect = self.#trigger.getBoundingClientRect();

					return {
						top: rect.top - (self.#target.offsetHeight / 2) + (self.#trigger.offsetHeight / 2),
						left: rect.left - self.#target.offsetWidth
					};
				}
			},
			right: {
				offset: function(self){
					let rect = self.#trigger.getBoundingClientRect();

					return {
						top: rect.top - (self.#target.offsetHeight / 2) + (self.#trigger.offsetHeight / 2),
						left: rect.left + self.#trigger.offsetWidth
					};
				}
			}
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} trigger
	 *
	 * @param {object|undefined} options
	 * */
	constructor(trigger, options) {
		this.#trigger = PipUI.e(trigger)[0];

		this.setOptions(options);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('tooltip.d_create_instance'), this.#options);
		}

		this.#container = document.querySelector('.tooltip-container');

		if(!this.#container){
			this.#container = PipUI.create('<div class="tooltip-container"></div>');

			document.body.append(this.#container);
		}

		this.#id = Math.random().toString();

		this.#trigger.setAttribute('data-tooltip-id', this.#id);

		this.update();

		this.#events();

		PipUI.Storage.set('tooltip', this, this.#id);
	}



	#events(){

		let self = this;

		this.#target.addEventListener('transitionend', () => {
			if(self.isOpen()){
				PipUI.trigger(this.#target, 'showed-tooltip-pipui', self.#id, self.#options, self);

				if(typeof self.#options.showedCallback == 'function'){
					self.#options.showedCallback(self, self.#id, self.#options);
				}
			}else{
				PipUI.trigger(this.#target, 'hided-tooltip-pipui', self.#id, self.#options, self);

				if(typeof self.#options.hided == 'function'){
					self.#options.hided(self, self.#id, self.#options);
				}
			}
		});

		return this;
	}



	/**
	 * @return {this}
	 * */
	update() {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('tooltip.d_update'), this.#options);
		}

		let active = false;

		let old = this.#container.querySelector('.tooltip[data-tooltip-id="'+this.#id+'"]');

		if(old){ active = PipUI.hasClass(old, this.#options.showedClass); old.remove(); }

		if(!this.#target){
			this.#target = PipUI.create(this.#options.template);
		}

		this.#target.setAttribute('data-tooltip-id', this.#id);

		if(active){
			PipUI.addClass(this.#target, this.#options.showedClass);
		}

		this.#target.innerHTML = this.#options.content;

		this.#target.setAttribute('data-tooltip-direction', this.#options.direction);

		this.#target.setAttribute('data-tooltip-'+this.#options.direction, this.#options.content);

		this.#container.append(this.#target);

		let direction = this.#options.directions[this.#options.direction];

		if(typeof direction != 'undefined'){
			let offset = direction.offset(this);

			this.#target.setAttribute('data-tooltip-direction', this.#options.direction);

			PipUI.style(this.#target, {
				top: offset.top+'px',
				left: offset.left+'px'
			});
		}

		if(typeof this.#options.updateCallback == 'function'){
			this.#options.updateCallback(this, offset);
		}

		PipUI.trigger(this.#target, 'update-tooltip-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#target, this.#options.showedClass);
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	show(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('tooltip.d_show'), this.#id, this.#options);
		}

		if(this.isOpen()){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('tooltip.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		let self = this;

		if(typeof callback == 'undefined'){
			callback = this.#options.showCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.addClass(this.#target, self.#options.showedClass);

		PipUI.trigger(this.#target, 'show-tooltip-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	hide(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('tooltip.d_close'), this.#id, this.#options);
		}

		if(!this.isOpen()){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('tooltip.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.hideCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.removeClass(this.#target, this.#options.showedClass);

		PipUI.trigger(this.#target, 'hide-tooltip-pipui', this.#id, this.#options, this);

		return this;
	}
}

PipUI.ready(document, () => {

	PipUI.body('mouseover', '[data-tooltip], [data-tooltip-left], [data-tooltip-right], [data-tooltip-up], [data-tooltip-down]', (e, target) => {

		if(e.relatedTarget && PipUI.closest(target, e.relatedTarget.parentNode)){ return; }

		let targetLink = target.getAttribute('data-tooltip-target');

		let trigger = targetLink && document.querySelector(targetLink) ? document.querySelector(targetLink) : target;

		let id = trigger.getAttribute('data-tooltip-id');

		let content = target.getAttribute('data-tooltip');

		let direction;

		if(target.hasAttribute('data-tooltip-down')){
			content = target.getAttribute('data-tooltip-down');

			direction = 'down';
		}else if(target.hasAttribute('data-tooltip-left')){
			content = target.getAttribute('data-tooltip-left');

			direction = 'left';
		}else if(target.hasAttribute('data-tooltip-right')){
			content = target.getAttribute('data-tooltip-right');

			direction = 'right';
		}else if(target.hasAttribute('data-tooltip-up')){
			content = target.getAttribute('data-tooltip-up');

			direction = 'up';
		}

		let options = {content: content}

		if(target.hasAttribute('data-tooltip-direction')){
			direction = target.getAttribute('data-tooltip-direction');
		}

		if(direction){
			options.direction = direction;
		}

		/** @return {TooltipComponent} */
		let tooltip = id ? PipUI.Storage.get('tooltip', id).setOptions(options).update() : new TooltipComponent(trigger, options);

		tooltip.show();
	});

	PipUI.body('mouseout', '[data-tooltip], [data-tooltip-left], [data-tooltip-right], [data-tooltip-up], [data-tooltip-down]', (e, target) => {

		if(PipUI.closest(target, e.relatedTarget)){ return; }

		let targetLink = target.getAttribute('data-tooltip-target');

		let trigger = targetLink && document.querySelector(targetLink) ? document.querySelector(targetLink) : target;

		let id = trigger.getAttribute('data-tooltip-id');

		if(id){
			let tooltip = PipUI.Storage.get('tooltip', id);

			if(tooltip){
				tooltip.hide();
			}
		}
	});
});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Tooltip', TooltipComponent.VERSION);
	PipUI.required('Tooltip', 'Storage', '1.0.0', '>=');
	/** @return {TooltipComponent} */
	PipUI.Tooltip = TooltipComponent;


	PipUI.i18n()
		.set('tooltip.d_create_instance', '[Tooltip] Создание объекта')
		.set('tooltip.d_lock', '[Tooltip] Тултип заблокирован')
		.set('tooltip.d_show', '[Tooltip] Появление тултипа')
		.set('tooltip.d_hide', '[Tooltip] Скрытие тултипа')
		.set('tooltip.d_update', '[Tooltip] Обновление позиции');
}
class NavbarComponent {
    static VERSION = '2.0.0';

    /** @return {HTMLElement} */
    #wrapper;

    #trigger;

    #id;

    #eventCallback;

    #options = {
        debug: false,
        openCallback: undefined,
        closeCallback: undefined
    }



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    getOptions() {
        return this.#options;
    }



    /** @return {string|undefined} */
    getID() {
        return this.#id;
    }



    /**
     * @param {HTMLElement|string} object
     *
     * @param {object|undefined} options
     * */
    constructor(object, options) {
        let self = this;

        this.#wrapper = PipUI.e(object)[0];

        this.setOptions(options);

        options = this.getOptions();

        if(typeof this.#wrapper == 'undefined'){
            if(PipUI.Logger && options.debug){
                PipUI.Logger.info(PipUI.i18n().get('navbar.d_element_not_found'), options);
            }

            return this;
        }

        if(PipUI.Logger && options.debug){
            PipUI.Logger.info(PipUI.i18n().get('navbar.d_create_instance'), options);
        }

        this.#id = Math.random().toString();

        this.#wrapper.setAttribute('data-navbar-id', this.#id);

        this.#eventCallback = (e) => {
            e.preventDefault();

            if(self.isOpen()){
                self.hide();
            }else{
                self.show();
            }
        };

        this.#trigger = this.#wrapper.querySelector('.nav-mobile');

        this.#trigger.addEventListener('click', this.#eventCallback);

        PipUI.Storage.set('navbar', this, this.#id);
    }



    /**
     * @return {boolean}
     * */
    isOpen() {
        return PipUI.hasClass(this.#wrapper, 'navbar-active');
    }



    /**
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    show(callback) {
        if(this.isOpen()){ return this; }

        let options = this.getOptions();

        if(PipUI.Logger && options.debug){
            PipUI.Logger.info(PipUI.i18n().get('navbar.d_open'), this.#id, options);
        }

        PipUI.addClass(this.#wrapper, 'navbar-active');

        if(typeof callback == 'undefined'){
            callback = options.openCallback;
        }

        if(typeof callback == 'function'){
            callback(this);
        }

        PipUI.trigger(this.#wrapper, 'show-navbar-pipui', this.#id, options, this);

        return this;
    }



    /**
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    hide(callback) {
        if(!this.isOpen()){ return this; }

        let options = this.getOptions();

        if(PipUI.Logger && options.debug){
            PipUI.Logger.info(PipUI.i18n().get('navbar.d_close'), this.#id, options);
        }

        PipUI.removeClass(this.#wrapper, 'navbar-active');

        if(typeof callback == 'undefined'){
            callback = options.closeCallback;
        }

        if(typeof callback == 'function'){
            callback(this);
        }

        PipUI.trigger(this.#wrapper, 'hide-navbar-pipui', this.#id, options, this);

        return this;
    }
}

PipUI.ready(document, () => {
    PipUI.body('click', '.navbar:not([data-navbar-id]) .nav-mobile', (e, target) => {
        e.preventDefault();

        new NavbarComponent(target.closest('.navbar')).show();
    });
});


if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Navbar', NavbarComponent.VERSION);
    PipUI.required('Navbar', 'Storage', '1.0.0', '>=');
    /** @return {NavbarComponent} */
    PipUI.Navbar = NavbarComponent;

    PipUI.i18n()
        .set('navbar.d_element_not_found', '[Navbar] Элементы не найдены')
        .set('navbar.d_create_instance', '[Navbar] Создание объекта')
        .set('navbar.d_open', '[Navbar] Открытие панели responsive')
        .set('navbar.d_close', '[Navbar] Закрытие панели responsive');
}
class DropdownComponent {
	static VERSION = '2.0.0';

	/** @return {HTMLElement} */
	#wrapper;

	/** @return {HTMLElement} */
	#trigger;

	/** @return {HTMLElement} */
	#element;

	/** @return {string} */
	#id;

	#triggerClickEvent;

	#triggerMouseEnterEvent;

	#triggerSubmenuMouseEnterEvent;

	#triggerSubmenuClickEvent;

	#triggerEventCallback;

	#options = {
		debug: false,
		templates: {
			wrapper: '<div class="dropdown"></div>',
			trigger: '<button type="button" class="btn dropdown-trigger"></button>',
			list: '<ul class="dropdown-list"></ul>',
			item: '<li class="dropdown-item"></li>'
		},
		name: '',
		url: '',
		list: [],
		direction_x: 'right',
		direction_y: 'down',
		openEvent: 'click',
		submenuOpenEvent: 'hover',
		showCallback: undefined,
		hideCallback: undefined
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} object
	 *
	 * @param {object|undefined} options
	 *
	 * @param {boolean} update
	 * */
	constructor(object, options, update) {
		this.#element = PipUI.e(object)[0];

		this.setOptions(options);

		options = this.getOptions();

		if(typeof this.#element == 'undefined'){
			if(PipUI.Logger && options.debug){
				PipUI.Logger.info(PipUI.i18n().get('dropdown.d_element_not_found'), options);
			}

			return this;
		}

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('dropdown.d_create_instance'), options);
		}

		this.#id = Math.random().toString();

		if(update){
			this.update();
		}else{
			this.#init();
		}

		this.#events();

		PipUI.Storage.set('dropdown', this, this.#id);

		return this;
	}



	#removeEvents(){
		if(typeof this.#trigger != 'undefined'){
			console.log(this.#trigger);
			this.#trigger.removeEventListener('click', this.#triggerEventCallback);
			this.#trigger.removeEventListener('mouseenter', this.#triggerMouseEnterEvent);
		}

		if(typeof this.#wrapper != 'undefined'){
			this.#wrapper.querySelectorAll('.dropdown-submenu-trigger').forEach(item => {
				item.addEventListener('click', this.triggerClickEvent);
				item.addEventListener('mouseenter', this.#triggerSubmenuMouseEnterEvent);
				item.addEventListener('click', this.#triggerSubmenuClickEvent);
			});
		}

		return this;
	}



	#events(){

		let self = this;

		let options = this.getOptions();

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('dropdown.d_events'), this.#id, options);
		}

		this.#triggerEventCallback = e => {

			e.preventDefault();

			if(self.isOpen()){
				self.hide();
			}else{
				self.show();
			}
		}

		this.#trigger.addEventListener('click', this.#triggerEventCallback);

		if(options.openEvent == 'hover'){

			this.#triggerMouseEnterEvent = () => {

				self.show();
			}

			this.#trigger.addEventListener('mouseenter', this.#triggerMouseEnterEvent);
		}

		if(options.submenuOpenEvent == 'click'){
			let submenu = this.#wrapper.querySelectorAll('.dropdown-submenu-trigger');

			this.#triggerClickEvent = e => {
				e.preventDefault();

				let that = e.target;

				let menu = that.closest('.dropdown-submenu');

				if(PipUI.hasClass(menu, 'active')){
					PipUI.removeClass(menu, 'active');

					menu.querySelectorAll('.active').forEach(item => {
						PipUI.removeClass(item, 'active');
					});
				}else{
					PipUI.addClass(menu, 'active');
				}
			}

			submenu.forEach(item => {
				item.addEventListener('click', this.triggerClickEvent);
			});
		}else if(options.submenuOpenEvent == 'hover'){

			this.#triggerSubmenuMouseEnterEvent = e => {
				let that = e.target;

				let childs = PipUI.children(that, '.dropdown-submenu-trigger');

				PipUI.removeClass(PipUI.siblings(that, 'dropdown-item'), 'active');

				if(childs.length > 0 && !PipUI.hasClass(that, 'active')){
					that.querySelectorAll('.active').forEach(item => {
						PipUI.removeClass(item, 'active');
					});

					PipUI.addClass(that, 'active');
				}
			}

			self.#wrapper.querySelectorAll('.dropdown-item').forEach(item => {
				item.addEventListener('mouseenter', this.#triggerSubmenuMouseEnterEvent);
			});

			this.#triggerSubmenuClickEvent = e => {
				e.preventDefault();

				let target = e.target;

				let sub = target.closest('.dropdown-submenu');

				PipUI.toggleClass(sub, 'active');

				PipUI.removeClass(sub.querySelectorAll('.active'), 'active');
			}

			self.#wrapper.querySelectorAll('.dropdown-submenu-trigger').forEach(item => {
				item.addEventListener('click', this.#triggerSubmenuClickEvent);
			});
		}

		return self;
	}



	/**
	 * @return {this}
	 * */
	#init(){

		this.#removeEvents();

		let options = this.getOptions();

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('dropdown.d_render'), this.#id, options);
		}

		this.#wrapper = this.#element;

		this.#wrapper.setAttribute('data-dropdown-id', this.#id);

		var direction_x = this.#wrapper.getAttribute('data-dropdown-direction-x');

		var direction_y = this.#wrapper.getAttribute('data-dropdown-direction-y');

		if(direction_x){
			options.direction_x = direction_x;
		}

		if(direction_y){
			options.direction_y = direction_y;
		}

		this.#wrapper.setAttribute('data-dropdown-direction-x', options.direction_x);

		this.#wrapper.setAttribute('data-dropdown-direction-y', options.direction_y);

		this.#trigger = PipUI.children(this.#wrapper, '.dropdown-trigger')[0];

		if(!this.#trigger){
			this.#trigger = PipUI.create(options.templates.trigger);

			this.#wrapper.innerHTML = this.#trigger.outerHTML + this.#wrapper.innerHTML;
		}

		var name = this.#trigger.innerHTML.trim();

		if(!options.name && name != ''){
			options.name = name;
		}

		this.#trigger.innerHTML = options.name;

		var url = this.#trigger.getAttribute('href');

		if(!options.url && url){
			options.url = url;

			this.#trigger.setAttribute('href', options.url);
		}


		var listWrapper = PipUI.children(this.#wrapper, '.dropdown-list')[0];

		if(!listWrapper){
			listWrapper = PipUI.create(options.templates.list);

			this.#wrapper.innerHTML += listWrapper.outerHTML;
		}

		if(listWrapper.innerHTML.trim() == ''){
			if(options.list){
				this.#renderList(options.list, this.#wrapper);
			}
		}else{
			options.list = this.#initList(listWrapper);
		}

		return this;
	}



	/**
	 * @param {HTMLElement} wrapper
	 *
	 * @param {array} l
	 *
	 * @return {array}
	 * */
	#initList(wrapper, l) {
		if(typeof l == 'undefined'){ l = []; }

		if(!wrapper.length){ return l; }

		PipUI.children(wrapper, '.dropdown-item').forEach(that => {

			var target = PipUI.children(that, '.dropdown-link');

			if(!target.length){
				target = PipUI.children(that, '.dropdown-element');
			}

			if(!target.length){ return; }

			var item = {
				active: PipUI.hasClass(that, 'active'),
				name: target.innerHTML,
			};

			var url = target.getAttribute('href');

			if(typeof url != 'undefined'){
				item.url = url;
			}

			var _target = target.getAttribute('target');

			if(typeof _target != 'undefined'){
				item.target = _target;
			}

			if(PipUI.hasClass(that, 'dropdown-submenu')){
				item.submenu = this.#initList(PipUI.children(that, '.dropdown-list'), item.submenu);
			}

			l.push(item);
		});

		return l;
	}



	/** @return {this} */
	update() {

		this.#removeEvents();

		let options = this.getOptions();

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('dropdown.d_render'), this.#id, options);
		}

		this.#wrapper = PipUI.create(options.templates.wrapper);

		this.#wrapper.setAttribute('data-dropdown-id', this.#id);

		this.#wrapper.setAttribute('data-dropdown-direction-x', options.direction_x);

		this.#wrapper.setAttribute('data-dropdown-direction-Y', options.direction_y);


		this.#trigger = PipUI.create(options.templates.trigger);

		this.#trigger.innerHTML = options.name ?? '';

		this.#wrapper.innerHTML = this.#trigger.outerHTML + this.#wrapper.innerHTML;

		if(options.url){ this.#trigger.setAttribute('href', options.url); }

		if(options.target){ this.#trigger.setAttribute('target', options.target); }

		if(Array.isArray(options.list)){
			this.#renderList(options.list, this.#wrapper);
		}

		this.#element.outerHTML = this.#wrapper.outerHTML;

		this.#wrapper = document.querySelector('.dropdown[data-dropdown-id="'+this.#id+'"]');

		this.#trigger = PipUI.children(this.#wrapper, '.dropdown-trigger')[0];

		return this;
	}



	/**
	 * @param {array} arr
	 *
	 * @param {HTMLElement} wrapper
	 *
	 * @return {this}
	 * */
	#renderList(arr, wrapper) {
		let options = this.getOptions();

		var items = PipUI.create(options.templates.list);

		for(var i = 0; i < arr.length; i++){
			var data = arr[i];

			if(typeof data == 'undefined'){ continue; }

			var item = PipUI.create(options.templates.item);

			var element;

			if(data.url){
				element = PipUI.create('<a class="dropdown-link" href="'+data.url+'" rel="nofollow"></a>');

				if(data.target){
					element.setAttribute('target', data.target);
				}

			}else{
				element = PipUI.create('<div class="dropdown-element"></div>');
			}

			element.innerHTML = data.name;

			if(data.active){
				PipUI.addClass(item, 'active');
			}

			if(Array.isArray(data.submenu)){
				PipUI.addClass(item, 'dropdown-submenu');

				PipUI.addClass(element, 'dropdown-submenu-trigger');

				this.#renderList(data.submenu, item);
			}

			item.innerHTML += element.outerHTML;

			items.innerHTML += item.outerHTML;
		}

		wrapper.innerHTML += items.outerHTML;

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#wrapper, 'active');
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	hide(callback) {

		if(!this.isOpen()){ return this; }

		if(typeof this.#wrapper == 'undefined'){ return this; }

		let options = this.getOptions();

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('dropdown.d_hide'), this.#id);
		}

		PipUI.removeClass(this.#wrapper, 'active');

		PipUI.removeClass(this.#wrapper.querySelectorAll('.active'), 'active');

		if(typeof callback != 'function'){
			callback = options.hideCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#wrapper, 'hide-dropdown-pipui', this.#wrapper, options, this);

		return this;
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	show(callback) {

		if(this.isOpen()){ return this; }

		if(typeof this.#wrapper == 'undefined'){ return this; }

		let options = this.getOptions();

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('dropdown.d_show'), this.#id);
		}

		let opened = PipUI.Storage.get('dropdown');

		if(typeof opened == 'object'){
			Object.keys(opened).forEach(key => {
				opened[key].hide();
			});
		}

		PipUI.addClass(this.#wrapper, 'active');

		PipUI.removeClass(this.#wrapper.querySelectorAll('.active'), 'active');

		if(typeof callback != 'function'){
			callback = options.showCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#wrapper, 'show-dropdown-pipui', this.#wrapper, options, this);

		return this;
	}
}

PipUI.ready(document, () => {
	document.body.addEventListener('click', (e) => {
		let target = e.target;

		let parent = target.closest('.dropdown:not([data-dropdown-id]) > .dropdown-trigger');

		let showed;

		if(parent){
			e.preventDefault();

			showed = new DropdownComponent(parent.closest('.dropdown')).show();
		}

		if(!target.closest('.dropdown')){
			let opened = PipUI.Storage.get('dropdown');

			if(typeof opened == 'object'){
				Object.keys(opened).forEach(key => {
					if(typeof showed != 'undefined' && showed.getID() == opened[key].getID()){
						return;
					}

					opened[key].hide();
				});
			}
		}
	});
});

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Dropdown', DropdownComponent.VERSION);
	PipUI.required('Dropdown', 'Storage', '1.0.0', '>=');
	/** @return {Dropdown} */
	PipUI.Dropdown = DropdownComponent;

	PipUI.i18n()
		.set('dropdown.d_create_instance', '[Dropdown] Создание объекта')
		.set('dropdown.d_show', '[Dropdown] Отображение элемента')
		.set('dropdown.d_hide', '[Dropdown] Скрытие эелемента')
		.set('dropdown.d_render', '[Dropdown] Рендер элемента')
		.set('dropdown.d_init', '[Dropdown] Инициализация элемента')
		.set('dropdown.d_events', '[Dropdown] Обработка событий');
}
class AnchorComponent {
	static VERSION = '2.0.0';

	static #options = {
		debug: false,
		defaultDuration: 400,
		defaultCallback: undefined,
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	static setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	static getOptions() {
		return this.#options;
	}



	static scroll(element, options){
		element = PipUI.e(element);

		if(!element.length){ return false; }

		if(typeof options == 'undefined'){
			options = {};
		}

		element = element[0];

		let rect = element.getBoundingClientRect();

		let pageOffsetTop = window.pageYOffset;
		let pageOffsetLeft = window.pageXOffset;

		let diffY = rect.top - pageOffsetTop;
		let diffX = rect.left - pageOffsetLeft;

		options.duration = typeof options.duration == 'undefined' || options.duration === null ? this.#options.defaultDuration : parseInt(options.duration);

		options.callback = typeof options.callback == 'undefined' || options.callback === null ? this.#options.defaultCallback : options.callback;

		options.hash = typeof options.hash == 'undefined' || options.hash === null ? false : options.hash;

		if(options.hash){
			location.hash = options.hash;
		}

		let start;

		window.requestAnimationFrame(function step(t){
			if(!start){
				start = t;
			}

			let time = t - start;

			var percent = Math.min(time / options.duration, 1);

			let left = pageOffsetLeft + diffX * percent;

			let top = pageOffsetTop + diffY * percent;

			window.scrollTo(left, top);

			if(time < options.duration){
				window.requestAnimationFrame(step);
			}else if(typeof options.callback == 'function'){
				options.callback();
			}
		});
	}

}

PipUI.ready(document, () => {

	PipUI.body('click', '[data-anchor]', (e, target) => {
		e.preventDefault();

		AnchorComponent.scroll(target.getAttribute('data-anchor'), {
			duration: target.getAttribute('data-anchor-duration'),
			hash: target.getAttribute('data-anchor-hash')
		});
	});
});

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Anchor', AnchorComponent.VERSION);
	/** @return {AnchorComponent} */
	PipUI.Anchor = AnchorComponent;

	PipUI.i18n()
		.set('anchor.d_element_not_found', '[Anchor] Элементы не найдены')
		.set('anchor.d_create_instance', '[Anchor] Создание объекта')
		.set('anchor.d_open', '[Anchor] Открытие панели responsive')
		.set('anchor.d_close', '[Anchor] Закрытие панели responsive');
}
class PaginationComponent {
	static VERSION = '2.0.0';

	/** @return {HTMLElement} */
	#wrapper;

	#id;

	#options = {
		debug: false,
		url: '/page-{NUM}',
		method: 'redirect', // redirect | location | none
		type: 1,
		records: 0,
		max: 10,
		current: 1,
		changePageCallback: undefined,
		updateCallback: undefined,
		types: {
			1: {
				templates: {
					prev: '<li class="pagination-page"><a data-pagination-page="{PREV}" href="{URL}">'+PipUI.i18n().get('pagination.prev')+'</a></li>',
					next: '<li class="pagination-page"><a data-pagination-page="{NEXT}" href="{URL}">'+PipUI.i18n().get('pagination.next')+'</a></li>'
				},
				render: function(self){
					if(PipUI.Logger && self.#options.debug){
						PipUI.Logger.info(PipUI.i18n().get('pagination.d_render_type')+'1', self.#options);
					}

					var type = self.getType();

					var prev = self.getPrev();

					var prevTemplate = type.templates.prev
						.replace(/\{PREV\}/g, prev)
						.replace(/\{URL\}/g, self.#options.url)
						.replace(/\{NUM\}/g, prev);

					var next = self.getNext();

					var nextTemplate = type.templates.next
						.replace(/\{NEXT\}/g, next)
						.replace(/\{URL\}/g, self.#options.url)
						.replace(/\{NUM\}/g, next);

					return prevTemplate+nextTemplate;
				}
			},
			2: {
				templates: {
					item: '<li class="pagination-page {SUBCLASS}"><a data-pagination-page="{NUM}" href="{URL}">{NUM}</a></li>'
				},
				render: function(self){
					if(PipUI.Logger && self.#options.debug){
						PipUI.Logger.info(PipUI.i18n().get('pagination.d_render_type')+'2', self.#options);
					}

					var pages = self.getPages();

					var result = "";

					if(pages <= 1){ return result; }

					var type = self.getType();

					for(var i = 1; i <= pages; i++){

						var subclass = i == self.#options.current ? 'active' : '';

						var item = type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, i)
							.replace(/\{SUBCLASS\}/g, subclass);

						result += item;
					}

					return result;
				}
			},
			3: {
				templates: {
					item: '<li class="pagination-page {SUBCLASS}"><a data-pagination-page="{NUM}" href="{URL}">{NAME}</a></li>'
				},
				nums: 2,
				icons: {
					leftArrows: '<i class="fa-solid fa-angles-left"></i>',
					rightArrows: '<i class="fa-solid fa-angles-right"></i>'
				},
				render: function(self){
					if(PipUI.Logger && self.#options.debug){
						PipUI.Logger.info(PipUI.i18n().get('pagination.d_render_type')+'3', self.#options);
					}

					var pages = self.getPages();

					var result = "";

					if(pages <= 1){ return result; }

					var type = self.getType();

					if(self.#options.current > type.nums + 1){

						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, 1)
							.replace(/\{NAME\}/g, type.icons.leftArrows)
							.replace(/\{SUBCLASS\}/g, '');

					}

					var i;

					for(i = self.#options.current - type.nums < 1 ? 1 : self.#options.current - type.nums; i < self.#options.current; i++){
						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, i)
							.replace(/\{NAME\}/g, i)
							.replace(/\{SUBCLASS\}/g, '');
					}

					result += type.templates.item
						.replace(/\{URL\}/g, self.#options.url)
						.replace(/\{NUM\}/g, self.#options.current)
						.replace(/\{NAME\}/g, self.#options.current)
						.replace(/\{SUBCLASS\}/g, 'active');

					for(i = self.#options.current + 1; i <= self.#options.current + type.nums && i <= pages; i++){
						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, i)
							.replace(/\{NAME\}/g, i)
							.replace(/\{SUBCLASS\}/g, '');
					}

					if(pages - self.#options.current > type.nums){

						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, pages)
							.replace(/\{NAME\}/g, type.icons.rightArrows)
							.replace(/\{SUBCLASS\}/g, '');

					}

					return result;
				}
			},
			4: {
				templates: {
					item: '<li class="pagination-page {SUBCLASS}"><a data-pagination-page="{NUM}" href="{URL}">{NAME}</a></li>'
				},
				nums: 2,
				leftNums: 3,
				rightNums: 3,
				selectors: true,
				icons: {
					leftArrows: '<i class="fa-solid fa-angles-left"></i>',
					rightArrows: '<i class="fa-solid fa-angles-right"></i>',
					selector: '...'
				},
				render: function(self){
					if(PipUI.Logger && self.#options.debug){
						PipUI.Logger.info(PipUI.i18n().get('pagination.d_render_type')+'4', self.#options);
					}

					var pages = self.getPages();

					var result = "";

					if(pages <= 1){ return result; }

					var type = self.getType();

					var i, subclass, url, num, name;

					var start = self.#options.current - type.nums;

					var end = self.#options.current + type.nums;



					for(i = 1; i <= type.leftNums && i < start; i++){
						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, i)
							.replace(/\{NAME\}/g, i)
							.replace(/\{SUBCLASS\}/g, '');
					}



					var startSelector = start - type.leftNums - 1;

					if(type.selectors && startSelector > 0){

						url = startSelector == 1 ? self.#options.url : '#';

						num = startSelector == 1 ? start - 1 : (type.leftNums + 1)+'.'+(start - 1);

						name = startSelector == 1 ? start - 1 : type.icons.selector;

						subclass = startSelector == 1 ? '' : 'page-selector';

						result += type.templates.item
							.replace(/\{URL\}/g, url)
							.replace(/\{NUM\}/g, num)
							.replace(/\{NAME\}/g, name)
							.replace(/\{SUBCLASS\}/g, subclass);
					}



					for(i = start < 1 ? 1 : start; i <= end && i <= pages; i++){

						subclass = i == self.#options.current ? 'active' : '';

						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, i)
							.replace(/\{NAME\}/g, i)
							.replace(/\{SUBCLASS\}/g, subclass);
					}



					var endSelector = pages - type.rightNums - end;

					if(type.selectors && endSelector > 0){

						url = endSelector == 1 ? self.#options.url : '#';

						num = endSelector == 1 ? end + 1 : (end + 1)+'.'+(pages - type.rightNums - 1);

						name = endSelector == 1 ? end + 1 : type.icons.selector;

						subclass = endSelector == 1 ? '' : 'page-selector';

						result += type.templates.item
							.replace(/\{URL\}/g, url)
							.replace(/\{NUM\}/g, num)
							.replace(/\{NAME\}/g, name)
							.replace(/\{SUBCLASS\}/g, subclass);
					}



					var startEnd = pages - type.rightNums + 1;

					for(i = end >= startEnd ? end + 1 : startEnd; i <= pages; i++){
						result += type.templates.item
							.replace(/\{URL\}/g, self.#options.url)
							.replace(/\{NUM\}/g, i)
							.replace(/\{NAME\}/g, i)
							.replace(/\{SUBCLASS\}/g, '');
					}

					return result;
				}
			}
		}
	}

	#clickEvent;

	#start = true;



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {

		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} object
	 *
	 * @param {object|undefined} options
	 * */
	constructor(object, options) {
		this.#wrapper = PipUI.e(object)[0];

		this.setOptions(options);

		if(typeof this.#wrapper == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('pagination.d_element_not_found'), this.#options);
			}

			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('pagination.d_create_instance'), this.#options);
		}

		this.#id = Math.random().toString();

		this.#wrapper.setAttribute('data-pagination-id', this.#id);

		PipUI.addClass(this.#wrapper, 'pagination');

		this.#start = false;

		this.update();

		PipUI.Storage.set('pagination', this, this.#id);
	}



	/**
	 * @return {this}
	 * */
	update(){

		if(this.#start){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('pagination.d_update'), this.#options);
		}

		let self = this;

		let pages = this.getPages();

		let old = this.#wrapper.querySelectorAll('.pagination-page > a');

		if(old.length > 0 && typeof this.#clickEvent == 'function'){
			old.forEach(item => {
				item.removeEventListener('click', this.#clickEvent);
			});
		}


		this.#wrapper.setAttribute('data-pagination-type', this.#options.type);

		if(pages > 1) {
			this.#wrapper.innerHTML = this.getType().render(this);
			PipUI.style(this.#wrapper, 'display', null);
		}else{
			this.#wrapper.innerHTML = '';
			PipUI.style(this.#wrapper, 'display', 'none');
		}

		this.#clickEvent = (e) => {
			e.preventDefault();

			let target = e.target;

			var item = target.closest('.pagination-page');

			if(!PipUI.hasClass(item, 'disabled') && !PipUI.hasClass(item, 'page-selector')){

				let page = parseInt(target.getAttribute('data-pagination-page'));

				if(this.#options.current != page){
					self.setPage(page);
				}
			}
		};

		let items = this.#wrapper.querySelectorAll('.pagination-page > a');

		if(items.length > 0){
			items.forEach(item => {
				item.addEventListener('click', this.#clickEvent);
			});
		}

		if(typeof this.#options.updateCallback == 'function'){
			this.#options.updateCallback(this, old);
		}

		PipUI.trigger(this.#wrapper, 'update-pagination-pipui', this.#id, this.#options, this);

		return self;
	}



	/**
	 * @return {int}
	 * */
	getPages(){
		return Math.ceil(this.#options.records / this.#options.max);
	}



	/**
	 * @return {int}
	 * */
	getPrev() {
		var prev = this.#options.current - 1;

		return prev <= 0 ? 1 : prev;
	}

	/**
	 * @return {int}
	 * */
	getNext() {
		var next = this.#options.current + 1;

		var pages = this.getPages();

		return next > pages ? pages : next;
	}



	/**
	 * @param {int|undefined} num
	 *
	 * @return {this}
	 * */
	setPage(num) {
		num = parseInt(num);

		let pages = this.getPages();

		if(isNaN(num)){
			num = 1;
		}else if(num < 0){
			num = 0;
		}else if(num > pages){
			num = pages;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('pagination.d_change_page'), this.#options);
		}

		this.#options.current = num;

		let url = this.#options.url.replace(/\{NUM\}/g, this.#options.current);

		if(this.#options.method == 'redirect'){
			window.location.href = url;
		}else if(this.#options.method == 'location'){
			window.history.pushState("", "", url);
		}

		this.update();

		if(typeof this.#options.changePageCallback == 'function'){
			this.#options.changePageCallback(this);
		}

		PipUI.trigger(this.#wrapper, 'change-pagination-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @return {object}
	 * */
	getType() {
		return this.#options.types[this.#options.type];
	}
}


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Pagination', PaginationComponent.VERSION);
	PipUI.required('Pagination', 'Storage', '1.0.0', '>=');
	/** @return {PaginationComponent} */
	PipUI.Pagination = PaginationComponent;

	PipUI.i18n()
		.set('pagination.prev', 'Назад')
		.set('pagination.next', 'Вперёд')
		.set('pagination.d_element_not_found', '[Pagination] Элемент не найден')
		.set('pagination.d_create_instance', '[Pagination] Создание объекта')
		.set('pagination.d_render_type', '[Pagination] Запуск рендера типа: ')
		.set('pagination.d_change_page', '[Pagination] Изменение страницы')
		.set('pagination.d_update', '[Pagination] Обновление содержимого');
}

class ProgressComponent {
	static VERSION = '2.0.0';

	/** @return {HTMLElement} */
	#element;

	#id;

	/** @return {HTMLElement} */
	#bar;

	/** @return {HTMLElement} */
	#canvas;

	/** @return {HTMLElement} */
	#context;

	/** @return {HTMLElement} */
	#text;

	/** @return {HTMLElement} */
	#label;

	#options = {
		debug: false,

		text: '',

		label: '',

		progress: 0,

		changeCallback: undefined,

		changeTextCallback: undefined,

		changeLabelCallback: undefined,

		updateCallback: undefined,

		styles: {
			width: '100%',
			height: '28px',
			padding: 4,
			barColor: '#fff',
			progressColor: '#212121'
		},

		type: 'linear',

		templates: {
			text: '<div class="progress-text"></div>',
			label: '<div class="progress-label"></div>'
		},

		types: {
			radial: {
				template: '<canvas class="progress-bar"></canvas>',
				create: function(self){

					PipUI.style(self.#element, {
						width: self.#options.styles.width,
						height: self.#options.styles.width
					});

					PipUI.style(self.#bar, {width: '100%', height: '100%'});

					self.#canvas = self.#bar;

					self.#canvas.width = self.#canvas.height = parseInt(self.#element.offsetWidth);

					self.#context = self.#canvas.getContext('2d');

					self.setProgress(self.#options.progress);

					return self;
				},

				progress: function(size, self){

					self.#options.progress = size;

					self.#bar.setAttribute('data-progress-size', size);

					let width = self.#element.offsetWidth;

					let height = parseInt(self.#options.styles.height);

					let margin = width / 2;

					let radius = margin - (height / 2);

					self.#context.clearRect(0, 0, width, height);

					self.#context.beginPath();
					self.#context.strokeStyle = self.#options.styles.progressColor;
					self.#context.lineWidth = height;

					self.#context.arc(margin, margin, radius, 0, Math.PI * 2);
					self.#context.stroke();


					var start = Math.PI * 1.5;
					var end = start + (3.6 * self.#options.progress * (Math.PI / 180));


					self.#context.beginPath();
					self.#context.shadowColor = self.#options.styles.barColor;
					self.#context.shadowBlur = self.#options.styles.padding;
					self.#context.strokeStyle = self.#options.styles.barColor;
					self.#context.lineWidth = height - (self.#options.styles.padding * 2);

					self.#context.arc(margin, margin, radius, start, end);
					self.#context.stroke();

					return self;
				}
			},
			linear: {
				template: '<div class="progress-bar" style="width:0;"></div>',
				create: function(self){
					PipUI.style(self.#element, {
						width: self.#options.styles.width,
						height: self.#options.styles.height,
						padding: self.#options.styles.padding+'px',
						'background-color': self.#options.styles.progressColor
					});

					PipUI.style(self.#bar, 'background-color', self.#options.styles.barColor);

					self.setProgress(self.#options.progress);

					return self;
				},

				progress: function(size, self){

					self.#options.progress = size;

					self.#bar.setAttribute('data-progress-size', size);

					PipUI.style(self.#bar, 'width', size+'%');

					return self;
				}
			}
		},
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} object
	 *
	 * @param {object|undefined} options
	 *
	 * @param {boolean|undefined} update
	 * */
	constructor(object, options, update) {
		this.#element = PipUI.e(object)[0];

		this.setOptions(options);

		options = this.getOptions();

		if(typeof this.#element == 'undefined'){
			if(PipUI.Logger && options.debug){
				PipUI.Logger.info(PipUI.i18n().get('progress.d_element_not_found'), options);
			}

			return this;
		}

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('progress.d_create_instance'), options);
		}

		this.#id = Math.random().toString();

		this.#element.setAttribute('data-progress-id', this.#id);

		if(update){
			this.update();
		}else{
			this.#init();
		}

		PipUI.Storage.set('progress', this, this.#id);
	}



	/**
	 * @param {float} size
	 *
	 * @return {this}
	 * */
	setProgress(size){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('progress.d_change_progress'), this.#options);
		}

		let type = this.#options.types[this.#options.type];

		if(typeof type == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('progress.d_undefined_type'), this.#options, this.#options.type);
			}

			return this;
		}

		type.progress(size, this);

		if(typeof this.#options.changeCallback == 'function'){
			this.#options.changeCallback(this, size);
		}

		PipUI.trigger(this.#element, 'change-progress-pipui', this, size, this.#options, this.#id);

		return this;
	}



	/**
	 * @param {string} text
	 *
	 * @return {this}
	 * */
	setText(text){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('progress.d_change_text'), this.#options);
		}

		this.#options.text = text;

		if(text){
			this.#text.innerHTML = text;
			PipUI.style(this.#text, 'display', null);
		}else{
			this.#text.innerHTML = '';
			PipUI.style(this.#text, 'display', 'none');
		}

		if(typeof this.#options.changeTextCallback == 'function'){
			this.#options.changeTextCallback(this, text);
		}

		PipUI.trigger(this.#element, 'change-text-progress-pipui', this, text, this.#options, this.#id);

		return this;
	}



	/**
	 * @param {string} text
	 *
	 * @return {this}
	 * */
	setLabel(text){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('progress.d_change_label'), this.#options);
		}

		this.#options.subtlabelext = text;

		if(text){
			this.#label.innerHTML = text;
			PipUI.style(this.#label, 'display', null);
		}else{
			this.#label.innerHTML = '';
			PipUI.style(this.#label, 'display', 'none');
		}

		if(typeof this.#options.changeLabelCallback == 'function'){
			this.#options.changeLabelCallback(this, text);
		}

		PipUI.trigger(this.#element, 'change-label-progress-pipui', this, text, this.#options, this.#id);

		return this;
	}



	/**
	 * @return {this}
	 * */
	update(){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('progress.d_update'), this.#options);
		}

		let type = this.#options.types[this.#options.type];

		if(typeof type == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('progress.d_undefined_type'), this.#options, this.#options.type);
			}

			return this;
		}

		this.#element.innerHTML = '';

		let bar = PipUI.create(type.template);

		let text = PipUI.create(this.#options.templates.text);

		let label = PipUI.create(this.#options.templates.label);

		PipUI.addClass(this.#element, 'progress');

		this.#element.setAttribute('data-progress-type', this.#options.type);

		this.#element.innerHTML += bar.outerHTML + text.outerHTML + label.outerHTML;

		PipUI.style(this.#element, {
			width: this.#options.styles.width,
			height: this.#options.styles.height
		});

		this.#bar = PipUI.children(this.#element, '.progress-bar')[0];

		this.#text = PipUI.children(this.#element, '.progress-text')[0];

		this.#label = PipUI.children(this.#element, '.progress-label')[0];

		if(typeof this.#options.updateCallback == 'function'){
			this.#options.updateCallback(this);
		}

		PipUI.trigger(this.#element, 'update-progress-pipui', this, this.#options, this.#id);

		this.setText(this.#options.text)
			.setLabel(this.#options.label);

		return type.create(this);
	}



	/**
	 * @return {this}
	 * */
	#init(){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('progress.d_init'), this.#options);
		}

		let typeName = this.#element.getAttribute('data-progress-type');

		if(!typeName){
			typeName = this.#options.type;
		}else{
			this.#options.type = typeName;
		}

		let type = this.#options.types[typeName];

		if(typeof type == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('progress.d_undefined_type'), this.#options, this.#options.type);
			}

			return this;
		}

		let bar = PipUI.children(this.#element, '.progress-bar')[0];

		let text = PipUI.children(this.#element, '.progress-text')[0];

		let label = PipUI.children(this.#element, '.progress-label')[0];

		if(bar){
			this.#bar = bar;
		}else{
			this.#bar = PipUI.create(type.template);

			this.#element.innerHTML = this.#bar.outerHTML + this.#element.innerHTML;
		}

		if(text){
			this.#text = text;
		}else{
			this.#text = PipUI.create(this.#options.templates.text);

			this.#element.innerHTML += this.#text.outerHTML;
		}

		this.#label = label ? label : PipUI.create(this.#options.templates.label);

		var textHtml = text.innerHTML;

		var labelHtml = label.innerHTML;

		if(textHtml){
			this.#options.text = textHtml;
		}

		if(labelHtml){
			this.#options.label = labelHtml;
		}

		this.setText(this.#options.text);

		this.setLabel(this.#options.label);

		var size = this.#bar.getAttribute('data-progress-size');

		if(size){
			this.#options.progress = size;
		}

		type.create(this);

		return this;
	}
}

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Progress', ProgressComponent.VERSION);
	PipUI.required('Progress', 'Storage', '1.0.0', '>=');
	/** @return {ProgressComponent} */
	PipUI.Progress = ProgressComponent;

	PipUI.i18n()
		.set('progress.d_create_instance', '[Progress] Создание объекта')
		.set('progress.d_undefined_type', '[Progress] Неверный тип прогресс бара')
		.set('progress.d_change_progress', '[Progress] Изменение размера прогресса')
		.set('progress.d_change_text', '[Progress] Изменение текста')
		.set('progress.d_change_label', '[Progress] Изменение маркировки')
		.set('progress.d_update', '[Progress] Запуск процесса инициализации через обновление')
		.set('progress.d_init', '[Progress] Запуск процесса инициализации через существующий элемент');
}
class CollapseComponent {
	static VERSION = '2.0.0';

	/** @return {array <HTMLElement>} */
	#triggers = [];

	/** @return {HTMLElement} */
	#target;

	#id;

	#lock = false;

	#animation;

	/** @return {function|undefined} */
	#eventCallback = function(e){
		e.preventDefault();

		let id = this.getAttribute('data-collapse-id');

		let self = PipUI.Storage.get('collapse', id);

		if(self.isOpen()){
			self.hide();
		}else{
			self.show();
		}
	}

	#options = {
		debug: false,

		/** @return {array <HTMLElement>} */
		triggers: [],

		animation: {
			show: {
				type: 'slideDown',
				duration: 200
			},
			hide: {
				type: 'slideUp',
				duration: 200
			}
		},

		accordion: false,

		defaultVisible: false,

		toggleTargetClass: 'collapse-active',

		toggleTriggerClass: 'active',

		/** @return {function|undefined} */
		showStartCallback: undefined,

		/** @return {function|undefined} */
		hideStartCallback: undefined,

		/** @return {function|undefined} */
		showEndCallback: undefined,

		/** @return {function|undefined} */
		hideEndCallback: undefined
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} object
	 *
	 * @param {object|undefined} options
	 * */
	constructor(object, options) {
		this.#target = PipUI.e(object)[0];

		this.setOptions(options);

		options = this.getOptions();

		if(typeof this.#target == 'undefined'){
			if(PipUI.Logger && options.debug){
				PipUI.Logger.info(PipUI.i18n().get('collapse.d_element_not_found'), options);
			}

			return this;
		}

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('collapse.d_create_instance'), options);
		}

		let id = this.#target.getAttribute('data-collapse-id');

		this.#id = id ? id : Math.random().toString();

		this.#target.setAttribute('data-collapse-id', this.#id);

		PipUI.addClass(this.#target, 'collapse');

		this.update();

		this.#animation = new PipUI.Animation(this.#target);

		PipUI.Storage.set('collapse', this, this.#id);


		if(this.#options.accordion){
			let accordion = this.#target.closest('.accordion');

			let triggers = accordion.querySelectorAll('[data-accordion]:not([data-collapse-id])');

			if(triggers.length){
				triggers.forEach(trigger => {
					let collapse = new CollapseComponent(trigger.getAttribute('data-accordion'), {
						triggers: [trigger]
					});

					collapse.setOptions({accordion: true});
				});
			}
		}

		if(this.#options.defaultVisible){
			this.show();
		}
	}



	/** @return {this} */
	update(){
		if(this.#triggers.length){
			this.#triggers.forEach(trigger => {
				trigger.removeEventListener('click', this.#eventCallback);

				PipUI.removeClass(trigger, 'collapse-trigger');

				trigger.removeAttribute('data-collapse-id');
			});
		}

		this.#triggers = [];

		if(!this.#options.triggers.length){ return this; }

		let self = this;

		this.#options.triggers.forEach(trigger => {
			trigger = PipUI.e(trigger)[0];

			PipUI.addClass(trigger, 'collapse-trigger');

			trigger.setAttribute('data-collapse-id', self.#id);

			trigger.addEventListener('click', this.#eventCallback);

			this.#triggers.push(trigger);
		});

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#target, this.#options.toggleTargetClass);
	}


	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	toggle(callback) {
		return this.isOpen() ? this.hide(callback) : this.show(callback);
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	show(callback) {
		if(this.#lock || this.isOpen()){ return this; }

		this.#lock = true;

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('collapse.d_show'), this.#id, this.#options);
		}

		let self = this;

		PipUI.addClass(this.#triggers, 'collapse-lock');

		//this.#options.animation.show.type

		this.#animation[this.#options.animation.show.type]({duration: this.#options.animation.show.duration}, () => {
			self.#lock = false;

			PipUI.addClass(self.#target, self.#options.toggleTargetClass);

			if(typeof self.#options.showEndCallback == 'function'){
				self.#options.showEndCallback(self);
			}

			PipUI.trigger(self.#target, 'show-end-collapse-pipui', self.#id, self.#options);

			if(PipUI.Logger && self.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('collapse.d_show_complete'), self.#id, self.#options);
			}

			PipUI.addClass(self.#triggers, self.#options.toggleTriggerClass);

			PipUI.removeClass(self.#triggers, 'collapse-lock');
		});

		/*animation(this.#target, {
			duration: self.#options.animation.show.duration
		}, () => {

			this.#lock = false;

			PipUI.addClass(self.#target, self.#options.toggleTargetClass);

			if(typeof this.#options.showEndCallback == 'function'){
				this.#options.showEndCallback(self);
			}

			PipUI.trigger(this.#target, 'show-end-collapse-pipui', this.#id, this.#options);

			if(PipUI.Logger && self.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('collapse.d_show_complete'), self.#id, self.#options);
			}

			PipUI.addClass(this.#triggers, self.#options.toggleTriggerClass);

			PipUI.removeClass(this.#triggers, 'collapse-lock');
		});*/

		if(typeof callback == 'undefined'){
			callback = this.#options.showStartCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'show-start-collapse-pipui', this.#id, this.#options);

		if(this.#options.accordion){
			let accordion = this.#target.closest('.accordion');

			accordion.querySelectorAll('[data-collapse-id]:not([data-collapse-id="'+this.#id+'"])').forEach(item => {
				let collapse = PipUI.Storage.get('collapse', item.getAttribute('data-collapse-id'));

				collapse.hide();
			});
		}

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	hide(callback) {
		if(this.#lock || !this.isOpen()){ return this; }

		this.#lock = true;

		let options = this.getOptions();

		if(PipUI.Logger && options.debug){
			PipUI.Logger.info(PipUI.i18n().get('collapse.d_hide'), this.#id, options);
		}

		let self = this;

		PipUI.addClass(this.#triggers, 'collapse-lock');

		this.#animation[this.#options.animation.hide.type]({duration: this.#options.animation.hide.duration}, () => {
			self.#lock = false;

			PipUI.removeClass(self.#target, self.#options.toggleTargetClass);

			if(typeof this.#options.hideEndCallback == 'function'){
				this.#options.hideEndCallback(self);
			}

			PipUI.trigger(self.#target, 'hide-end-collapse-pipui', self.#id, self.#options);

			if(PipUI.Logger && self.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('collapse.d_hide_complete'), self.#id, self.#options);
			}

			PipUI.removeClass(self.#triggers, 'collapse-lock '+self.#options.toggleTriggerClass);
		});

		/*animation(this.#target, {
			duration: self.#options.animation.hide.duration
		}, () => {

			this.#lock = false;

			PipUI.removeClass(self.#target, self.#options.toggleTargetClass);

			if(typeof this.#options.hideEndCallback == 'function'){
				this.#options.hideEndCallback(self);
			}

			PipUI.trigger(this.#target, 'hide-end-collapse-pipui', this.#id, this.#options);

			if(PipUI.Logger && self.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('collapse.d_hide_complete'), self.#id, self.#options);
			}

			PipUI.removeClass(this.#triggers, 'collapse-lock '+self.#options.toggleTriggerClass);
		});*/

		if(typeof callback == 'undefined'){
			callback = this.#options.hideStartCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'hide-start-collapse-pipui', this.#id, this.#options);

		return this;
	}
}

PipUI.ready(document, () => {
	PipUI.body('click', '[data-collapse]:not([data-collapse-id])', (e, target) => {
		e.preventDefault();

		new CollapseComponent(target.getAttribute('data-collapse'), {
			triggers: [target]
		}).toggle();
	});

	PipUI.body('click', '.accordion [data-accordion]:not([data-collapse-id])', (e, target) => {
		e.preventDefault();

		new CollapseComponent(target.getAttribute('data-accordion'), {
			triggers: [target],
			accordion: true
		}).toggle();
	});
});

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Collapse', CollapseComponent.VERSION);
	PipUI.required('Collapse', 'Storage', '1.0.0', '>=');
	PipUI.required('Collapse', 'Animation', '1.0.0', '>=');
	/** @return {CollapseComponent} */
	PipUI.Collapse = CollapseComponent;

	PipUI.i18n()
		.set('collapse.d_element_not_found', '[Collapse] Элементы не найдены')
		.set('collapse.d_create_instance', '[Collapse] Создание объекта')
		.set('collapse.d_show', '[Collapse] Появление объекта')
		.set('collapse.d_hide', '[Collapse] Скрытие объекта')
		.set('collapse.d_hide_complete', '[Collapse] Завершение скрытия объекта')
		.set('collapse.d_show_complete', '[Collapse] Завершение появления объекта');
}
class ModalComponent {
	static VERSION = '2.0.0';

	/** @return {HTMLElement} */
	#target;

	#id;

	#lock = false;

	#options = {
		debug: false,
		showCallback: undefined,
		hideCallback: undefined,
		showedCallback: undefined,
		hidedCallback: undefined,
		updateCallback: undefined,
		initCallback: undefined,

		targetActiveClass: 'modal-active',

		header: undefined,
		body: undefined,
		footer: undefined,
		close: false,

		triggers: [],

		templates: {
			modal: '<div class="modal">' +
						'<div class="modal-wrapper">' +
							'<div class="modal-content"></div>' +
						'</div>' +
					'</div>',
			header: '<div class="modal-header"></div>',
			body: '<div class="modal-body"></div>',
			footer: '<div class="modal-footer"></div>',
			close: '<a href="#" rel="nofollow" data-modal-close class="modal-close"></a>'
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} object
	 *
	 * @param {object|undefined} options
	 *
	 * @param {boolean} update
	 * */
	constructor(object, options, update) {
		this.#target = PipUI.e(object)[0];

		this.setOptions(options);

		if(typeof this.#target == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('modal.d_element_not_found'), this.#options);
			}

			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('modal.d_create_instance'), this.#options);
		}

		let id = this.#target.getAttribute('data-modal-id');

		this.#id = id ? id : Math.random().toString();

		this.#target.setAttribute('data-modal-id', this.#id);

		PipUI.addClass(this.#target, 'modal');

		this.#events();

		if(update){
			this.update();
		}else{
			this.init();
		}

		if(this.#options.triggers.length){
			this.#options.triggers.forEach((item, key) => {
				this.#options.triggers[key] = PipUI.e(item)[0];

				this.#options.triggers[key].setAttribute('data-modal-id', this.#id);
			});
		}

		PipUI.Storage.set('modal', this, this.#id);
	}



	#events() {

		let self = this;

		this.#target.addEventListener('transitionend', () => {

			self.#lock = false;

			if(self.isOpen()){
				PipUI.addClass(self.#target, self.#options.targetActiveClass);

				if(typeof self.#options.showedCallback == 'function'){
					self.#options.showedCallback(self);
				}

				PipUI.trigger(self.#target, 'showed-modal-pipui', self.#id, self.#options);

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('modal.d_show_complete'), self.#id, self.#options);
				}
			}else{
				PipUI.removeClass(self.#target, self.#options.targetActiveClass);

				if(typeof self.#options.hidedCallback == 'function'){
					self.#options.hidedCallback(self);
				}

				PipUI.trigger(self.#target, 'hided-modal-pipui', self.#id, self.#options);

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('modal.d_hide_complete'), self.#id, self.#options);
				}
			}
		});

		return this;
	}



	/**
	 * @return {this}
	 * */
	update() {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('modal.d_update'), this.#options);
		}

		this.#target.innerHTML = this.#options.templates.modal;

		let content = this.#target.querySelector('.modal-content');

		if(this.#options.close){
			content.innerHTML += this.#options.templates.close;
		}

		if(this.#options.header){
			content.innerHTML += this.#options.templates.header;

			content.querySelector('.modal-header').innerHTML = this.#options.header;
		}

		if(this.#options.body){
			content.innerHTML += this.#options.templates.body;

			content.querySelector('.modal-body').innerHTML = this.#options.body;
		}

		if(this.#options.footer){
			content.innerHTML += this.#options.templates.footer;

			content.querySelector('.modal-footer').innerHTML = this.#options.footer;
		}

		PipUI.trigger(this.#target, 'update-modal-pipui', this.#id, this.#options);

		return this;
	}



	/**
	 * @return {this}
	 * */
	init() {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('modal.d_init'), this.#options);
		}

		let close = this.#target.querySelector('.modal-close');

		if(close){
			this.#options.close = true;
		}

		let header = this.#target.querySelector('.modal-header');

		if(header){
			this.#options.header = header.innerHTML;
		}

		let body = this.#target.querySelector('.modal-body');

		if(body){
			this.#options.body = body.innerHTML;
		}

		let footer = this.#target.querySelector('.modal-footer');

		if(footer){
			this.#options.footer = footer.innerHTML;
		}

		PipUI.trigger(this.#target, 'init-modal-pipui', this.#id, this.#options);

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#target, this.#options.targetActiveClass);
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	show(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('modal.d_show'), this.#id, this.#options);
		}

		if(this.isOpen() || this.#lock){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('modal.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		this.#lock = true;

		let opened = PipUI.Storage.get('modal');

		if(typeof opened != 'undefined'){
			Object.entries(opened).forEach(modal => {

				if(modal[1].isOpen()){ modal[1].hide(); }
			});
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.showCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'show-modal-pipui', this.#id, this.#options, this);

		PipUI.addClass(this.#target, this.#options.targetActiveClass);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	hide(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('modal.d_hide'), this.#id, this.#options);
		}

		if(!this.isOpen() || this.#lock){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('modal.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		this.#lock = true;

		let options = this.getOptions();

		if(typeof callback == 'undefined'){
			callback = options.hideCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'hide-modal-pipui', this.#id, options, this);

		PipUI.removeClass(this.#target, this.#options.targetActiveClass);

		return this;
	}
}

PipUI.ready(document, () => {
	PipUI.body('click', '[data-modal]', (e, target) => {
		e.preventDefault();

		let id = target.getAttribute('data-modal-id');

		let modal = id ? PipUI.Storage.get('modal', id) : new ModalComponent(target.getAttribute('data-modal'), {triggers: [target]});

		modal.show();
	});

	PipUI.body('click', '.modal', (e, target) => {

		if(PipUI.hasClass(e.target, 'modal')){
			e.preventDefault();

			let id = target.getAttribute('data-modal-id');

			let modal = id ? PipUI.Storage.get('modal', id) : new ModalComponent(target.getAttribute('data-modal'), {triggers: [target]});

			modal.hide();
		}
	});

	PipUI.body('click', '.modal > .modal-wrapper [data-modal-close]', (e, target) => {
		e.preventDefault();


		let modal = target.closest('.modal');

		let id = modal.getAttribute('data-modal-id');

		if(id){ PipUI.Storage.get('modal', id).hide(); }
	});
});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Modal', ModalComponent.VERSION);
	PipUI.required('Modal', 'Storage', '1.0.0', '>=');
	/** @return {ModalComponent} */
	PipUI.Modal = ModalComponent;

	PipUI.i18n()
		.set('modal.d_element_not_found', '[Modal] Модальное окно не найдено')
		.set('modal.d_create_instance', '[Modal] Создание объекта')
		.set('modal.d_update', '[Modal] Обновление DOM модального окна')
		.set('modal.d_init', '[Modal] Инициализация модального окна')
		.set('modal.d_lock', '[Modal] Модальное окно заблокировано')
		.set('modal.d_show', '[Modal] Попытка открытия модального окна')
		.set('modal.d_show_complete', '[Modal] Завершение открытия модального окна')
		.set('modal.d_hide_lock', '[Modal] Попытка закрытия. Закрытие уже производится')
		.set('modal.d_hide', '[Modal] Закрытие модального окна')
		.set('modal.d_hide_complete', '[Modal] Завершение закрытия модального окна');
}
class AlertComponent {
	static VERSION = '2.0.0';

	#id;

	#target;

	#container;

	#lock = false;

	#timeout;

	#options = {
		debug: false,

		title: '',

		text: '',

		icon: '',

		placement: 'bottom-right',

		overlay: false,

		autoclose: 3000,

		closeButton: true,

		closeClick: false,

		openedClass: 'alert-active',

		openCallback: undefined,
		closeCallback: undefined,
		openedCallback: undefined,
		closedCallback: undefined,

		animation: {
			open: {
				type: 'fadeIn',
				duration: 200
			},

			close: {
				type: 'fadeOut',
				duration: 100
			}
		},

		templates: {
			alert: '<div class="alert-id">'+
						'<div class="alert-icon"></div>' +
						'<div class="alert-wrapper">' +
							'<div class="alert-text"></div>'+
							'<div class="alert-footer">' +
								'<div class="alert-title"></div>' +
								'<div class="alert-footer-close"></div>' +
							'</div>'+
						'</div>'+
					'</div>',
			close: '<button type="button" class="btn btn-transparent" data-alert-close></button>'
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 *
	 * @param {object|undefined} options
	 * */
	constructor(options) {

		this.setOptions(options);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alert.d_create_instance'), this.#options);
		}

		this.#container = document.querySelector('.alert-container');

		if(!this.#container){
			this.#container = PipUI.create('<div class="alert-container"></div>');

			document.body.append(this.#container);
		}

		this.#id = Math.random().toString();

		this.update();

		this.#events();

		this.open();

		PipUI.Storage.set('alert', this, this.#id);

		this.#updateOverlay();
	}



	#events(){
		let self = this;

		this.#target.addEventListener('transitionend', () => {
			self.#lock = false;

			if(self.isOpen()){
				if(typeof self.#options.openedCallback == 'function'){
					self.#options.openedCallback(self);
				}

				if(typeof self.#timeout != 'undefined'){
					clearTimeout(self.#timeout);
					self.#timeout = undefined;
				}

				if(self.#options.autoclose > 0){
					self.#timeout = setTimeout(() => {
						self.close();
					}, self.#options.autoclose);
				}

				PipUI.trigger(self.#target, 'opened-alert-pipui', self.#id, this.#options);

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('alert.d_opened'), self.#id, self.#options);
				}
			}else{
				if(typeof self.#options.closedCallback == 'function'){
					self.#options.closedCallback(this);
				}

				PipUI.trigger(self.#target, 'closed-alert-pipui', this.#id, this.#options);

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('alert.d_closed'), self.#id, self.#options);
				}

				self.#target.remove();

				PipUI.Storage.delete('alert', this.#id);

				self.#updateOverlay();
			}
		});

		return this;
	}



	#updateOverlay() {

		let overlay = false;

		let alerts = PipUI.Storage.get('alert');

		if(typeof alerts != 'undefined'){

			Object.keys(alerts).forEach(key => {
				let alert = alerts[key];

				if(alert.getOptions().overlay){
					overlay = true;

					return false;
				}
			});
		}

		if(overlay){
			PipUI.addClass(this.#container, 'alert-overlay');
		}else{
			PipUI.removeClass(this.#container, 'alert-overlay');
		}

		return this;
	}



	/**
	 * @return {this}
	 * */
	update() {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alert.d_update'), this.#options);
		}

		let old = this.#container.querySelector('.alert-id[data-alert-id="'+this.#id+'"]');

		if(old){ old.remove(); }

		let placement = this.#container.querySelector('.placement[data-alert-placement="'+this.#options.placement+'"]');

		if(!placement){
			placement = PipUI.create('<div class="placement" data-alert-placement="'+this.#options.placement+'"></div>');

			this.#container.append(placement);
		}

		this.#target = PipUI.create(this.#options.templates.alert);

		this.#target.setAttribute('data-alert-id', this.#id);

		if(this.#options.closeClick){
			this.#target.setAttribute('data-alert-close', 'true');
		}else{
			this.#target.removeAttribute('data-alert-close');
		}

		placement.append(this.#target);

		let text = this.#target.querySelector('.alert-text');

		if(this.#options.text){
			text.innerHTML = this.#options.text;

			PipUI.addClass(this.#target, 'text-include');

			PipUI.style(text, 'display', null);
		}else{
			text.innerHTML = '';

			PipUI.removeClass(this.#target, 'text-include');

			PipUI.style(text, 'display', 'none');
		}

		let title = this.#target.querySelector('.alert-title');

		if(this.#options.title){
			title.innerHTML = this.#options.title;

			PipUI.addClass(this.#target, 'title-include');

			PipUI.style(title, 'display', null);
		}else{
			title.innerHTML = '';

			PipUI.removeClass(this.#target, 'title-include');

			PipUI.style(title, 'display', 'none');
		}

		let icon = this.#target.querySelector('.alert-icon');

		if(this.#options.icon){
			icon.innerHTML = this.#options.icon;

			PipUI.addClass(this.#target, 'icon-include');

			PipUI.style(icon, 'display', null);
		}else{
			icon.innerHTML = '';

			PipUI.removeClass(this.#target, 'icon-include');

			PipUI.style(icon, 'display', 'none');
		}

		if(this.#options.closeButton){
			let close = this.#target.querySelector('.alert-footer-close');

			let button = PipUI.create(this.#options.templates.close);

			button.innerHTML = PipUI.i18n().get('alert.close');

			close.append(button);
		}

		let footer = this.#target.querySelector(".alert-footer");

		if(!this.#options.closeButton && !this.#options.title){
			PipUI.style(footer, 'display', 'none');
		}else{
			PipUI.style(footer, 'display', null);
		}

		if(!this.#options.title && !this.#options.text && !this.#options.icon){
			PipUI.style(this.#target, 'display', 'none');
		}

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#target, this.#options.openedClass);
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	open(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alert.d_open'), this.#id, this.#options);
		}

		if(this.isOpen() || this.#lock){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('alert.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		this.#lock = true;

		let self = this;

		if(typeof callback == 'undefined'){
			callback = self.#options.openCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'open-alert-pipui', this.#id, self.#options, this);

		setTimeout(() => PipUI.addClass(this.#target, this.#options.openedClass), 100);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	close(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('alert.d_close'), this.#id, this.#options);
		}

		if(!this.isOpen() || this.#lock){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('alert.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		this.#lock = true;

		if(typeof this.#timeout != 'undefined'){
			clearTimeout(this.#timeout);

			this.#timeout = undefined;
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.closeCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'close-alert-pipui', this.#id, this.#options, this);

		PipUI.removeClass(this.#target, this.#options.openedClass);

		return this;
	}
}

PipUI.ready(document, () => {
	PipUI.body('click', '.alert-container [data-alert-close]', (e, target) => {
		e.preventDefault();

		PipUI.Storage.get('alert', target.closest('.alert-id').getAttribute('data-alert-id')).close();
	});

	PipUI.body('click', '[data-alert]', (e, target) => {
		e.preventDefault();

		let placement = target.getAttribute('data-alert-placement');

		let autoclose = parseInt(target.getAttribute('data-alert-autoclose'));

		let options = {
			title: target.getAttribute('data-alert-title'),
			text: target.getAttribute('data-alert-text'),
			overlay: target.getAttribute('data-alert-overlay') === 'true'
		};

		if(placement){
			options.placement = placement;
		}

		if(!isNaN(autoclose)){
			options.autoclose = autoclose;
		}

		new PipUI.Alert(options);
	});
});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Alert', AlertComponent.VERSION);
	PipUI.required('Alert', 'Storage', '1.0.0', '>=');
	/** @return {AlertComponent} */
	PipUI.Alert = AlertComponent;

	PipUI.i18n()
		.set('alert.close', 'ЗАКРЫТЬ')
		.set('alert.d_create_instance', '[Alert] Создание объекта')
		.set('alert.d_open', '[Alert] Открытие оповещения')
		.set('alert.d_opened', '[Alert] Завершение открытия оповещения')
		.set('alert.d_close', '[Alert] Закрытие оповещения')
		.set('alert.d_closed', '[Alert] Звершение закрытия оповещения')
		.set('alert.d_lock', '[Alert] Оповещение заблокировано');
}
class ConfirmComponent {
	static VERSION = '2.0.0';

	#id;

	#target;

	#container;

	#lock = false;

	#locked = false;

	#timeout;

	#options = {
		debug: false,

		title: '',

		text: '',

		placement: 'top-center',

		confirmBtn: undefined,

		cancelBtn: undefined,

		overlay: true,

		autoclose: 0,

		openedClass: 'confirm-active',

		openCallback: undefined,
		closeCallback: undefined,
		openedCallback: undefined,
		closedCallback: undefined,

		confirm: undefined,

		cancel: undefined,

		templates: {
			confirm: '<div class="confirm-id">'+
						'<div class="confirm-wrapper">' +
							'<div class="confirm-title"></div>' +
							'<div class="confirm-text"></div>'+
							'<div class="confirm-footer">' +
								'<button type="button" class="btn confirm-btn" data-confirm-confirm></button>' +
								'<button type="button" class="btn cancel-btn btn-transparent" data-confirm-cancel></button>' +
							'</div>'+
						'</div>'+
					'</div>'
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 *
	 * @param {object|undefined} options
	 * */
	constructor(options) {

		this.setOptions(options);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('confirm.d_create_instance'), this.#options);
		}

		this.#container = document.querySelector('.confirm-container');

		if(!this.#container){
			this.#container = PipUI.create('<div class="confirm-container"></div>');

			document.body.append(this.#container);
		}

		this.#id = Math.random().toString();

		this.update();

		this.#events();

		this.open();

		PipUI.Storage.set('confirm', this, this.#id);

		this.#updateOverlay();
	}



	#events(){

		let self = this;

		this.#target.addEventListener('transitionend', () => {
			self.#lock = false;

			self.#locked = false;

			if(self.isOpen()){
				PipUI.addClass(self.#target, self.#options.openedClass);

				if(typeof self.#options.openedCallback == 'function'){
					self.#options.openedCallback(self);
				}

				if(typeof self.#timeout != 'undefined'){
					clearTimeout(self.#timeout);
					self.#timeout = undefined;
				}

				if(self.#options.autoclose > 0){
					self.#timeout = setTimeout(() => {
						self.close();
					}, self.#options.autoclose);
				}

				PipUI.trigger(self.#target, 'opened-confirm-pipui', self.#id, self.#options);

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('confirm.d_opened'), self.#id, self.#options);
				}
			}else{

				PipUI.removeClass(self.#target, self.#options.openedClass);

				if(typeof self.#options.closedCallback == 'function'){
					self.#options.closedCallback(self);
				}

				PipUI.trigger(self.#target, 'closed-confirm-pipui', self.#id, self.#options);

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('confirm.d_closed'), self.#id, self.#options);
				}

				self.#target.remove();

				PipUI.Storage.delete('confirm', self.#id);

				self.#updateOverlay();
			}
		});

		return this;
	}



	#updateOverlay() {

		let overlay = false;

		let confirms = PipUI.Storage.get('confirm');

		if(typeof confirms != 'undefined'){

			Object.keys(confirms).forEach(key => {
				let confirm = confirms[key];

				if(confirm.getOptions().overlay){
					overlay = true;

					return false;
				}
			});
		}

		if(overlay){
			PipUI.addClass(this.#container, 'confirm-overlay');
		}else{
			PipUI.removeClass(this.#container, 'confirm-overlay');
		}

		return this;
	}



	/**
	 * @return {this}
	 * */
	update() {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('confirm.d_update'), this.#options);
		}

		let old = this.#container.querySelector('.confirm-id[data-confirm-id="'+this.#id+'"]');

		if(old){ old.remove(); }

		let placement = this.#container.querySelector('.placement[data-confirm-placement="'+this.#options.placement+'"]');

		if(!placement){
			placement = PipUI.create('<div class="placement" data-confirm-placement="'+this.#options.placement+'"></div>');

			this.#container.append(placement);
		}

		this.#target = PipUI.create(this.#options.templates.confirm);

		this.#target.setAttribute('data-confirm-id', this.#id);

		placement.append(this.#target);

		let text = this.#target.querySelector('.confirm-text');

		if(this.#options.text){
			text.innerHTML = this.#options.text;

			PipUI.addClass(this.#target, 'text-include');

			PipUI.style(text, 'display', null);
		}else{
			text.innerHTML = '';

			PipUI.removeClass(this.#target, 'text-include');

			PipUI.style(text, 'display', 'none');
		}

		let title = this.#target.querySelector('.confirm-title');

		if(this.#options.title){
			title.innerHTML = this.#options.title;

			PipUI.addClass(this.#target, 'title-include');

			PipUI.style(title, 'display', null);
		}else{
			title.innerHTML = '';

			PipUI.removeClass(this.#target, 'title-include');

			PipUI.style(title, 'display', 'none');
		}

		if(!this.#options.title && !this.#options.text){
			PipUI.style(this.#target, 'display', 'none');
		}

		let confirmBtn = this.#target.querySelector('.confirm-btn');

		let cancelBtn = this.#target.querySelector('.cancel-btn');

		confirmBtn.innerHTML = this.#options.confirmBtn ? this.#options.confirmBtn : PipUI.i18n().get('confirm.confirm');

		cancelBtn.innerHTML = this.#options.cancelBtn ? this.#options.cancelBtn : PipUI.i18n().get('confirm.cancel');

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#target, this.#options.openedClass);
	}



	confirm() {
		if(this.#locked){ return this; }

		this.#locked = true;

		if(typeof this.#options.confirm == 'function'){
			this.#options.confirm(this);
		}

		PipUI.trigger(this.#target, 'confirm-confirm-pipui', this.#id, this.#options, this);

		return this.close();
	}



	cancel() {
		if(this.#locked){ return this; }

		this.#locked = true;

		if(typeof this.#options.cancel == 'function'){
			this.#options.cancel(this);
		}

		PipUI.trigger(this.#target, 'cancel-confirm-pipui', this.#id, this.#options, this);

		return this.close();
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	open(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('confirm.d_open'), this.#id, this.#options);
		}

		if(this.isOpen() || this.#lock){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('confirm.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		this.#lock = true;

		if(typeof callback == 'undefined'){
			callback = this.#options.openCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'open-confirm-pipui', this.#id, this.#options, this);

		setTimeout(() => PipUI.addClass(this.#target, this.#options.openedClass), 100);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	close(callback) {

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('confirm.d_close'), this.#id, this.#options);
		}

		if(!this.isOpen() || this.#lock){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('confirm.d_lock'), this.#id, this.#options);
			}

			return this;
		}

		this.#lock = true;

		if(typeof this.#timeout != 'undefined'){
			clearTimeout(this.#timeout);

			this.#timeout = undefined;
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.closeCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#target, 'close-confirm-pipui', this.#id, this.#options, this);

		PipUI.removeClass(this.#target, this.#options.openedClass);

		return this;
	}
}

PipUI.ready(document, () => {
	PipUI.body('click', '.confirm-container [data-confirm-cancel], .confirm-container [data-confirm-confirm]', (e, target) => {
		e.preventDefault();

		/** @return {ConfirmComponent} */
		let confirm = PipUI.Storage.get('confirm', target.closest('.confirm-id').getAttribute('data-confirm-id'));

		if(target.hasAttribute('data-confirm-confirm')){
			confirm.confirm();
		}else{
			confirm.cancel();
		}
	});

	PipUI.body('click', '[data-confirm]', (e, target) => {
		e.preventDefault();

		let placement = target.getAttribute('data-confirm-placement');

		let autoclose = parseInt(target.getAttribute('data-confirm-autoclose'));

		let confirmCallback = target.getAttribute('data-confirm-confirm');

		let cancelCallback = target.getAttribute('data-confirm-cancel');

		let confirmBtn = target.getAttribute('data-confirm-confirm-btn');

		let cancelBtn = target.getAttribute('data-confirm-cancel-btn');

		let options = {
			title: target.getAttribute('data-confirm-title'),
			text: target.getAttribute('data-confirm-text'),
			overlay: target.getAttribute('data-confirm-overlay') !== 'false',
			confirm: typeof confirmCallback != 'undefined' ? window[confirmCallback] : undefined,
			cancel: typeof cancelCallback != 'undefined' ? window[cancelCallback] : undefined,
		};

		if(confirmBtn){
			options.confirmBtn = confirmBtn;
		}

		if(cancelBtn){
			options.cancelBtn = cancelBtn;
		}

		if(placement){
			options.placement = placement;
		}

		if(!isNaN(autoclose)){
			options.autoclose = autoclose;
		}

		new PipUI.Confirm(options);
	});
});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Confirm', ConfirmComponent.VERSION);
	PipUI.required('Confirm', 'Storage', '1.0.0', '>=');
	PipUI.required('Confirm', 'Animation', '1.0.0', '>=');
	/** @return {ConfirmComponent} */
	PipUI.Confirm = ConfirmComponent;

	PipUI.i18n()
		.set('confirm.confirm', 'Подтвердить')
		.set('confirm.cancel', 'Отмена')
		.set('confirm.d_create_instance', '[Confirm] Создание объекта')
		.set('confirm.d_open', '[Confirm] Открытие подтверждения')
		.set('confirm.d_opened', '[Confirm] Завершение открытия подтверждения')
		.set('confirm.d_close', '[Confirm] Закрытие подтверждения')
		.set('confirm.d_closed', '[Confirm] Звершение закрытия подтверждения')
		.set('confirm.d_lock', '[Confirm] Подтверждение заблокировано');
}
class BBPanelComponent {
	static VERSION = '2.0.0';

	#id;

	/** @return {HTMLElement} */
	#wrapper;

	/** @return {HTMLElement} */
	#form;

	/** @return {HTMLElement} */
	#panel;

	/** @return {HTMLElement} */
	#popup;

	#pressed = {};

	#animation;

	#popupPositions = {top: 0, left: 0};

	#history = [];

	#historyRedo = [];

	#cb = {};

	#options = {
		debug: false,

		focusClass: 'focus',

		formClass: 'bbpanel-form',

		popup: {
			enable: true,
			format: 'b,i,u,s,|,left,center,right,|,urlAlt',
			template: '<div class="popup-list"></div>',
			activeClass: 'popup-active'
		},

		tooltip: true,

		showCallback: undefined,

		hideCallback: undefined,

		callcodeCallback: undefined,

		undoCallback: undefined,

		redoCallback: undefined,

		changeCallback: undefined,

		format: 'undo,|,redo,|,b,i,u,s,|,left,center,right,|,size,color,|,spoiler,quote,code,|,img,youtube,|,urlAlt,line,|,hide',

		maxHistory: 64,

		codes: {
			undo: {title: PipUI.i18n().get('bbpanel.undo'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-arrow-rotate-left"></i></div>', hotkey: 'control+z', callback: self => self.undo()},
			redo: {title: PipUI.i18n().get('bbpanel.redo'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-arrow-rotate-right"></i></div>', hotkey: 'control+y', callback: self => self.redo()},
			b: {title: PipUI.i18n().get('bbpanel.b'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-bold"></i></div>', left: '[b]', right: '[/b]', hotkey: 'control+b'},
			i: {title: PipUI.i18n().get('bbpanel.i'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-italic"></i></div>', left: '[i]', right: '[/i]', hotkey: 'control+i'},
			u: {title: PipUI.i18n().get('bbpanel.u'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-underline"></i></div>', left: '[u]', right: '[/u]', hotkey: 'control+u'},
			s: {title: PipUI.i18n().get('bbpanel.s'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-strikethrough"></i></div>', left: '[s]', right: '[/s]', hotkey: 'control+s'},
			left: {title: PipUI.i18n().get('bbpanel.left'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-align-left"></i></div>', left: '[left]', right: '[/left]', hotkey: 'control+arrowleft'},
			center: {title: PipUI.i18n().get('bbpanel.center'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-align-center"></i></div>', left: '[center]', right: '[/center]', hotkey: 'control+arrowdown'},
			right: {title: PipUI.i18n().get('bbpanel.right'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-align-right"></i></div>', left: '[right]', right: '[/right]', hotkey: 'control+arrowright'},
			spoiler: {title: PipUI.i18n().get('bbpanel.spoiler'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-eye-slash"></i></div>', left: '[spoiler=""]', right: '[/spoiler]', hotkey: 'control+1'},
			color: {title: PipUI.i18n().get('bbpanel.color'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-paintbrush"></i></div>', left: '[color="#"]', right: '[/color]', hotkey: 'control+2'},
			size: {title: PipUI.i18n().get('bbpanel.size'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-text-height"></i></div>', left: '[size="1"]', right: '[/size]', hotkey: 'control+3'},
			img: {title: PipUI.i18n().get('bbpanel.img'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-image"></i></div>', left: '[img]', right: '[/img]', hotkey: 'control+4'},
			quote: {title: PipUI.i18n().get('bbpanel.quote'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-quote-right"></i></div>', left: '[quote]', right: '[/quote]', hotkey: 'control+q'},
			urlAlt: {title: PipUI.i18n().get('bbpanel.urlAlt'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-link"></i></div>', left: '[url=""]', right: '[/url]', hotkey: 'control+5'},
			code: {title: PipUI.i18n().get('bbpanel.code'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-code"></i></div>', left: '[code]', right: '[/code]', hotkey: 'control+6'},
			line: {title: PipUI.i18n().get('bbpanel.line'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-minus"></i></div>', left: '', right: '[line]', hotkey: 'control+l'},
			youtube: {title: PipUI.i18n().get('bbpanel.youtube'), text: '<div class="bbcode-trigger"><i class="fa-brands fa-youtube"></i></div>', left: '[youtube]', right: '[/youtube]', hotkey: 'control+7'},
			hide: {title: PipUI.i18n().get('bbpanel.hide'), text: '<div class="bbcode-trigger"><i class="fa-solid fa-angle-up"></i></div>', callback: self => {
					self.hide();

					self.#form.blur();
				}}
		},

		templates: {
			wrapper: '<div class="bbpanel"></div>',
			separator: '<div class="bbcode-separator"></div>',
			panel: '<div class="bbcode-list"></div>'
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} form
	 *
	 * @param {object|undefined} options
	 * */
	constructor(form, options) {

		this.#form = PipUI.e(form)[0];

		this.setOptions(options);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_create_instance'), this.#options);
		}

		if(typeof this.#form == 'undefined'){
			if(PipUI.Logger && options.debug){
				PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_form_not_found'), options);
			}

			return this;
		}

		this.#id = Math.random().toString();

		PipUI.addClass(this.#form, this.#options.formClass);

		PipUI.removeClass(this.#form, 'bbpanel');

		this.#form.setAttribute('data-bbpanel-id', this.#id);

		this.#render();

		this.#animation = new PipUI.Animation(this.#panel);

		PipUI.Storage.set('bbpanel', this, this.#id);
	}



	/**
	 * @return {this}
	 * */
	#renderList(list, format){

		let split = format.split(',');

		list.innerHTML = '';

		for(let i = 0; i < split.length; i++){
			let v = split[i];

			if(v == '|'){
				let separator = PipUI.create(this.#options.templates.separator);

				list.append(separator);
			}else{
				let code = this.#options.codes[v];

				if(typeof code == 'undefined'){ return; }

				let html = PipUI.create(code.text);

				html.setAttribute('data-bbpanel-bbcode', v);

				if(typeof code.title != 'undefined'){
					if(this.#options.tooltip){
						html.setAttribute('data-tooltip', code.title);
					}else{
						html.setAttribute('title', code.title);
					}
				}

				list.append(html);
			}
		}

		return this;
	}



	/**
	 * @return {this}
	 * */
	#render(){

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_render'), this.#options);
		}

		let self = this;

		this.#wrapper = PipUI.create(this.#options.templates.wrapper);

		this.#form.after(this.#wrapper);

		this.#wrapper.append(this.#form);

		this.#panel = PipUI.create(this.#options.templates.panel);

		this.#wrapper.setAttribute('data-bbpanel-id', this.#id);

		this.#wrapper.append(this.#panel);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_render_panel'), this.#options);
		}

		this.#renderList(this.#panel, this.#options.format);

		if(this.#options.popup.enable){

			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_render_popup'), this.#options);
			}

			this.#popup = PipUI.create(this.#options.popup.template);

			this.#wrapper.append(this.#popup);

			this.#renderList(this.#popup, this.#options.popup.format);
		}else{
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_popup_disabled'), this.#options);
			}
		}



		this.#cb.focusIn = () => {
			self.show();
		}

		this.#form.addEventListener('focusin', this.#cb.focusIn);



		this.#cb.clickTrigger = e => {
			e.preventDefault();

			self.callCode(e.target.closest('.bbcode-trigger').getAttribute('data-bbpanel-bbcode'));
		}

		this.#wrapper.querySelectorAll('.bbcode-trigger').forEach(item => {
			item.addEventListener('click', this.#cb.clickTrigger);
		});



		this.#cb.change = () => self.change();

		this.#form.addEventListener('input', this.#cb.change);



		this.#cb.mousedown = e => {

			let offset = self.#wrapper.getBoundingClientRect();


			self.#popupPositions.top = e.clientY - offset.top;
			self.#popupPositions.left = e.clientX - offset.left;
		}

		this.#form.addEventListener('mousedown', this.#cb.mousedown);




		this.#cb.keydown = e => {
			let key = e.key.toLowerCase();

			if(!self.#pressed[key]){ self.#pressed[key] = true; }

			Object.keys(this.#options.codes).forEach(k => {
				let v = this.#options.codes[k];

				if(typeof v.hotkey == 'undefined'){ return; }

				let press = k;

				v.hotkey.split('+').forEach(vp => {
					if(!self.#pressed[vp]){ press = false; return false; }
				});

				if(press){
					e.preventDefault();

					self.callCode(press);

					return false;
				}
			});
		}

		this.#form.addEventListener('keydown', this.#cb.keydown);


		this.#cb.keyup = e => {
			let key = e.key.toLowerCase();

			if(self.#pressed[key]){ self.#pressed[key] = false; }
		}

		this.#form.addEventListener('keyup', this.#cb.keyup);



		this.#cb.clickForm = e => {
			if(self.#options.popup.enable){

				let start = e.target.selectionStart;

				let end = e.target.selectionEnd;

				if(start != end){

					let height = self.#popup.clientHeight;

					PipUI.style(self.#popup, {top: (self.#popupPositions.top - height)+'px', left: self.#popupPositions.left+'px'});

					PipUI.addClass(self.#popup, 'popup-active');

				}else{
					PipUI.removeClass(self.#popup, 'popup-active');
				}
			}
		}

		this.#form.addEventListener('click', this.#cb.clickForm);



		return this;
	}



	/**
	 * @param {string} key
	 *
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	callCode(key, callback){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_bbcall') + key, this.#options);
		}

		let code = this.#options.codes[key];

		if(typeof code == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.error(PipUI.i18n().get('bbpanel.d_bbnotfound') + key, this.#options);
			}

			return this;
		}

		this.#form.focus();

		if(typeof callback == 'undefined'){
			callback = this.#options.callcodeCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'callcode-bbpanel-pipui', key, this.#id, this.#options, this);

		if(typeof code.callback == 'function'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_bbcallback') + key, this.#options);
			}

			code.callback(this);

			return this;
		}else{
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_bbcallback_disabled') + key, this.#options);
			}
		}

		let startCaret = this.#form.selectionStart;
		let endCaret = this.#form.selectionEnd;

		let beforeText = this.#form.value.substring(0, startCaret);
		let afterText = this.#form.value.substr(endCaret);
		let currentText = this.#form.value.substring(startCaret, endCaret);

		this.change();

		this.#form.value = beforeText;

		let rightCaret = endCaret;

		if(typeof code.left != 'undefined'){
			this.#form.value += code.left;

			rightCaret = rightCaret + code.left.length;
		}

		this.#form.value += currentText;

		if(typeof code.right != 'undefined'){
			this.#form.value += code.right;

			rightCaret = rightCaret + code.right.length;
		}

		this.#form.value += afterText;

		this.#form.selectionStart = startCaret;
		this.#form.selectionEnd = rightCaret;

		this.#form.setSelectionRange(startCaret, rightCaret);

		return this.change();
	}


	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this} */
	change(callback){
		this.#historyRedo = [];

		let length = this.#history.length;

		let prev = this.#history[length - 1];

		if(typeof prev != 'undefined' && prev.value == this.#form.value && prev.startCaret == this.#form.selectionStart && prev.endCaret == this.#form.selectionEnd){
			return;
		}

		let data = {
			value: this.#form.value,
			startCaret: this.#form.selectionStart,
			endCaret: this.#form.selectionEnd
		};

		this.#history.push(data);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_change'), this.#options, data);
		}

		length++;

		if(length > this.#options.maxHistory + 1){
			this.#history.splice(0, length - this.#options.maxHistory + 1);
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.changeCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'change-bbpanel-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this} */
	undo(callback){
		if(this.#history.length <= 0){
			return this;
		}

		let history = this.#history[this.#history.length - 2];

		this.#form.focus();

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_undo'), this.#options);
		}

		if(typeof history == 'undefined'){
			this.#form.value = '';

			this.#form.setSelectionRange(0, 0);

			return this;
		}

		this.#form.value = history.value;

		this.#form.setSelectionRange(history.startCaret, history.endCaret);

		let pop = this.#history.pop();

		this.#historyRedo.push(pop);

		if(typeof callback == 'undefined'){
			callback = this.#options.undoCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'undo-bbpanel-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this} */
	redo(callback){
		let histories = this.#historyRedo;

		if(histories.length <= 0){
			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_redo'), this.#options);
		}

		let history = this.#historyRedo.pop();

		if(typeof history == 'undefined'){
			history = {value: '', startCaret: 0, endCaret: 0};
		}

		this.#form.focus();

		this.#form.value = history.value;

		this.#form.setSelectionRange(history.startCaret, history.endCaret);

		this.#history.push(history);

		if(typeof callback == 'undefined'){
			callback = this.#options.redoCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'redo-bbpanel-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this} */
	hide(callback) {
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_hide'), this.#options);
		}

		PipUI.removeClass(this.#form, this.#options.focusClass);

		PipUI.removeClass(this.#panel, this.#options.focusClass);

		if(this.#options.popup.enable){
			PipUI.removeClass(this.#popup, this.#options.popup.activeClass);
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.hideCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'hide-bbpanel-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this} */
	show(callback) {
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('bbpanel.d_show'), this.#options);
		}

		PipUI.addClass(this.#form, this.#options.focusClass);

		PipUI.addClass(this.#panel, this.#options.focusClass);

		if(typeof callback == 'undefined'){
			callback = this.#options.showCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'show-bbpanel-pipui', this.#id, this.#options, this);

		this.#form.focus();

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpen() {
		return PipUI.hasClass(this.#panel, this.#options.focusClass);
	}
}

PipUI.ready(document, () => {
	PipUI.body('focusin', '.bbpanel:not([data-bbpanel-id])', (event, target) => {
		let panel = new PipUI.BBPanel(target);

		setTimeout(() => { panel.show(); }, 0);
	});

	document.addEventListener('click', e => {

		let panels = PipUI.Storage.get('bbpanel');

		if(panels){
			let c = e.target.closest('[data-bbpanel-id]');

			let id = c ? c.getAttribute('data-bbpanel-id') : undefined;

			Object.keys(panels).forEach(key => {
				if(id !== key){
					panels[key].hide();
				}
			});
		}
	});
});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('BBPanel', BBPanelComponent.VERSION);
	PipUI.required('BBPanel', 'Storage', '1.0.0', '>=');
	/** @return {BBPanelComponent} */
	PipUI.BBPanel = BBPanelComponent;

	PipUI.i18n()
		.set('bbpanel.b', 'Жирный')
		.set('bbpanel.i', 'Курсив')
		.set('bbpanel.u', 'Подчёркнутый')
		.set('bbpanel.s', 'Зачёркнутый')
		.set('bbpanel.left', 'Выравнивание по левому краю')
		.set('bbpanel.center', 'Выравнивание по центру')
		.set('bbpanel.right', 'Выравнивание по правому краю')
		.set('bbpanel.spoiler', 'Скрытый текст')
		.set('bbpanel.color', 'Цвет текста')
		.set('bbpanel.size', 'Размер текста')
		.set('bbpanel.img', 'Изображение')
		.set('bbpanel.quote', 'Цитата')
		.set('bbpanel.urlAlt', 'Ссылка')
		.set('bbpanel.code', 'Код')
		.set('bbpanel.line', 'Горизонтальная линия')
		.set('bbpanel.youtube', 'Ссылка на YouTube видео')
		.set('bbpanel.hide', 'Скрыть панель')
		.set('bbpanel.undo', 'Отменить')
		.set('bbpanel.redo', 'Вернуть')
		.set('bbpanel.d_create_instance', '[BBPanel] Создание экземпляра класса')
		.set('bbpanel.d_form_not_found', '[BBPanel] Форма не найдена')
		.set('bbpanel.d_render', '[BBPanel] Запуск рендера формы')
		.set('bbpanel.d_show', '[BBPanel] Появление панели')
		.set('bbpanel.d_hide', '[BBPanel] Исчезание панели')
		.set('bbpanel.d_render_panel', '[BBPanel] Запуск рендера списка кодов панели')
		.set('bbpanel.d_render_popup', '[BBPanel] Запуск рендера списка кодов Popup')
		.set('bbpanel.d_popup_disabled', '[BBPanel] Popup\'ы отключены')
		.set('bbpanel.d_change', '[BBPanel] Изменение значения. Событие change')
		.set('bbpanel.d_bbcall', '[BBPanel] Обращение к ББ коду ')
		.set('bbpanel.d_bbnotfound', '[BBPanel] ББ код не найден ')
		.set('bbpanel.d_bbcallback', '[BBPanel] Запуск функции обратного вызова кода ')
		.set('bbpanel.d_bbcallback_disabled', '[BBPanel] Функция обратного вызова кода отключена ')
		.set('bbpanel.d_undo', '[BBPanel] Undo')
		.set('bbpanel.d_redo', '[BBPanel] Redo');
}
class DatepickerComponent {
	static VERSION = '2.0.0';

	/** @return {Date} */
	#current = new Date();

	/** @return {Date} */
	#now = new Date();

	/** @return {Date|undefined} */
	#selected;

	/** @return {Date} */
	#tmp = new Date();

	/** @return {HTMLElement} */
	#form;

	/** @return {HTMLElement} */
	#container;

	/** @return {HTMLElement} */
	#wrapper;

	/** @return {HTMLElement} */
	#tabs;

	/** @return {HTMLElement} */
	#dateBlock;

	/** @return {HTMLElement} */
	#timeBlock;

	/** @return {HTMLElement} */
	#yearBlock;

	/** @return {HTMLElement} */
	#monthBlock;

	/** @return {HTMLElement} */
	#weekBlock;

	/** @return {HTMLElement} */
	#dayBlock;

	/** @return {HTMLElement} */
	#year;

	/** @return {HTMLElement} */
	#month;

	/** @return {HTMLElement} */
	#hours;

	/** @return {HTMLElement} */
	#minutes;

	/** @return {HTMLElement} */
	#seconds;

	#inputs = {
		/** @return {HTMLElement} */
		hours: undefined,

		/** @return {HTMLElement} */
		minutes: undefined,

		/** @return {HTMLElement} */
		seconds: undefined
	};

	/** @return {HTMLElement} */
	#footer;

	#id;

	#options = {
		debug: false,

		showedClass: 'datepicker-active',

		date: true,

		time: true,

		year: true,

		month: true,

		day: true,

		hours: true,

		minutes: true,

		seconds: true,

		changeCallback: undefined,

		updateCallback: undefined,

		showCallback: undefined,

		hideCallback: undefined,

		format: 'd.m.Y H:i:s',

		formating: (date, self) => PipUI.Date.format(date, self.#options.format),

		parse: (str, self) => {

			if(!str){ return undefined; }

			if(!self.#options.date && !self.#options.time){ return undefined; }

			let year = 0, month = 0, day = 0, hours = 0, minutes = 0, seconds = 0;

			let split = str.split(' ');

			if(self.#options.date){
				let part = split[0].split('.');

				year = part[2];

				month = parseInt(part[1]) - 1;

				day = parseInt(part[0]);
			}

			if(self.#options.time){
				let part = typeof split[1] == 'undefined' ? split[0].split(':') : split[1].split(':');

				hours = parseInt(part[0]);

				minutes = parseInt(part[1]);

				seconds = parseInt(part[2]);
			}

			return new Date(year, month, day, hours, minutes, seconds);
		},

		templates: {
			container: '<div class="datepicker"></div>',
			wrapper: '<div class="datepicker-wrapper"></div>',
			tabs: '<div class="datepicker-tabs"></div>',
			date: {
				wrapper: '<div class="date-block"></div>',

				year: '<div class="year-block">' +
							'<span class="year-prev"><i class="fa-solid fa-angle-left"></i></span>' +
							'<input type="number" class="input year-input">' +
							'<span class="year-next"><i class="fa-solid fa-angle-right"></i></span>' +
						'</div>',

				month: '<div class="month-block">' +
							'<span class="month-prev"><i class="fa-solid fa-angle-left"></i></span>' +
							'<select class="input month-input"></select>' +
							'<span class="month-next"><i class="fa-solid fa-angle-right"></i></span>' +
						'</div>',

				week: '<div class="week-block"></div>',

				day: '<div class="day-block"></div>'
			},
			time: {
				wrapper: '<div class="time-block">' +
							'<div class="time-visual"></div>' +
							'<div class="time-inputs"></div>' +
						'</div>',
				visual: {
					hours: '<div class="visual-block hours-visual">-</div>',
					minutes: '<div class="visual-block minutes-visual">-</div>',
					seconds: '<div class="visual-block seconds-visual">-</div>',
				},
				inputs: {
					hours: '<div class="block-input-time"><label>'+PipUI.i18n().get('date.hours')+'</label><input type="range" min="0" max="23" class="input hours-input"></div>',
					minutes: '<div class="block-input-time"><label>'+PipUI.i18n().get('date.minutes')+'</label><input type="range" min="0" max="59" class="input minutes-input"></div>',
					seconds: '<div class="block-input-time"><label>'+PipUI.i18n().get('date.seconds')+'</label><input type="range" min="0" max="59" class="input seconds-input"></div>',
				},
				separator: '<div class="time-separator"></div>'
			},
			footer: {
				wrapper: '<div class="datepicker-footer"></div>',
				cancel: '<button type="button" class="btn datepicker-cancel">'+PipUI.i18n().get('datepicker.cancel')+'</button>',
				next: '<button type="button" class="btn datepicker-next datepicker-change-tab" data-datepicker-tab="time">'+PipUI.i18n().get('datepicker.next')+'</button>',
				prev: '<button type="button" class="btn datepicker-prev datepicker-change-tab" data-datepicker-tab="date">'+PipUI.i18n().get('datepicker.prev')+'</button>',
				done: '<button type="button" class="btn datepicker-done">'+PipUI.i18n().get('datepicker.done')+'</button>'
			}

		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} form
	 *
	 * @param {object|undefined} options
	 * */
	constructor(form, options) {
		this.#form = PipUI.e(form)[0];

		this.setOptions(options);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_create_instance'), this.#options);
		}

		if(typeof this.#form == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('datepicker.d_element_not_found'), this.#options);
			}

			return this;
		}

		this.#id = Math.random().toString();

		this.#form.setAttribute('data-datepicker-id', this.#id);

		this.#form.setAttribute('data-datepicker', 'true');

		this.#container = PipUI.create(this.#options.templates.container);

		this.#container.setAttribute('data-datepicker-id', this.#id);

		this.#wrapper = PipUI.create(this.#options.templates.wrapper);

		this.#tabs = PipUI.create(this.#options.templates.tabs);

		if(this.#options.date){
			this.#dateBlock = PipUI.create(this.#options.templates.date.wrapper);

			if(this.#options.year){
				this.#yearBlock = PipUI.create(this.#options.templates.date.year);

				this.#year = this.#yearBlock.querySelector('.year-input');

				this.#dateBlock.append(this.#yearBlock);
			}

			if(this.#options.month){
				this.#monthBlock = PipUI.create(this.#options.templates.date.month);

				this.#month = this.#monthBlock.querySelector('.month-input');

				PipUI.Date.months.forEach((name, key) => {
					this.#month.append(PipUI.create('<option value="'+key+'">'+name+'</option>'));
				});

				this.#dateBlock.append(this.#monthBlock);
			}

			if(this.#options.day){
				this.#weekBlock = PipUI.create(this.#options.templates.date.week);

				for(let i = 0; i < 7; i++){
					let week = PipUI.create('<div class="week-id" data-datepicker-week-num="'+(i+1)+'">'+PipUI.Date._week[i]+'</div>');

					this.#weekBlock.append(week);
				}

				this.#dateBlock.append(this.#weekBlock);
			}

			this.#tabs.append(this.#dateBlock);

			PipUI.addClass(this.#container, 'date-picker');
		}

		if(this.#options.time){
			this.#timeBlock = PipUI.create(this.#options.templates.time.wrapper);

			let visual = this.#timeBlock.querySelector('.time-visual');

			let inputs = this.#timeBlock.querySelector('.time-inputs');

			if(this.#options.hours){
				this.#hours = PipUI.create(this.#options.templates.time.visual.hours);

				let hours = PipUI.create(this.#options.templates.time.inputs.hours);

				this.#inputs.hours = hours.querySelector('.hours-input');

				this.#inputs.hours.value = this.#now.getHours();

				visual.append(this.#hours);

				inputs.append(hours);
			}

			if(this.#options.minutes){
				this.#minutes = PipUI.create(this.#options.templates.time.visual.minutes);

				if(this.#options.hours){
					visual.append(PipUI.create(this.#options.templates.time.separator));
				}

				let minutes = PipUI.create(this.#options.templates.time.inputs.minutes);

				this.#inputs.minutes = minutes.querySelector('.minutes-input');

				this.#inputs.minutes.value = this.#now.getMinutes();

				visual.append(this.#minutes);

				inputs.append(minutes);
			}

			if(this.#options.seconds){
				this.#seconds = PipUI.create(this.#options.templates.time.visual.seconds);

				if(this.#options.hours || this.#options.minutes){
					visual.append(PipUI.create(this.#options.templates.time.separator));
				}

				visual.append(this.#seconds);
			}

			let seconds = PipUI.create(this.#options.templates.time.inputs.seconds);

			this.#inputs.seconds = seconds.querySelector('.seconds-input');

			this.#inputs.seconds.value = this.#now.getSeconds();

			this.#tabs.append(this.#timeBlock);

			inputs.append(seconds);

			PipUI.addClass(this.#container, 'time-picker');
		}


		this.#footer = PipUI.create(this.#options.templates.footer.wrapper);


		this.#wrapper.append(this.#tabs, this.#footer);


		this.#container.append(this.#wrapper);

		document.body.append(this.#container);

		this.update();

		if(this.#options.date){
			this.setActiveDate();
		}else if(this.#options.time){
			this.setActiveTime();
		}

		PipUI.Storage.set('datepicker', this, this.#id);
	}



	/**
	 * @return {this}
	 * */
	setActiveDate(){

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_set_tab_date'), this.#options);
		}

		if(!this.#options.date){ return this; }

		if(this.#options.time){
			PipUI.removeClass(this.#timeBlock, 'active-tab');
		}

		PipUI.addClass(this.#dateBlock, 'active-tab');

		this.#footer.innerHTML = '';

		this.#footer.append(PipUI.create(this.#options.templates.footer.cancel));

		if(this.#options.time){
			this.#footer.append(PipUI.create(this.#options.templates.footer.next));
		}else{
			this.#footer.append(PipUI.create(this.#options.templates.footer.done));
		}


		return this;
	}



	/**
	 * @return {this}
	 * */
	setActiveTime(){

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_set_tab_time'), this.#options);
		}

		if(!this.#options.time){ return this; }

		if(this.#options.date){
			PipUI.removeClass(this.#dateBlock, 'active-tab');
		}

		PipUI.addClass(this.#timeBlock, 'active-tab');


		this.#footer.innerHTML = '';

		if(this.#options.date){
			this.#footer.append(PipUI.create(this.#options.templates.footer.prev));
		}else{
			this.#footer.append(PipUI.create(this.#options.templates.footer.cancel));
		}

		this.#footer.append(PipUI.create(this.#options.templates.footer.done));


		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	show(callback){

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_show'), this.#options);
		}

		let parse = this.#options.parse(this.#form.value, this);

		if(parse){
			this.#now = parse;

			this.#current.setTime(this.#now.getTime());
		}



		if(this.#options.date){
			this.setActiveDate();
		}else if(this.#options.time){
			this.setActiveTime();
		}

		PipUI.addClass(this.#container, this.#options.showedClass);

		if(typeof callback == 'undefined'){
			callback = this.#options.showCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'show-datepicker-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	hide(callback){

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_hide'), this.#options);
		}

		PipUI.removeClass(this.#container, this.#options.showedClass);

		if(typeof callback == 'undefined'){
			callback = this.#options.hideCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'hide-datepicker-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this} */
	updateValue(callback){

		if(typeof this.#selected == 'undefined'){
			this.#selected = new Date();
		}

		this.#selected.setTime(this.#now.getTime());

		this.#form.value = this.#options.formating(this.#now, this);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_update_value'), this.#options, this.#form.value);
		}

		if(typeof callback != 'function'){
			callback = this.#options.changeCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'change-datepicker-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {number} day
	 *
	 * @return {this}
	 * */
	setDay(day){

		this.#now.setTime(this.#current.getTime());

		this.#now.setDate(day);

		this.#current.setDate(day);

		return this;
	}



	/**
	 * @param {number} year
	 *
	 * @return {this}
	 * */
	setYear(year){
		this.#current.setFullYear(year);

		return this;
	}



	/** @return {this} */
	nextYear(){
		return this.setYear(this.#current.getFullYear() + 1);
	}



	/** @return {this} */
	prevYear(){
		return this.setYear(this.#current.getFullYear() - 1);
	}



	/**
	 * @param {number} month
	 *
	 * @return {this}
	 * */
	setMonth(month){
		this.#current.setMonth(month);

		return this;
	}



	/** @return {this} */
	nextMonth(){
		return this.setMonth(this.#current.getMonth() + 1);
	}



	/** @return {this} */
	prevMonth(){
		return this.setMonth(this.#current.getMonth() - 1);
	}



	/** @return {this} */
	setHours(num){
		this.#current.setHours(num);

		return this;
	}



	/** @return {this} */
	setMinutes(num){
		this.#current.setMinutes(num);

		return this;
	}



	/** @return {this} */
	setSeconds(num){
		this.#current.setSeconds(num);

		return this;
	}



	/** @return {Date} */
	getValue(){
		return this.#current;
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	update(callback) {

		if(this.#options.date){
			if(this.#options.year){
				this.#year.value = this.#current.getFullYear();
			}

			if(this.#options.month){
				this.#month.value = this.#current.getMonth();
			}

			if(this.#options.day){
				if(typeof this.#dayBlock == 'undefined'){
					this.#dayBlock = PipUI.create(this.#options.templates.date.day);

					this.#dateBlock.append(this.#dayBlock);
				}

				this.#dayBlock.innerHTML = '';

				let daysInPrev = PipUI.Date.daysInMonth(this.#current.getFullYear(), this.#current.getMonth()-1);

				let daysInCurrent = PipUI.Date.daysInMonth(this.#current.getFullYear(), this.#current.getMonth());

				this.#tmp.setFullYear(this.#current.getFullYear())
				this.#tmp.setMonth(this.#current.getMonth());
				this.#tmp.setDate(1);

				let startDay = this.#tmp.getDay();

				if(startDay == 0){ startDay = 7; }

				startDay = 7 - (7 - startDay) - 1;

				let selected = -1;

				if(typeof this.#now != 'undefined' && this.#now.getMonth() == this.#current.getMonth() && this.#now.getFullYear() == this.#current.getFullYear()){
					selected = this.#now.getDate();
				}

				let i, day;

				let num = 0;

				for(i = daysInPrev - startDay + 1; i <= daysInPrev; i++){
					day = PipUI.create('<div class="day-id day-prev" data-datepicker-day-num="'+i+'">'+i+'</div>');

					this.#dayBlock.append(day);

					num++;
				}

				for(i = 1; i <= daysInCurrent; i++){
					day = PipUI.create('<div class="day-id" data-datepicker-day-num="'+i+'">'+i+'</div>');

					if(selected == i){
						PipUI.addClass(day, 'day-active')
					}

					this.#dayBlock.append(day);

					num++;
				}

				for(i = 1; i <= 42 - num; i++){
					day = PipUI.create('<div class="day-id day-next" data-datepicker-day-num="'+i+'">'+i+'</div>');

					this.#dayBlock.append(day);
				}
			}
		}

		if(this.#options.time){
			if(this.#options.hours){
				this.#hours.innerHTML = PipUI.Date.leadingZero(this.#current.getHours());

				this.#inputs.hours.value = this.#current.getHours();
			}

			if(this.#options.minutes){
				this.#minutes.innerHTML = PipUI.Date.leadingZero(this.#current.getMinutes());

				this.#inputs.minutes.value = this.#current.getMinutes();
			}

			if(this.#options.seconds){
				this.#seconds.innerHTML = PipUI.Date.leadingZero(this.#current.getSeconds());

				this.#inputs.seconds.value = this.#current.getSeconds();
			}
		}

		if(typeof callback == 'undefined'){
			callback = this.#options.updateCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#form, 'update-datepicker-pipui', this.#id, this.#options, this);

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('datepicker.d_update'), this.#options, this.#now);
		}

		return this;
	}
}

PipUI.ready(document, () => {

	PipUI.body('click', '.datepicker .year-block .year-next, .datepicker .year-block .year-prev', (e, target) => {
		e.preventDefault();

		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		if(PipUI.hasClass(target, 'year-prev')){
			picker.prevYear().update();
		}else{
			picker.nextYear().update();
		}
	});

	PipUI.body('input', '.datepicker .year-block .year-input', (e, target) => {
		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		picker.setYear(target.value).update();
	});

	PipUI.body('click', '.datepicker .month-block .month-next, .datepicker .month-block .month-prev', (e, target) => {
		e.preventDefault();

		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		if(PipUI.hasClass(target, 'month-prev')){
			picker.prevMonth().update();
		}else{
			picker.nextMonth().update();
		}
	});

	PipUI.body('input', '.datepicker .month-block .month-input', (e, target) => {
		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		picker.setMonth(target.value).update();
	});

	PipUI.body('focusin', '[data-datepicker]', (e, target) => {
		e.preventDefault();

		target.blur();

		let id = target.getAttribute('data-datepicker-id');

		let options = {};

		let format = target.getAttribute('data-datepicker-format');

		let type = target.getAttribute('data-datepicker');

		if(format){
			options.format = format;
		}

		if(type == 'time'){
			options.date = false;
		}else if(type == 'date'){
			options.time = false;
		}

		let picker = id ? PipUI.Storage.get('datepicker', id) : new DatepickerComponent(target, options);

		if(id){
			picker.show().update();
		}else{
			setTimeout(() => picker.show().update(), 150);
		}
	});

	PipUI.body('click', '.datepicker', (e, target) => {
		if(e.target == target){
			e.preventDefault();

			PipUI.Storage.get('datepicker', target.getAttribute('data-datepicker-id')).hide();
		}
	});

	PipUI.body('click', '.datepicker .datepicker-cancel', (e, target) => {
		e.preventDefault();

		PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id')).hide();
	});

	PipUI.body('click', '.datepicker .datepicker-done', (e, target) => {
		e.preventDefault();

		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		picker.updateValue().hide();
	});

	PipUI.body('click', '.datepicker .datepicker-change-tab', (e, target) => {
		e.preventDefault();

		let tab = target.getAttribute('data-datepicker-tab');

		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		if(tab == 'time'){
			picker.setActiveTime();
		}else{
			picker.setActiveDate();
		}
	});

	PipUI.body('click', '.datepicker .date-block > .day-block > .day-id', (e, target) => {
		e.preventDefault();

		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		let day = target.getAttribute('data-datepicker-day-num');

		if(PipUI.hasClass(target, 'day-prev')){
			picker.prevMonth();
		}else if(PipUI.hasClass(target, 'day-next')){
			picker.nextMonth();
		}

		picker.setDay(day).update();
	});

	PipUI.body('input', '.datepicker .hours-input', (e, target) => {
		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		picker.setHours(target.value).update();
	});

	PipUI.body('input', '.datepicker .minutes-input', (e, target) => {
		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		picker.setMinutes(target.value).update();
	});

	PipUI.body('input', '.datepicker .seconds-input', (e, target) => {
		let picker = PipUI.Storage.get('datepicker', target.closest('.datepicker').getAttribute('data-datepicker-id'));

		picker.setSeconds(target.value).update();
	});
});

if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Datepicker', DatepickerComponent.VERSION);
	PipUI.required('Datepicker', 'Date', '1.0.0', '>=');
	PipUI.required('Datepicker', 'Storage', '1.0.0', '>=');
	/** @return {DatepickerComponent} */
	PipUI.Datepicker = DatepickerComponent;

	PipUI.i18n()

		.set('datepicker.next', 'Далее')
		.set('datepicker.prev', 'Назад')
		.set('datepicker.done', 'Готово')
		.set('datepicker.cancel', 'Отмена')
		.set('datepicker.d_create_instance', '[Datepicker] Создание объекта')
		.set('datepicker.d_element_not_found', '[Datepicker] Инициатор не найден')
		.set('datepicker.d_set_tab_time', '[Datepicker] Измениение вкладки на вкладку времени')
		.set('datepicker.d_set_tab_date', '[Datepicker] Изменение вкладки на вкладку даты')
		.set('datepicker.d_show', '[Datepicker] Открытие окна выбора даты/времени')
		.set('datepicker.d_hide', '[Datepicker] Закрытие окна выбора даты/времени')
		.set('datepicker.d_update_value', '[Datepicker] Изменение значения формы')
		.set('datepicker.d_update', '[Datepicker] Обновление всех значений');
}
class ImageComponent {
    static VERSION = '1.0.0';

    /** @return {HTMLElement} */
    static #lightbox;

    /** @return {HTMLElement} */
    static #target;

    /** @return {HTMLElement} */
    static #loader;

    /** @return {HTMLElement} */
    static #prev;

    /** @return {HTMLElement} */
    static #next;

    /** @return {HTMLElement} */
    static #current;

    static #list = [];

    static #options = {
        debug: false,
        openCallback: undefined,
        closeCallback: undefined,
        nextCallback: undefined,
        prevCallback: undefined,
        templates: {
            modal: '<div class="image-lightbox">' +
                        '<div class="image-lightbox-wrapper">' +
                            '<img class="image-lightbox-target" src="" alt="Image not found" />' +
                            '<div class="image-lightbox-loader"><i class="fa-solid fa-spinner fa-spin-pulse"></i></div>' +
                            '<div class="image-lightbox-prev"></div>' +
                            '<div class="image-lightbox-next"></div>' +
                        '</div>' +
                    '</div>'
        }
    }



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    static setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    static getOptions() {
        return this.#options;
    }


    static #loadCallback = () => {
        PipUI.removeClass(this.#lightbox, 'image-lightbox-loading');
    };


    static #init(){
        if(typeof this.#lightbox != 'undefined'){ return false; }

        let self = this;

        this.#lightbox = PipUI.create(this.#options.templates.modal);

        this.#target = this.#lightbox.querySelector('.image-lightbox-target');

        this.#loader = this.#lightbox.querySelector('.image-lightbox-loader');

        this.#prev = this.#lightbox.querySelector('.image-lightbox-prev');

        this.#next = this.#lightbox.querySelector('.image-lightbox-next');

        this.#prev.setAttribute('title', PipUI.i18n().get('image.prev'));

        this.#next.setAttribute('title', PipUI.i18n().get('image.next'));

        document.body.append(this.#lightbox);

        this.#target.addEventListener('load', this.#loadCallback);

        this.#prev.addEventListener('click', () => self.prev());

        this.#next.addEventListener('click', () => self.next());

        return true;
    }


    static #cb = (self) => {
        PipUI.addClass(self.#lightbox, 'image-lightbox-active');

        if(typeof self.#options.openCallback == 'function'){
            self.#options.openCallback(self, self.#current);
        }
    }



    /**
     * @param {string} url
     *
     * @param {options|undefined} options
     * */
    static open(url, options){

        /*if(typeof this.#options == 'undefined'){
            options = this.#optionsPreset;
        }*/

        this.setOptions(options);

        let self = this;

        let init = this.#init();

        this.#current = url;

        this.#target.setAttribute('src', '');

        this.#target.setAttribute('src', url);

        PipUI.addClass(this.#lightbox, 'image-lightbox-loading');

        let selector = this.#options.group ? '[data-image][data-image-group="'+this.#options.group+'"]' : '[data-image]:not([data-image-group])';

        this.#list = [];

        document.querySelectorAll(selector).forEach(item => {
            self.#list.push(item.getAttribute('data-image'));
        });

        if(!PipUI.hasClass(this.#lightbox)){

            if(PipUI.Logger && this.#options.debug){
                PipUI.Logger.info(PipUI.i18n().get('image.d_open'), this.#options, this.#current);
            }

            if(init){
                setTimeout(() => this.#cb(this), 0);
            }else{
                this.#cb(this)
            }
        }
    }

    static close(){

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('image.d_close'), this.#options);
        }

        PipUI.removeClass(this.#lightbox, 'image-lightbox-active');

        if(typeof this.#options.closeCallback == 'function'){
            this.#options.closeCallback(this, this.#current);
        }
    }

    static prev(){

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('image.d_close'), this.#options);
        }

        let index = this.#list.indexOf(this.#current);

        if(index === -1){ return this.close(); }

        index--;

        if(typeof this.#options.prevCallback == 'function'){
            this.#options.prevCallback(this, index);
        }

        typeof this.#list[index] == 'undefined' ? this.close() : this.open(this.#list[index]);
    }

    static next(){

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('image.d_next'), this.#options);
        }

        let index = this.#list.indexOf(this.#current);

        if(index === -1){ return this.close(); }

        index++;

        if(typeof this.#options.nextCallback == 'function'){
            this.#options.nextCallback(this, index);
        }

        typeof this.#list[index] == 'undefined' ? this.close() : this.open(this.#list[index]);
    }

}

PipUI.ready(document, () => {

    PipUI.body('click', '[data-image]', (e, target) => {
        e.preventDefault();

        ImageComponent.open(target.getAttribute('data-image'), {
            group: target.getAttribute('data-image-group')
        });
    });

    PipUI.body('click', '.image-lightbox', e => {
        if(PipUI.hasClass(e.target, 'image-lightbox')){
            e.preventDefault();

            ImageComponent.close();
        }
    });
});

if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Image', ImageComponent.VERSION);
    /** @return {ImageComponent} */
    PipUI.Image = ImageComponent;

    PipUI.i18n()
        .set('image.d_open', '[Image] Открытие окна изображения')
        .set('image.d_close', '[Image] Закрытие окна изображения')
        .set('image.d_next', '[Image] Переключение на следующее изображение')
        .set('image.d_prev', '[Image] Переключение на предыдущее изображение')
        .set('image.next', 'Следующее изрображение')
        .set('image.prev', 'Предыдущее изображение');
}
class GalleryComponent {
    static VERSION = '1.0.0';

    /** @return {HTMLElement} */
    #container;

    /** @return {HTMLElement} */
    #wrapper;

    /** @return {HTMLElement} */
    #list;

    /** @return {HTMLElement} */
    #img;

    /** @return {HTMLElement} */
    #info;

    /** @return {HTMLElement} */
    #title;

    /** @return {HTMLElement} */
    #text;

    /** @return {HTMLElement} */
    #previewWrapper;

    #id;

    #options = {
        debug: false,
        images: [],

        scroll: true,

        changeCallback: undefined,

        changedCallback: undefined,

        updateCallback: undefined,

        pushCallback: undefined,

        unshiftCallback: undefined,

        useImage: false,

        active: 0,

        templates: {
            wrapper: '<div class="gallery-wrapper">' +
                        '<div class="gallery-preview">' +
                            '<a href="#" rel="nofollow" target="_blank" class="gallery-preview-wrapper">' +
                                '<img src="" alt="PREVIEW" />' +
                            '</a>' +

                            '<div class="gallery-info">' +
                                '<div class="gallery-info-title"></div>' +
                                '<div class="gallery-info-text"></div>' +
                            '</div>' +
                        '</div>' +

                        '<div class="gallery-list"></div>' +
                    '</div>',

            image: '<div class="gallery-item">' +
                        '<div class="gallery-item-image"></div>' +
                        '<div class="gallery-item-title"></div>' +
                    '</div>'
        }
    }


    #loadCallback;



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    getOptions() {
        return this.#options;
    }



    /** @return {string|undefined} */
    getID() {
        return this.#id;
    }



    /**
     * @param {HTMLElement|string} object
     *
     * @param {object|undefined} options
     *
     * @param {boolean} update
     * */
    constructor(object, options, update) {
        this.#container = PipUI.e(object)[0];

        this.setOptions(options);

        options = this.getOptions();

        if(typeof this.#container == 'undefined'){
            if(PipUI.Logger && options.debug){
                PipUI.Logger.info(PipUI.i18n().get('gallery.d_element_not_found'), options);
            }

            return this;
        }

        if(PipUI.Logger && options.debug){
            PipUI.Logger.info(PipUI.i18n().get('gallery.d_create_instance'), options);
        }

        this.#id = Math.random().toString();

        this.#container.setAttribute('data-gallery-id', this.#id);

        this.update();

        let self = this;

        new ResizeObserver(function(){
            self.updateScoll();
        }).observe(self.#container);

        PipUI.Storage.set('gallery', this, this.#id);
    }



    /**
     * @return {this}
     * */
    update() {

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('gallery.d_update'), this.#options);
        }

        let self = this;

        this.#container.innerHTML = '';

        this.#wrapper = PipUI.create(this.#options.templates.wrapper);

        this.#list = this.#wrapper.querySelector('.gallery-list');

        this.#previewWrapper = this.#wrapper.querySelector('.gallery-preview-wrapper');

        if(typeof this.#img != 'undefined' && typeof this.#loadCallback != 'undefined'){
            this.#img.removeEventListener('load', this.#loadCallback);
        }

        this.#img = this.#wrapper.querySelector('.gallery-preview-wrapper > img');

        this.#loadCallback = () => {
            PipUI.removeClass(self.#container, 'gallery-loading');

            if(typeof this.#options.changedCallback == 'function'){
                this.#options.changedCallback(self);
            }

            PipUI.trigger(this.#container, 'changed-gallery-pipui', this.#options);
        };

        this.#img.addEventListener('load', this.#loadCallback);

        this.#info = this.#wrapper.querySelector('.gallery-info');

        this.#title = this.#wrapper.querySelector('.gallery-info-title');

        this.#text = this.#wrapper.querySelector('.gallery-info-text');

        this.#container.append(this.#wrapper);

        PipUI.addClass(this.#container, 'gallery');

        this.updateImages();

        this.active(this.#options.active);

        if(typeof this.#options.updateCallback == 'function'){
            this.#options.updateCallback(this);
        }

        PipUI.trigger(this.#container, 'update-gallery-pipui', this.#options);

        return this;
    }



    /**
     * @param {this} self
     *
     * @param {object} image
     *
     * @param {int} index
     *
     * @return {HTMLElement}
     * */
    #createNewImage(self, image, index) {

        let item = PipUI.create(self.#options.templates.image);

        let thumb = item.querySelector('.gallery-item-image');

        let title = item.querySelector('.gallery-item-title');

        let pic = image.thumb;

        if(typeof pic == 'undefined'){
            pic = image.large;
        }

        if(typeof pic == 'undefined'){
            pic = image.original;
        }

        PipUI.style(thumb, 'background-image', 'url('+pic+')');

        title.innerHTML = image.title;

        item.setAttribute('data-gallery-index', index);

        item.append(thumb);

        item.append(title);

        if(this.#options.useImage){
            item.append(PipUI.create('<div data-image="'+image.original+'" data-image-group="gallery-'+this.#id+'"></div>'));
        }

        return item;
    }



    /**
     * @return {this}
     * */
    updateImages() {

        let self = this;

        this.#list.innerHTML = '';

        this.#options.images.forEach((image, index) => {

            self.#list.append(self.#createNewImage(self, image, index));

        });

        PipUI.trigger(this.#container, 'update-images-gallery-pipui', this.#options);

        return this;
    }



    /**
     * @param {object} object
     *
     * @return {this}
     * */
    push(object) {
        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('gallery.d_push'), this.#options);
        }

        this.#options.images.push(object);

        this.#list.append(this.#createNewImage(this, object, this.#options.images.length));

        if(typeof this.#options.pushCallback == 'function'){
            this.#options.pushCallback(this);
        }

        PipUI.trigger(this.#container, 'push-gallery-pipui', object, this.#options);

        return this;
    }



    /**
     * @param {object} object
     *
     * @return {this}
     * */
    unshift(object) {
        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('gallery.d_unshift'), this.#options);
        }

        this.#options.images.unshift(object);

        this.#list.prepend(this.#createNewImage(this, object, 0));

        this.#list.forEach((item, index) => {
            item.setAttribute('data-gallery-index', index);
        });

        this.#options.active = this.#options.active + 1;

        if(typeof this.#options.unshiftCallback == 'function'){
            this.#options.unshiftCallback(this);
        }

        PipUI.trigger(this.#container, 'unshint-gallery-pipui', object, this.#options);

        return this;
    }



    updateScoll(){
        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('gallery.d_update_scroll'), this.#options);
        }

        let actual = this.#list.querySelector('.gallery-item.active');

        let galleryWidth = this.#container.offsetWidth;

        let itemWidth = actual ? actual.offsetWidth / 2 : 0;

        let left = actual ? actual.offsetLeft - (galleryWidth / 2) + itemWidth : 0;

        let max = this.#list.scrollWidth - galleryWidth;

        if(left < 0){ left = 0; }

        if(left > max){ left = max; }

        PipUI.style(this.#list, {'left': -left+'px', width: this.#list.scrollWidth+'px'});

        PipUI.trigger(this.#container, 'update-scroll-gallery-pipui', this.#options);

        return this;
    }



    active(index){
        var image = this.#options.images[index];

        let self = this;

        if(typeof image == 'undefined'){ return self; }

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('gallery.d_change_active'), this.#options);
        }

        PipUI.addClass(this.#container, 'gallery-loading');

        this.#options.active = index;

        if(image.text){
            this.#text.innerHTML = image.text;

            PipUI.style(this.#text, 'display', null);
        }else{
            this.#text.innerHTML = '';

            PipUI.style(this.#text, 'display', 'none');
        }

        if(image.title){
            this.#title.innerHTML = image.title;

            PipUI.style(this.#title, 'display', null);
        }else{
            this.#title.innerHTML = '';

            PipUI.style(this.#title, 'display', 'none');
        }


        PipUI.style(this.#info, 'display', image.text || info.name ? null : 'none');


        this.#img.setAttribute('src', image.large);

        this.#img.setAttribute('alt', image.title);

        this.#previewWrapper.setAttribute('href', image.original);

        PipUI.removeClass(this.#list.querySelector('.gallery-item.active'), 'active');

        PipUI.addClass(this.#list.querySelector('.gallery-item[data-gallery-index="'+index+'"]'), 'active');

        this.updateScoll();

        if(typeof this.#options.changeCallback == 'function'){
            this.#options.changeCallback(this);
        }

        PipUI.trigger(this.#container, 'change-gallery-pipui', index, this.#options);

        return this;
    }
}

PipUI.ready(document, () => {

    let touch = false;

    PipUI.body('click', '.gallery .gallery-preview-wrapper', (e, target) => {

        let id = target.closest('.gallery').getAttribute('data-gallery-id');

        if(id){
            let gallery = PipUI.Storage.get('gallery', id);

            let options = gallery.getOptions();

            if(options.useImage){
                e.preventDefault();

                PipUI.Image.open(target.getAttribute('href'), {
                    group: 'gallery-'+id,
                    nextCallback: (obj, index) => gallery.active(index),
                    prevCallback: (obj, index) => gallery.active(index),
                    closeCallback: obj => obj.setOptions({nextCallback: undefined, prevCallback: undefined, closeCallback: undefined})
                });
            }
        }
    });

    PipUI.body('click', '.gallery .gallery-item[data-gallery-index]', (e, target) => {
        e.preventDefault();

        let id = target.closest('.gallery').getAttribute('data-gallery-id');

        if(id){
            PipUI.Storage.get('gallery', id).active(target.getAttribute('data-gallery-index'));
        }
    });

    PipUI.body('mousedown', '.gallery .gallery-list', (e, list) => {
        e.preventDefault();

        let id = list.closest('.gallery').getAttribute('data-gallery-id');

        if(id){
            let gallery = PipUI.Storage.get('gallery', id);

            if(gallery.getOptions().scroll){
                touch = {
                    transition: PipUI.style(list, 'transition'),
                    left: parseInt(PipUI.style(list, 'left')[0].left),
                    clientX: e.clientX,
                    list: list
                };

                PipUI.style(list, 'transition', 'none');
            }
        }
    });

    window.addEventListener('mousemove', e => {
        if(touch !== false){

            var left = touch.left + (e.clientX - touch.clientX);

            left *= -1;

            if(left < 0){ left = 0; }

            var max = touch.list.scrollWidth - touch.list.closest('.gallery').offsetWidth;

            if(left > max){ left = max; }

            PipUI.style(touch.list, 'left', -left+'px');
        }
    });

    window.addEventListener('mouseup', e => {

        if(touch !== false){
            e.preventDefault();

            PipUI.style(touch.list, 'transition', touch.transition);

            touch = false;
        }
    });
});


if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Gallery', GalleryComponent.VERSION);
    PipUI.required('Gallery', 'Storage', '1.0.0', '>=');
    /** @return {GalleryComponent} */
    PipUI.Gallery = GalleryComponent;

    PipUI.i18n()
        .set('gallery.d_element_not_found', '[Gallery] Модальное окно не найдено')
        .set('gallery.d_create_instance', '[Gallery] Создание объекта')
        .set('gallery.d_update', '[Gallery] Полное обновление объекта')
        .set('gallery.d_change_active', '[Gallery] Изменение активного элемента')
        .set('gallery.d_push', '[Gallery] Добавление изображения в конец')
        .set('gallery.d_unshift', '[Gallery] Добавление изображения в начало')
        .set('gallery.d_update_scroll', '[Gallery] Обновление позиции скролла');
}
class PopupComponent {
    static VERSION = '2.0.0';

    #id;

    #target;

    #triggers = [];

    #box;

    #overlay;

    #autocloseTimeout = undefined;

    #triggerCallback;

    /** @return {HTMLElement} */
    #title;

    /** @return {HTMLElement} */
    #content;

    #oldPosition;

    #options = {
        debug: false,

        target: undefined,

        triggers: [],

        title: '',

        content: '',

        direction: 'up',

        overlay: false,

        autoclose: 0,

        overclose: true,

        openedClass: 'popup-active',

        openedOverlayClass: 'overlay-active',

        openedTriggerClass: 'active',

        targetClass: 'popup-target',

        targetActiveClass: 'popup-target-active',

        openCallback: undefined,

        closeCallback: undefined,

        updateCallback: undefined,

        repositionCallback: undefined,

        templates: {
            box: '<div class="popup">' +
                        '<div class="popup-title"></div>' +
                        '<div class="popup-content"></div>' +
                    '</div>',
            overlay: '<div class="popup-overlay"></div>'
        }
    }



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    getOptions() {
        return this.#options;
    }



    /** @return {string|undefined} */
    getID() {
        return this.#id;
    }



    /**
     * @param {HTMLElement|string} box
     *
     * @param {object|undefined} options
     * */
    constructor(box, options) {
        this.setOptions(options);

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('popup.d_create_instance'), this.#options);
        }

        this.#id = Math.random().toString();

        if(box){
            this.#box = PipUI.e(box)[0];
        }else{
            this.#box = PipUI.create(this.#options.templates.box);

            document.body.append(this.#box);
        }

        this.#box.setAttribute('data-popup-id', this.#id);

        let self = this;

        this.#target = PipUI.e(this.#options.target)[0];

        if(!this.#target){
            if(PipUI.Logger && this.#options.debug){
                PipUI.Logger.info(PipUI.i18n().get('popup.d_target_not_found'), this.#options);
            }

            return;
        }


        this.#target.setAttribute('data-popup-id', this.#id);

        this.#title = this.#box.querySelector('.popup-title');

        this.#content = this.#box.querySelector('.popup-content');

        window.addEventListener('resize', () => {
            if(self.isOpen()){ self.updatePosition(); }
        });

        document.addEventListener('scroll', () => {
            if(self.isOpen()){ self.updatePosition(); }
        });

        this.update();

        PipUI.Storage.set('popup', this, this.#id);
    }



    /**
     * @param {string} name
     *
     * @return {object} */
    getPosition(name){
        let top = 0, left = 0;

        switch(name){
            case 'left':
                top = this.#target.offsetTop - (this.#box.offsetHeight / 2) + (this.#target.offsetHeight / 2);
                left = this.#target.offsetLeft - this.#box.offsetWidth;
                break;

            case 'right':
                top = this.#target.offsetTop - (this.#box.offsetHeight / 2) + (this.#target.offsetHeight / 2);
                left = this.#target.offsetLeft + this.#target.offsetWidth;
                break;

            case 'up':
                top = this.#target.offsetTop - this.#box.offsetHeight;
                left = this.#target.offsetLeft + (this.#target.offsetWidth / 2) - (this.#box.offsetWidth / 2);
                break;

            case 'down':
                top = this.#target.offsetTop + this.#target.offsetHeight;
                left = this.#target.offsetLeft + (this.#target.offsetWidth / 2) - (this.#box.offsetWidth / 2);
                break;
        }

        return {
            top: top,
            left: left
        };
    }



    updatePosition(){

        let offset = {
            top: this.#target.offsetTop - window.scrollY,
            bottom: 0,
            left: this.#target.offsetLeft - window.scrollX,
            right: 0
        };

        offset.bottom = window.innerHeight - (this.#target.offsetTop - window.scrollY + this.#target.offsetHeight);

        offset.right = window.innerWidth - (this.#target.offsetLeft - window.scrollX + this.#target.offsetWidth);

        let pos;

        if(this.#options.direction == 'left'){
            if(offset.left >= this.#box.offsetWidth){
                pos = 'left';
            }else if(offset.right >= this.#box.offsetWidth){
                pos = 'right';
            }else if(offset.top >= this.#box.offsetHeight){
                pos = 'up';
            }else if(offset.bottom >= this.#box.offsetHeight){
                pos = 'down';
            }else{
                pos = 'left';
            }
        }else if(this.#options.direction == 'right'){
            if(offset.right >= this.#box.offsetWidth){
                pos = 'right';
            }else if(offset.left >= this.#box.offsetWidth){
                pos = 'left';
            }else if(offset.top >= this.#box.offsetHeight){
                pos = 'up';
            }else if(offset.bottom >= this.#box.offsetHeight){
                pos = 'down';
            }else{
                pos = 'right';
            }
        }else if(this.#options.direction == 'down'){
            if(offset.bottom >= this.#box.offsetHeight){
                pos = 'down';
            }else if(offset.top >= this.#box.offsetHeight){
                pos = 'up';
            }else if(offset.right >= this.#box.offsetWidth){
                pos = 'right';
            }else if(offset.left >= this.#box.offsetWidth){
                pos = 'left';
            }else {
                pos = 'down';
            }
        }else{
            if(offset.top >= this.#box.offsetHeight){
                pos = 'up';
            }else if(offset.bottom >= this.#box.offsetHeight){
                pos = 'down';
            }else if(offset.right >= this.#box.offsetWidth){
                pos = 'right';
            }else if(offset.left >= this.#box.offsetWidth){
                pos = 'left';
            }else {
                pos = 'up';
            }
        }

        let positions = this.getPosition(pos);

        PipUI.style(this.#box, {top: positions.top+'px', left: positions.left+'px'});

        this.#box.setAttribute('data-popup-direction', pos);

        if(typeof this.#options.repositionCallback == 'function'){
            this.#options.repositionCallback(this, positions, pos);
        }

        PipUI.trigger(this.#box, 'update-position-popup-pipui', this.#id, this.#options, this);

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('popup.d_update_position'), this.#id, this.#options, positions, pos);
        }

        return this;
    }



    /** * @return {this} */
    update(){

        if(!this.#options.title){
            this.#options.title = this.#title.innerHTML;
        }

        if(!this.#options.content){
            this.#options.content = this.#content.innerHTML;
        }

        let trigger = this.#options.triggers[0];

        if(typeof trigger != 'undefined'){
            if(!this.#options.title){
                this.#options.title = trigger.getAttribute('data-popup-title') ?? '';
            }

            if(!this.#options.content){
                this.#options.content = trigger.getAttribute('data-popup-content') ?? '';
            }

            let direction = trigger.getAttribute('data-popup-direction');

            if(direction){
                this.#options.direction = direction;
            }

            let autoclose = trigger.getAttribute('data-popup-autoclose');

            if(autoclose){
                this.#options.autoclose = parseInt(autoclose);
            }

            let overlay = trigger.getAttribute('data-popup-overlay');

            if(overlay){
                this.#options.overlay = overlay == 'true';
            }

            let overclose = trigger.getAttribute('data-popup-overclose');

            if(overclose){
                this.#options.overclose = overclose == 'true';
            }

            trigger.setAttribute('data-popup-direction', this.#options.direction);
        }


        this.#overlay = document.querySelector('.popup-overlay');

        if(!this.#overlay && this.#options.overlay){
            this.#overlay = PipUI.create(this.#options.templates.overlay);

            document.body.append(this.#overlay);
        }

        if(this.#options.title){
            this.#title.innerHTML = this.#options.title;

            PipUI.style(this.#title, 'display', null);
        }else{
            PipUI.style(this.#title, 'display', 'none');
        }

        if(this.#options.content){
            this.#content.innerHTML = this.#options.content;

            PipUI.style(this.#content, 'display', null);
        }else{
            PipUI.style(this.#content, 'display', 'none');
        }

        let self = this;

        this.#triggers.forEach(trigger => {
            trigger.removeEventListener('click', self.#triggerCallback);
        });

        self.#triggerCallback = e => {
            e.preventDefault();

            if(!self.isOpen()){
                self.open();
            }else{
                self.close();
            }
        }

        this.#triggers = [];

        this.#options.triggers.forEach(trigger => {
            trigger.setAttribute('data-popup-id', self.#id);

            trigger.addEventListener('click', self.#triggerCallback);

            this.#triggers.push(trigger);
        });

        if(typeof this.#options.updateCallback == 'function'){
            this.#options.updateCallback(this);
        }

        PipUI.trigger(this.#box, 'update-popup-pipui', this.#id, this.#options, this);

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('popup.d_update'), this.#id, this.#options);
        }

        return this;
    }


    /** @return {boolean} */
    isOpen(){
        return PipUI.hasClass(this.#box, this.#options.openedClass);
    }



    /**
     * @param {function|undefined} callback
     *
     * @return {this} */
    close(callback) {

        if(!this.isOpen()){ return this; }

        PipUI.removeClass(this.#options.triggers, this.#options.openedTriggerClass);

        PipUI.removeClass(this.#box, this.#options.openedClass);

        PipUI.removeClass(this.#target, this.#options.targetActiveClass);

        if(this.#overlay){
            PipUI.removeClass(this.#overlay, this.#options.openedOverlayClass);

            if(this.#oldPosition == 'static'){
                PipUI.style(this.#box, {
                    'position': this.#oldPosition
                });
            }
        }

        if(typeof callback == 'undefined'){
            callback = this.#options.closeCallback;
        }

        if(typeof callback == 'function'){
            callback(this);
        }

        PipUI.trigger(this.#box, 'close-popup-pipui', this.#id, this.#options, this);

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('popup.d_close'), this.#id, this.#options);
        }

        return this;
    }



    /**
     * @param {function|undefined} callback
     *
     * @return {this} */
    open(callback){

        this.updatePosition();

        PipUI.addClass(this.#options.triggers, this.#options.openedTriggerClass);

        PipUI.addClass(this.#box, this.#options.openedClass);

        PipUI.addClass(this.#target, this.#options.targetClass);

        PipUI.addClass(this.#target, this.#options.targetActiveClass);

        if(this.#options.overlay){
            PipUI.addClass(this.#overlay, this.#options.openedOverlayClass);

            this.#oldPosition = PipUI.style(this.#box, 'position')[0].position;

            if(this.#oldPosition == 'static'){
                PipUI.style(this.#box, {'position': 'relative'});
            }
        }else{
            if(this.#overlay){
                PipUI.removeClass(this.#overlay, this.#options.openedOverlayClass);
            }
        }

        if(typeof callback == 'undefined'){
            callback = this.#options.closeCallback;
        }

        if(typeof callback == 'function'){
            callback(this);
        }

        PipUI.trigger(this.#box, 'open-popup-pipui', this.#id, this.#options, this);

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('popup.d_open'), this.#id, this.#options);
        }

        if(this.#options.autoclose > 0){
            let that = this;

            if(typeof this.#autocloseTimeout != 'undefined'){
                clearTimeout(this.#autocloseTimeout);
                this.#autocloseTimeout = undefined;
            }

            this.#autocloseTimeout = setTimeout(() => {
                that.close();
                that.#autocloseTimeout = undefined;
            }, this.#options.autoclose);
        }

        return this;
    }
}

PipUI.ready(document, () => {

    PipUI.body('click', '[data-popup]:not([data-popup-id])', (e, trigger) => {
        e.preventDefault();

        let target = trigger.getAttribute('data-popup-target');

        let options = {
            triggers: [trigger]
        };

        options.target = target ? target : trigger;

        let popup = new PopupComponent(trigger.getAttribute('data-popup'), options);

        setTimeout(() => popup.open(), 100);
    });

    document.addEventListener('click', e => {

        document.querySelectorAll('.popup.popup-active[data-popup-id]').forEach(item => {
            let target = e.target.closest('[data-popup-id]');

            if(target === null){
                let popup = PipUI.Storage.get('popup', item.getAttribute('data-popup-id'));

                if(popup.getOptions().overclose){
                    popup.close();
                }
            }else{
                let id = target.getAttribute('data-popup-id');

                let popup = PipUI.Storage.get('popup', item.getAttribute('data-popup-id'));

                if(popup.getID() != id && popup.getOptions().overclose){
                    popup.close();
                }
            }
        });
    });

    PipUI.body('click', '.popup-overlay.overlay-active', e => {
        e.preventDefault();

        let popups = PipUI.Storage.get('popup');

        Object.keys(popups).forEach(key => {
            if(popups[key].isOpen()){ popups[key].close(); }
        });
    });
});


if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Popup', PopupComponent.VERSION);
    PipUI.required('Popup', 'Storage', '1.0.0', '>=');
    /** @return {PopupComponent} */
    PipUI.Popup = PopupComponent;


    PipUI.i18n()
        .set('popup.d_create_instance', '[Popup] Создание объекта')
        .set('popup.d_target_not_found', '[Popup] Не найден элемент назначения')
        .set('popup.d_open', '[Popup] Открытие всплывающего блока')
        .set('popup.d_close', '[Popup] Закрытие всплывающего блока')
        .set('popup.d_update', '[Popup] Обновление всплывающего блока')
        .set('popup.d_update_position', '[Popup] Обновление позиции всплывающего блока');
}
class TagselectorComponent {
    static VERSION = '2.0.0';

    #id;

    /** @return {HTMLElement} */
    #input;

    /** @return {HTMLElement} */
    #container;

    /** @return {HTMLElement} */
    #list;

    /** @return {HTMLElement} */
    #hidden;

    #options = {
        debug: false,

        min: 1,

        max: 32,

        maxTags: 0,

        pattern: undefined,

        insert: 'start',

        values: [],

        keys: [',', 'Enter'],

        unique: true,

        hidden: 'tags',

        pushCallback: undefined,

        unshiftCallback: undefined,

        clearCallback: undefined,

        setCallback: undefined,

        removeCallback: undefined,

        updateCallback: undefined,

        errorCallback: undefined,

        tagselectorClass: 'tagselector',

        templates: {
            container: '<div class="tagselector"><ul class="tagselector-list"></ul></div>',
            tag: '<div class="tagselector-item"></div>'
        }
    }


    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }


    /** @return {object} */
    getOptions() {
        return this.#options;
    }


    /** @return {string|undefined} */
    getID() {
        return this.#id;
    }


    /**
     * @param {HTMLElement|string} input
     *
     * @param {object|undefined} options
     * */
    constructor(input, options) {
        this.#input = PipUI.e(input)[0];

        this.setOptions(options);

        if (typeof this.#input == 'undefined') {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_element_not_found'), this.#options);
            }

            return this;
        }

        if (PipUI.Logger && this.#options.debug) {
            PipUI.Logger.info(PipUI.i18n().get('tagselector.d_create_instance'), this.#options);
        }

        this.#id = Math.random().toString();

        this.#input.setAttribute('data-tagselector-id', this.#id);

        this.#container = PipUI.create(this.#options.templates.container);

        this.#container.setAttribute('data-tagselector-id', this.#id);

        this.#hidden = PipUI.create('<input type="hidden" class="tagselector-hidden" name="' + this.#options.hidden + '">');

        PipUI.addClass(this.#container, this.#options.tagselectorClass);

        this.#list = this.#container.querySelector('.tagselector-list');

        this.#input.after(this.#container);

        this.#input.after(this.#hidden);

        PipUI.addClass(this.#input, 'tagselector-input');

        this.#input.addEventListener('keypress', e => {
            if (this.#options.keys.indexOf(e.key) !== -1) {
                this.add(this.#input.value);
            }
        });

        PipUI.Storage.set('tagselector', this, this.#id);
    }


    /**
     * @return {HTMLElement|undefined}
     * */
    #precompile(string) {
        string = string.toString();

        if (!string.trim()) {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_err1'), this.#options);
            }

            if (typeof this.#options.errorCallback == 'function') {
                this.#options.errorCallback(this, 1, PipUI.i18n().get('tagselector.d_err1'));
            }

            return false;
        }

        if (this.#options.unique && this.search(string) !== -1) {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_err2'), this.#options);
            }

            if (typeof this.#options.errorCallback == 'function') {
                this.#options.errorCallback(this, 2, PipUI.i18n().get('tagselector.d_err2'));
            }

            return false;
        }

        if (this.#options.min > 0 && string.length < this.#options.min) {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_err3') + this.#options.min, this.#options);
            }

            if (typeof this.#options.errorCallback == 'function') {
                this.#options.errorCallback(this, 3, PipUI.i18n().get('tagselector.d_err3') + this.#options.min);
            }

            return false;
        }

        if (this.#options.max > 0 && string.length > this.#options.max) {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_err4') + this.#options.max, this.#options);
            }

            if (typeof this.#options.errorCallback == 'function') {
                this.#options.errorCallback(this, 4, PipUI.i18n().get('tagselector.d_err4') + this.#options.max);
            }

            return false;
        }

        if (this.#options.maxTags > 0 && this.#options.values.length >= this.#options.maxTags) {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_err5') + this.#options.maxTags, this.#options);
            }

            if (typeof this.#options.errorCallback == 'function') {
                this.#options.errorCallback(this, 5, PipUI.i18n().get('tagselector.d_err5') + this.#options.maxTags);
            }

            return false;
        }

        if (this.#options.pattern && !this.#options.pattern.test(string)) {
            if (PipUI.Logger && this.#options.debug) {
                PipUI.Logger.info(PipUI.i18n().get('tagselector.d_err6'), this.#options);
            }

            if (typeof this.#options.errorCallback == 'function') {
                this.#options.errorCallback(this, 6, PipUI.i18n().get('tagselector.d_err6'));
            }

            return false;
        }

        let tag = PipUI.create(this.#options.templates.tag);

        tag.setAttribute('data-tagselector-item-id', Math.random().toString());

        tag.innerHTML = string;

        return tag;
    }



    /**
     * @param {string} string
     *
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    add(string, callback) {
        return this.#options.insert == 'start' ? this.unshift(string, callback) : this.push(string, callback);
    }



    /**
     * @param {string} string
     *
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    push(string, callback) {
        let tag = this.#precompile(string);

        if (tag === false) {
            return this;
        }

        this.#options.values.push(string);

        this.#list.append(tag);

        this.#input.value = '';

        this.#hidden = this.#options.values.join(',');

        if (PipUI.Logger && this.#options.debug) {
            PipUI.Logger.info(PipUI.i18n().get('tagselector.d_push'), string, this.#options);
        }

        if (typeof callback == 'undefined') {
            callback = this.#options.pushCallback;
        }

        if (typeof callback == 'function') {
            callback(this, string);
        }

        PipUI.trigger(this.#input, 'push-tagselector-pipui', string, this.#id, this.#options, this);

        return this;
    }


    /**
     * @param {string} string
     *
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    unshift(string, callback) {
        let tag = this.#precompile(string);

        if (tag === false) {
            return this;
        }

        this.#options.values.unshift(string);

        this.#list.prepend(tag);

        this.#input.value = '';

        this.#hidden = this.#options.values.join(',');

        if (PipUI.Logger && this.#options.debug) {
            PipUI.Logger.info(PipUI.i18n().get('tagselector.d_unshift'), string, this.#options);
        }

        if (typeof callback == 'undefined') {
            callback = this.#options.unshiftCallback;
        }

        if (typeof callback == 'function') {
            callback(this, string);
        }

        PipUI.trigger(this.#input, 'unshift-tagselector-pipui', string, this.#id, this.#options, this);

        return this;
    }


    /**
     *
     * @param {string} id
     *
     * @param {string} string
     *
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    set(id, string, callback) {

        let tag = this.#list.querySelector('.tagselector-item[data-tagselector-item-id="' + id + '"]');

        if (!tag) {
            return this;
        }

        tag.innerHTML = string;

        if (PipUI.Logger && this.#options.debug) {
            PipUI.Logger.info(PipUI.i18n().get('tagselector.d_set'), id, string, this.#options);
        }

        if (typeof callback == 'undefined') {
            callback = this.#options.setCallback;
        }

        if (typeof callback == 'function') {
            callback(this, id, string);
        }

        PipUI.trigger(this.#input, 'set-tagselector-pipui', id, string, this.#id, this.#options, this);

        return this;
    }


    /**
     * @param {string} id
     *
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    remove(id, callback) {

        let tag = this.#list.querySelector('.tagselector-item[data-tagselector-item-id="' + id + '"]');

        if (!tag) {
            return this;
        }

        tag.remove();

        let index = this.search(tag.innerHTML);

        if (index === -1) {
            return this;
        }

        this.#options.values.splice(index, 1);

        if (PipUI.Logger && this.#options.debug) {
            PipUI.Logger.info(PipUI.i18n().get('tagselector.d_remove'), id, this.#options);
        }

        if (typeof callback == 'undefined') {
            callback = this.#options.removeCallback;
        }

        if (typeof callback == 'function') {
            callback(this, id, index);
        }

        PipUI.trigger(this.#input, 'remove-tagselector-pipui', id, index, this.#id, this.#options, this);

        return this;
    }


    /**
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    clear(callback) {

        this.#options.values = [];

        this.#list.innerHTML = '';

        if (PipUI.Logger && this.#options.debug) {
            PipUI.Logger.info(PipUI.i18n().get('tagselector.d_clear'), this.#options);
        }

        if (typeof callback == 'undefined') {
            callback = this.#options.clearCallback;
        }

        if (typeof callback == 'function') {
            callback(this);
        }

        PipUI.trigger(this.#input, 'clear-tagselector-pipui', this.#id, this.#options, this);

        return this;
    }


    /**
     * @param {string} string
     *
     * @return {int}
     * */
    search(string) {
        return this.#options.values.indexOf(string);
    }
}

PipUI.ready(document, () => {

    PipUI.body('click', '.tagselector[data-tagselector-id] .tagselector-item', (e, target) => {
        e.preventDefault();

        let id = target.closest('[data-tagselector-id]').getAttribute('data-tagselector-id');

        PipUI.Storage.get('tagselector', id).remove(target.getAttribute('data-tagselector-item-id'));
    });

    PipUI.body('focusin', '[data-tagselector]:not([data-tagselector-id])', (e, target) => {

        let values = target.value.length > 0 ? target.value.split(',') : [];

        let keys = target.getAttribute('data-tagselector-keys');

        let unique = target.getAttribute('data-tagselector-unique');

        let insert = target.getAttribute('data-tagselector-insert');

        let min = target.getAttribute('data-tagselector-min');

        let max = target.getAttribute('data-tagselector-max');

        let maxTags = target.getAttribute('data-tagselector-max-tags');

        let pattern = target.getAttribute('data-tagselector-pattern');

        let options = {
            values: values
        };

        if(keys){
            options.keys = keys.split('+');
        }

        if(unique){
            options.unique = unique == 'true';
        }

        if(insert){
            options.insert = insert;
        }

        if(min){
            options.min = parseInt(min);
        }

        if(max){
            options.max = parseInt(max);
        }

        if(maxTags){
            options.maxTags = parseInt(maxTags);
        }

        if(pattern){
            options.pattern = parseInt(pattern);
        }

        new TagselectorComponent(target, options);
    });

});


if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Tagselector', TagselectorComponent.VERSION);
    PipUI.required('Tagselector', 'Storage', '1.0.0', '>=');
    /** @return {TagselectorComponent} */
    PipUI.Tagselector = TagselectorComponent;


    PipUI.i18n()
        .set('tagselector.d_create_instance', '[Tagselector] Создание объекта')
        .set('tagselector.d_element_not_found', '[Tagselector] Форма ввода не найдена')
        .set('tagselector.d_err1', '[Tagselector] Необходимо заполнить поле формы')
        .set('tagselector.d_err2', '[Tagselector] Тег должен быть уникальным')
        .set('tagselector.d_err3', '[Tagselector] Минимальное кол-во символов в теге: ')
        .set('tagselector.d_err4', '[Tagselector] Максимальное кол-во символов в теге: ')
        .set('tagselector.d_err5', '[Tagselector] Максимальное кол-во тегов: ')
        .set('tagselector.d_err6', '[Tagselector] Тег не соответствует паттерну')
        .set('tagselector.d_push', '[Tagselector] Добавления тега в конец списка')
        .set('tagselector.d_unshift', '[Tagselector] Добавления тега в начало списка')
        .set('tagselector.d_set', '[Tagselector] Обновление тега')
        .set('tagselector.d_remove', '[Tagselector] Удаление тега')
        .set('tagselector.d_clear', '[Tagselector] Очистка всех тегов');
}
class SliderComponent {
	static VERSION = '2.0.0';

	#id;

	/** @return {HTMLElement} */
	#list;

	/** @return {HTMLElement} */
	#container;

	/** @return {HTMLElement} */
	#labels;

	#pause = false;

	#lock = false;

	#pauseTimer;

	#pauseStart;

	#pauseStop = 0;

	#mouseEnterEvent;

	#mouseOutEvent;

	#active = 0;

	#options = {
		debug: false,

		slides: [],

		updateCallback: undefined,

		changeCallback: undefined,

		pauseCallback: undefined,

		resumeCallback: undefined,

		playCallback: undefined,

		duration: 3000,

		animation: 'slide',

		arrows: true,

		labels: true,

		swipe: true,

		pause: true,

		templates: {
			list: '<div class="slider-list"></div>',
			slide: '<div class="slider-slide"></div>',
			arrowLeft: '<div class="slider-arrow" data-slider-arrow="prev"><i class="fa-solid fa-angle-left"></i></div>',
			arrowRight: '<div class="slider-arrow" data-slider-arrow="next"><i class="fa-solid fa-angle-right"></i></div>',
			label: '<div class="slider-label"></div>'
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} container
	 *
	 * @param {object|undefined} options
	 * */
	constructor(container, options) {
		this.#container = PipUI.e(container)[0];

		this.setOptions(options);

		if(typeof this.#container == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('slider.d_element_not_found'), this.#options);
			}

			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('slider.d_create_instance'), this.#options);
		}

		this.#id = Math.random().toString();

		this.#container.setAttribute('data-slider-id', this.#id);

		this.update();

		PipUI.Storage.set('slider', this, this.#id);
	}



	/**
	 * @param {object} object
	 *
	 * @return {HTMLElement}
	 * */
	#createSlide(object){

		let slide = PipUI.create(this.#options.templates.slide);

		if(object.img){
			PipUI.style(slide, 'background-image', 'url('+object.img+')');
		}

		if(object.content){
			slide.innerHTML = object.content;
		}

		slide.setAttribute('data-slider-slide-id', Math.random().toString());

		return slide;
	}



	/**
	 * @return {this}
	 *
	 * @param {function} callback
	 *
	 * */
	update(callback){

		let self = this;

		if(typeof this.#mouseEnterEvent != 'undefined'){
			this.#container.removeEventListener('mouseenter', this.#mouseEnterEvent);

			this.#container.removeEventListener('mouseout', this.#mouseOutEvent);
		}

		this.#container.setAttribute('data-slider-animation', this.#options.animation);

		this.#container.innerHTML = '';

		if(this.#options.arrows){
			this.#container.append(PipUI.create(this.#options.templates.arrowLeft), PipUI.create(this.#options.templates.arrowRight));
		}

		if(this.#options.labels){
			this.#labels = PipUI.create('<div class="slider-labels"></div>');

			for(let i = 0; i < this.#options.slides.length; i++){
				let label = PipUI.create(this.#options.templates.label);

				if(this.#active == i){ PipUI.addClass(label, 'active'); }

				label.setAttribute('data-slider-label-index', i);

				this.#labels.append(label);
			}

			this.#container.append(this.#labels);
		}

		PipUI.addClass(this.#container, 'slider');

		this.#list = PipUI.create(this.#options.templates.list);

		this.#options.slides.forEach((obj, index) => {
			let slide = this.#createSlide(obj);

			if(index === this.#active){
				PipUI.addClass(slide, 'active');
			}

			this.#list.append(slide);
		});

		this.#container.append(this.#list);

		if(this.#options.duration > 0){
			this.#mouseEnterEvent = () => {
				self.pause();
			}

			this.#mouseOutEvent = (e) => {
				if(!e.explicitOriginalTarget.parentElement.closest('.slider[data-slider-id="'+self.#id+'"]')){
					self.resume();
				}
			}

			this.#container.addEventListener('mouseenter', this.#mouseEnterEvent);

			this.#container.addEventListener('mouseout', this.#mouseOutEvent);

			this.play(this.#options.duration, true);
		}

		if(typeof callback != 'function'){
			callback = this.#options.updateCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#container, 'update-slider-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {int} index
	 *
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	setSlide(index, callback){

		index = parseInt(index);

		if(index == this.#active || this.#lock){ return this; }

		if(typeof this.#options.slides[index] == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('slider.d_slide_not_found'), index, this.#options);
			}

			return this;
		}

		let self = this;

		this.#lock = true;

		let slides = this.#list.querySelectorAll('.slider-slide');

		let activeClass = index > this.#active ? 'slide-animation-left' : 'slide-animation-right';

		if(index == 0 && this.#active == this.#options.slides.length-1){
			activeClass = 'slide-animation-left';
		}

		if(this.#active == 0 && index == this.#options.slides.length-1){
			activeClass = 'slide-animation-right';
		}

		if(this.#options.labels){
			let labels = this.#labels.querySelectorAll('.slider-label');

			PipUI.removeClass(labels, 'active');

			PipUI.addClass(labels[index], 'active');
		}

		PipUI.addClass([slides[this.#active], slides[index]], activeClass);

		setTimeout(() => {
			PipUI.addClass(this.#container, 'slide-animation');

			let cb = () => {

				PipUI.removeClass(self.#container, 'slide-animation');

				PipUI.removeClass(slides, 'active slide-animation-left slide-animation-right');

				PipUI.addClass(slides[index], 'active');

				slides[self.#active].removeEventListener('transitionend', cb);

				self.#active = index;

				self.#lock = false;

				if(typeof callback != 'function'){
					callback = this.#options.changeCallback;
				}

				if(typeof callback == 'function'){
					callback(self);
				}

				PipUI.trigger(this.#container, 'change-slider-pipui', index, self.#id, self.#options, self);

				if(self.#options.duration > 0){
					self.play(self.#options.duration, true);
				}
			}

			slides[this.#active].addEventListener('transitionend', cb);
		}, 0);

		return this;
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	next(callback){
		let next = this.#active + 1 >= this.#options.slides.length ? 0 : this.#active + 1;

		return this.setSlide(next, callback);
	}



	/**
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	prev(callback){
		let next = this.#active - 1 < 0 ? this.#options.slides.length-1 : this.#active - 1;

		return this.setSlide(next, callback);
	}



	/**
	 *
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	pause(callback){
		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('slider.d_pause_timer'), this.#options);
		}

		this.#pause = true;

		this.#pauseStop = Date.now() - this.#pauseStart + this.#pauseStop;

		if(typeof this.#pauseTimer != 'undefined'){
			clearTimeout(this.#pauseTimer);

			this.#pauseTimer = undefined;
		}

		this.#container.setAttribute('data-slider-pause', 'true');

		if(typeof callback != 'function'){
			callback = this.#options.pauseCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#container, 'pause-slider-pipui', this.#id, this.#options, this);

		return this;
	}



	/**
	 *
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	resume(callback){
		this.#pause = false;

		this.#container.setAttribute('data-slider-pause', 'false');

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('slider.d_resume_timer'), this.#options);
		}

		if(typeof callback != 'function'){
			callback = this.#options.resumeCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#container, 'resume-slider-pipui', this.#id, this.#options, this);

		return this.play(this.#options.duration);
	}



	/**
	 * @param {int} delay
	 *
	 * @param {boolean} clear
	 *
	 * @param {function} callback
	 *
	 * @return {this}
	 * */
	play(delay, clear, callback){

		if(!this.#options.duration){ return this; }

		let self = this;

		if(clear){
			this.#pauseStop = 0;

			if(typeof this.#pauseTimer != 'undefined'){

				if(PipUI.Logger && this.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('slider.d_clear_timer'), this.#options);
				}

				clearTimeout(this.#pauseTimer);
				this.#pauseTimer = undefined;
			}
		}

		if(this.#pause){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('slider.d_create_timer'), this.#options);
		}

		this.#pauseStart = Date.now();

		this.#pauseTimer = setTimeout(function(){
			self.next();
		}, delay - this.#pauseStop);

		if(typeof callback != 'function'){
			callback = this.#options.playCallback;
		}

		if(typeof callback == 'function'){
			callback(this);
		}

		PipUI.trigger(this.#container, 'play-slider-pipui', delay, clear, this.#id, this.#options, this);

		return this;
	}
}

PipUI.ready(document, () => {

	PipUI.body('click', '.slider > .slider-arrow', (e, target) => {
		e.preventDefault();

		let id = target.closest('[data-slider-id]').getAttribute('data-slider-id');

		let slider = PipUI.Storage.get('slider', id);

		if(target.getAttribute('data-slider-arrow') == 'prev'){
			slider.prev();
		}else{
			slider.next();
		}
	});

	PipUI.body('click', '.slider > .slider-labels > .slider-label', (e, target) => {
		e.preventDefault();

		let id = target.closest('[data-slider-id]').getAttribute('data-slider-id');

		let slider = PipUI.Storage.get('slider', id);

		slider.setSlide(target.getAttribute('data-slider-label-index'));
	});

});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Slider', SliderComponent.VERSION);
	PipUI.required('Slider', 'Storage', '1.0.0', '>=');
	/** @return {SliderComponent} */
	PipUI.Slider = SliderComponent;


	PipUI.i18n()
		.set('slider.d_create_instance', '[Slider] Создание объекта')
		.set('slider.d_element_not_found', '[Slider] Слайд не найден')
		.set('slider.d_pause_timer', '[Slider] Пауза слайдера')
		.set('slider.d_resume_timer', '[Slider] Продолжение работы слайдера')
		.set('slider.d_clear_timer', '[Slider] Очистка таймера слайдера')
		.set('slider.d_create_timer', '[Slider] Запуск таймера');
}
class AutocompleteComponent {
	static VERSION = '2.0.0';

	#id;

	/** @return {HTMLElement} */
	#list;

	/** @return {HTMLElement} */
	input;

	/** @return {HTMLElement} */
	#container;

	#options = {
		debug: false,

		source: {
			url: '',
			method: 'GET',
			key: 'value',
			extra: undefined
		},

		list: [],

		min: 2,

		maxItems: 10,

		updateCallback: undefined,

		nextCallback: undefined,

		prevCallback: undefined,

		updatePosition: undefined,

		requestCallback: undefined,

		choiseCallback: undefined,

		templates: {
			list: '<ul class="autocomplete-list"></ul>',
			item: '<div class="autocomplete-item"></div>',
			container: '<div class="autocomplete"></div>'
		}
	}



	/**
	 * @param {object} options
	 *
	 * @return {this}
	 * */
	setOptions(options) {
		this.#options = PipUI.assign(this.#options, options);

		return this;
	}



	/** @return {object} */
	getOptions() {
		return this.#options;
	}



	/** @return {string|undefined} */
	getID() {
		return this.#id;
	}



	/**
	 * @param {HTMLElement|string} input
	 *
	 * @param {object|undefined} options
	 * */
	constructor(input, options) {
		this.input = PipUI.e(input)[0];

		this.setOptions(options);

		if(typeof this.input == 'undefined'){
			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_element_not_found'), this.#options);
			}

			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_create_instance'), this.#options);
		}

		this.#id = Math.random().toString();

		this.#container = PipUI.create(this.#options.templates.container);

		let url = this.input.getAttribute('data-autocomplete-url');

		let method = this.input.getAttribute('data-autocomplete-method');

		let key = this.input.getAttribute('data-autocomplete-key');

		let min = this.input.getAttribute('data-autocomplete-min');

		let maxItems = this.input.getAttribute('data-autocomplete-max-items');

		let list = this.input.getAttribute('data-autocomplete-list');

		if(url){ this.#options.source.url = url; }

		if(method){ this.#options.source.method = method; }

		if(key){ this.#options.source.key = key; }

		if(min){ this.#options.min = min; }

		if(maxItems){ this.#options.maxItems = maxItems; }

		if(list){ this.#options.list = list.split(';'); }

		PipUI.addClass(this.input, 'autocomplete-input');

		this.input.setAttribute('data-autocomplete-id', this.#id);

		this.input.setAttribute('autocomplete', 'off');

		this.#container.setAttribute('data-autocomplete-id', this.#id);

		this.input.after(this.#container);

		this.#list = PipUI.create(this.#options.templates.list);

		this.#container.append(this.#list);

		this.#updateEvents();

		PipUI.Storage.set('autocomplete', this, this.#id);
	}



	#scroll(item) {
		this.#list.scrollTop = item.offsetTop;

		return this;
	}



	#next() {
		if(!this.isOpenedContainer()){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_next'), this.#options);
		}

		let current = this.#list.querySelector('.autocomplete-item.hover');

		PipUI.removeClass(current, 'hover');

		let item;

		if(current && current.nextElementSibling){
			item = current.nextElementSibling;
		}else{
			item = this.#list.querySelector('.autocomplete-item:first-child');
		}


		PipUI.addClass(item, 'hover');

		this.#scroll(item);

		if(typeof this.#options.nextCallback == 'function'){
			this.#options.nextCallback(this, item, current);
		}

		PipUI.trigger(this.input, 'next-autocomplete-pipui', item, current, this.#id, this.#options, this);

		return this;
	}



	#prev() {
		if(!this.isOpenedContainer()){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_prev'), this.#options);
		}

		let current = this.#list.querySelector('.autocomplete-item.hover');

		PipUI.removeClass(current, 'hover');

		let item;

		if(current && current.previousElementSibling){
			item = current.previousElementSibling;
		}else{
			item = this.#list.querySelector('.autocomplete-item:last-child');
		}

		PipUI.addClass(item, 'hover');

		this.#scroll(item);

		if(typeof this.#options.prevCallback == 'function'){
			this.#options.prevCallback(this, item, current);
		}

		PipUI.trigger(this.input, 'prev-autocomplete-pipui', item, current, this.#id, this.#options, this);

		return this;
	}



	/**
	 * @param {string} value
	 *
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	request(value, callback){

		this.#list.innerHTML = '';

		PipUI.removeClass(this.#container, 'autocomplete-active');

		if(value.length < this.#options.min){

			if(PipUI.Logger && this.#options.debug){
				PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_request'), this.#options);
			}

			return this;
		}

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_http_request'), this.#options.source.url, this.#options);
		}

		if(!this.#options.source.url){ return this.update(value); }

		let formData = new FormData();

		formData.append(this.#options.source.key, value);

		if(this.#options.source.extra){
			Object.keys(this.#options.source.extra).forEach(key => {

				formData.append(key, this.#options.source.extra[key]);
			});
		}

		let self = this;

		fetch(this.#options.source.url, {
			method: this.#options.source.method,
			body: formData,
			credentials: "same-origin"
		})
			.then(response => {

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_response_handler'), response, self.#options);
				}

				if(typeof callback == 'undefined'){
					callback = self.#options.requestCallback;
				}

				if(typeof callback == 'function'){
					callback(self, response, value);
				}

				PipUI.trigger(self.input, 'request-autocomplete-pipui', response, value, self.#id, self.#options, self);

				return response.json();
			})
			.then(result => {

				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_response_result'), result.list, self.#options);
				}

				self.#options.list = typeof result.list == 'undefined' ? [] : result.list;

				self.update(value);
			})
			.catch(error => {
				if(PipUI.Logger && self.#options.debug){
					PipUI.Logger.error(PipUI.i18n().get('autocomplete.d_request_error'), self.#options, error);
				}

				self.update(value);
			});
	}



	/**
	 * @param {string} value
	 *
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	update(value, callback){

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_update_list'), value, this.#options);
		}

		let found = 0;

		for(let i = 0; i < this.#options.list.length; i++){

			let val = this.#options.list[i];

			if(val.toLowerCase().indexOf(value.toLowerCase()) === -1){ continue; }

			found++;

			let item = PipUI.create(this.#options.templates.item);

			item.setAttribute('data-autocomplete-item-index', i);

			item.innerHTML = val;

			this.#list.append(item);

			if(found >= this.#options.maxItems){ break; }
		}

		if(found > 0){
			PipUI.addClass(this.#container, 'autocomplete-active');
		}

		if(typeof callback != 'function'){
			callback = this.#options.updateCallback;
		}

		if(typeof callback == 'function'){
			callback(this, value);
		}

		PipUI.trigger(this.input, 'update-autocomplete-pipui', value, this.#id, this.#options, this);

		return this.updatePosition();
	}



	/**
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	updatePosition(callback){

		let left = this.input.offsetLeft;

		let top = this.input.offsetTop + this.input.offsetHeight;

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_update_position'), left, top, this.#options);
		}

		PipUI.style(this.#container, {
			left: left+'px',
			top: top+'px'
		});

		if(typeof callback != 'function'){
			callback = this.#options.updatePosition;
		}

		if(typeof callback == 'function'){
			callback(this, top, left);
		}

		PipUI.trigger(this.input, 'update-position-autocomplete-pipui', top, left, this.#id, this.#options, this);

		return this;
	}



	/**
	 * @return {boolean}
	 * */
	isOpenedContainer(){
		return PipUI.hasClass(this.#container, 'autocomplete-active');
	}



	/**
	 * @param {int} index
	 *
	 * @param {function|undefined} callback
	 *
	 * @return {this}
	 * */
	choise(index, callback){
		if(!this.isOpenedContainer()){ return this; }

		if(PipUI.Logger && this.#options.debug){
			PipUI.Logger.info(PipUI.i18n().get('autocomplete.d_choise'), this.#options);
		}

		let item = this.#list.querySelector('.autocomplete-item[data-autocomplete-item-index="'+index+'"]');

		if(!item){ return this; }

		this.input.value = item.innerText;

		PipUI.removeClass(this.#container, 'autocomplete-active');

		if(typeof callback != 'function'){
			callback = this.#options.choiseCallback;
		}

		if(typeof callback == 'function'){
			callback(this, item);
		}

		PipUI.trigger(this.input, 'choise-autocomplete-pipui', item, this.#id, this.#options, this);

		return this;
	}



	#updateEvents(){
		let self = this;

		this.input.addEventListener('keydown', e => {
			if(e.key == 'ArrowDown'){
				e.preventDefault();

				self.#next();
			}else if(e.key == 'ArrowUp'){
				e.preventDefault();

				self.#prev();
			}else if(e.key == 'Enter'){
				e.preventDefault();

				let item = self.#list.querySelector('.autocomplete-item.hover');

				if(item){
					self.choise(item.getAttribute('data-autocomplete-item-index'));
				}
			}
		});

		this.input.addEventListener('input', e => {
			self.request(e.target.value);
		});

		this.input.addEventListener('focusout', e => {
			if(!e.explicitOriginalTarget.parentElement.closest('.autocomplete[data-autocomplete-id="'+self.#id+'"]')){
				PipUI.removeClass(self.#container, 'autocomplete-active');
			}
		});

		window.addEventListener('resize', () => {
			if(self.isOpenedContainer()){ self.updatePosition(); }
		});

		return this;
	}
}

PipUI.ready(document, () => {

	PipUI.body('click', '.autocomplete > .autocomplete-list > .autocomplete-item', (e, target) => {
		e.preventDefault();

		let id = target.closest('.autocomplete').getAttribute('data-autocomplete-id');

		PipUI.Storage.get('autocomplete', id).choise(target.getAttribute('data-autocomplete-item-index'));
	});

	PipUI.body('mouseover', '.autocomplete > .autocomplete-list > .autocomplete-item', (e, target) => {
		PipUI.removeClass(target.closest('.autocomplete-list').querySelectorAll('.autocomplete-item'), 'hover');

		PipUI.addClass(target, 'hover');
	});

	PipUI.body('focusin', '[data-autocomplete]:not([data-autocomplete-id])', (e, target) => {
		new AutocompleteComponent(target);
	});

});


if(typeof PipUI != 'undefined'){
	PipUI.addComponent('Autocomplete', AutocompleteComponent.VERSION);
	PipUI.required('Autocomplete', 'Storage', '1.0.0', '>=');
	/** @return {AutocompleteComponent} */
	PipUI.Autocomplete = AutocompleteComponent;


	PipUI.i18n()
		.set('autocomplete.d_create_instance', '[Autocomplete] Создание объекта')
		.set('autocomplete.d_element_not_found', '[Autocomplete] Форма ввода не найдена')
		.set('autocomplete.d_request_error', '[Autocomplete] Произошла ошибка запроса')
		.set('autocomplete.d_next', '[Autocomplete] Переключение на следующий пункт')
		.set('autocomplete.d_prev', '[Autocomplete] Переключение на предыдущий пункт')
		.set('autocomplete.d_request', '[Autocomplete] Запрос на изменение списка')
		.set('autocomplete.d_http_request', '[Autocomplete] HTTP запрос к серверу')
		.set('autocomplete.d_response_handler', '[Autocomplete] Запуск обработчика ответа')
		.set('autocomplete.d_response_result', '[Autocomplete] Изменение списка')
		.set('autocomplete.d_update_list', '[Autocomplete] Обновление DOM списка')
		.set('autocomplete.d_update_position', '[Autocomplete] Обновление позиции')
		.set('autocomplete.d_choise', '[Autocomplete] Выбор элемента списка');
}
if(typeof PipUI != 'undefined'){
    PipUI.i18n()
        .set('textarea.d_create_instance', '[Textarea] Создание объекта')
        .set('textarea.d_element_not_found', '[Textarea] Форма не найдена')
        .set('textarea.d_copy', '[Textarea] Копирование в буфер обмена')
        .set('textarea.d_update', '[Textarea] Обновление списка строк')
        .set('textarea.d_update_position', '[Textarea] Обновление позиции списка строк')
        .set('textarea.d_events', '[Textarea] Инициализациия событий')
        .set('textarea.copied', 'Скопировано!');
}

class TextareaComponent {
    static VERSION = '2.0.0';

    #id;

    /** @return {HTMLElement} */
    #copy;

    /** @return {HTMLElement} */
    #list;

    /** @return {HTMLElement} */
    #input;

    /** @return {HTMLElement} */
    #container;

    #options = {
        debug: false,

        updateCallback: undefined,

        updatePositionCallback: undefined,

        copyCallback: undefined,

        templates: {
            container: '<div class="textarea"></div>',
            list: '<ul class="textarea-list"></ul>',
            item: '<li class="textarea-list-item"></li>',
            copy: '<div class="textarea-copy">'+PipUI.i18n().get('textarea.copied')+'</div>'
        }
    }

    #copyTimeout;



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    getOptions() {
        return this.#options;
    }



    /** @return {string|undefined} */
    getID() {
        return this.#id;
    }



    /**
     * @param {HTMLElement|string} input
     *
     * @param {object|undefined} options
     * */
    constructor(input, options) {
        this.#input = PipUI.e(input)[0];

        this.setOptions(options);

        if(typeof this.#input == 'undefined'){
            if(PipUI.Logger && this.#options.debug){
                PipUI.Logger.info(PipUI.i18n().get('textarea.d_element_not_found'), this.#options);
            }

            return this;
        }

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('textarea.d_create_instance'), this.#options);
        }

        this.#id = Math.random().toString();

        this.#input.setAttribute('data-textarea-id', this.#id);

        this.#container = PipUI.create(this.#options.templates.container);

        this.#container.setAttribute('data-textarea-id', this.#id);

        this.#copy = PipUI.create(this.#options.templates.copy);

        this.#container.append(this.#copy);

        this.#input.after(this.#container);

        this.#list = PipUI.create(this.#options.templates.list);

        this.#container.append(this.#list);

        this.#container.append(this.#input);

        this.update().events();

        PipUI.Storage.set('textarea', this, this.#id);
    }

    update(){

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('textarea.d_update'), this.#options);
        }

        this.#list.innerHTML = '';

        let breaks = this.#input.value.split('\n').length;

        for(let i = 1; i <= breaks; i++){
            let item = PipUI.create(this.#options.templates.item);

            item.innerHTML = i;

            item.setAttribute('data-textarea-line', i);

            this.#list.append(item);
        }

        if(typeof this.#options.updateCallback == 'function'){
            this.#options.updateCallback(this);
        }

        return this;
    }

    updatePosition(){

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('textarea.d_update_position'), this.#input.scrollTop, this.#options);
        }

        this.#list.scrollTop = this.#input.scrollTop;

        if(typeof this.#options.updatePositionCallback == 'function'){
            this.#options.updatePositionCallback(this, this.#input.scrollTop);
        }

        return this;
    }

    events(){

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('textarea.d_events'), this.#options);
        }

        this.#input.addEventListener('input', () => this.update());

        this.#input.addEventListener('scroll', () => this.updatePosition());

        return this;
    }

    copy(line){
        if(typeof this.#copyTimeout != 'undefined'){
            clearTimeout(this.#copyTimeout);

            this.#copyTimeout = undefined;
        }

        PipUI.addClass(this.#copy, 'visible');

        line = parseInt(line);

        let lines = this.#input.value.split('\n');

        let text = lines[line-1];

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('textarea.d_copy'), text, this.#options);
        }

        let tmp = document.createElement("input");
        tmp.type = "text";
        tmp.value = text;

        document.body.appendChild(tmp);

        tmp.select();
        document.execCommand("Copy");

        document.body.removeChild(tmp);

        this.#input.focus();

        let end = 0;

        for(let i = 0; i <= line-1; i++){
            end = end + lines[i].length + 1;
        }

        end--;

        let start = end - lines[line-1].length;

        this.#input.setSelectionRange(start, end);

        this.#copyTimeout = setTimeout(() => {
            PipUI.removeClass(this.#copy, 'visible');
        }, 1000);

        if(typeof this.#options.copyCallback == 'function'){
            this.#options.copyCallback(this, text);
        }

        return this;
    }
}

PipUI.ready(document, () => {

    PipUI.body('click', '.textarea > .textarea-list > .textarea-list-item', (e, target) => {
        e.preventDefault();

        let textarea = PipUI.Storage.get('textarea', target.closest('.textarea').getAttribute('data-textarea-id'));

        textarea.copy(target.getAttribute('data-textarea-line'));
    });

});


if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Textarea', TextareaComponent.VERSION);
    PipUI.required('Textarea', 'Storage', '1.0.0', '>=');
    /** @return {TextareaComponent} */
    PipUI.Textarea = TextareaComponent;
}
class ValidatorComponent {
    static VERSION = '2.0.0';

    #id;

    /** @return {HTMLElement} */
    #input;

    /** @return {HTMLElement} */
    #container;

    /** @return {HTMLElement} */
    #box;

    #options = {
        debug: false,

        text: '',

        type: 'default',

        invalidCallback: undefined,

        templates: {
            box: '<div class="validator"></div>'
        }
    }



    /**
     * @param {object} options
     *
     * @return {this}
     * */
    setOptions(options) {
        this.#options = PipUI.assign(this.#options, options);

        return this;
    }



    /** @return {object} */
    getOptions() {
        return this.#options;
    }



    /** @return {string|undefined} */
    getID() {
        return this.#id;
    }



    /**
     * @param {HTMLElement|string} input
     *
     * @param {object|undefined} options
     * */
    constructor(input, options) {
        this.#input = PipUI.e(input)[0];

        this.setOptions(options);

        if(typeof this.#input == 'undefined'){
            if(PipUI.Logger && this.#options.debug){
                PipUI.Logger.info(PipUI.i18n().get('validator.d_element_not_found'), this.#options);
            }

            return this;
        }

        this.#container = this.#input.closest('.input-block');

        if(!this.#container){
            if(PipUI.Logger && this.#options.debug){
                PipUI.Logger.info(PipUI.i18n().get('validator.d_container_not_found'), this.#options);
            }

            return this;
        }

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('validator.d_create_instance'), this.#options);
        }

        this.#id = Math.random().toString();

        this.#input.setAttribute('data-validator-id', this.#id);

        this.#container.setAttribute('data-validator-id', this.#id);

        this.#box = PipUI.create(this.#options.templates.box);

        this.#box.setAttribute('data-validator-id', this.#id);

        this.#container.append(this.#box);

        let form = this.#input.closest('form');

        if(form){ form.setAttribute('novalidate', ''); }

        let self = this;

        this.#input.addEventListener('input', () => self.isValid() ? PipUI.removeClass(self.#box, 'visible') : '');

        PipUI.Storage.set('validator', this, this.#id);
    }



    /**
     * @return {boolean}
     * */
    isValid(){
        return !this.#input.validationMessage;
    }



    /**
     * @param {function|undefined} callback
     *
     * @return {this}
     * */
    validate(callback) {

        let valid = this.isValid();

        if(PipUI.Logger && this.#options.debug){
            PipUI.Logger.info(PipUI.i18n().get('validator.d_validate'), valid, this.#options);
        }

        PipUI.trigger(this.#input, 'invalid-validate-pipui', this, this.#id, this.#options);

        if(valid){ PipUI.removeClass(this.#box, 'visible'); return true; }

        this.#box.innerHTML = this.#options.text ? this.#options.text : this.#input.validationMessage;

        this.#input.setAttribute('data-validator-type', this.#options.type);

        PipUI.addClass(this.#box, 'visible');

        if(typeof callback == 'undefined'){
            callback = this.#options.invalidCallback;
        }

        if(typeof callback == 'function'){
            callback(this, this.#input.validationMessage);
        }

        PipUI.trigger(this.#input, 'validate-validator-pipui', this.#id, this.#options, this, this.#input.validationMessage);

        return false;
    }
}

PipUI.ready(document, () => {

    PipUI.body('focusin', '[data-validator]:not([data-validator-id])', (e, target) => {
        let options = {};

        let text = target.getAttribute('data-validator-text');

        if(text){ options.text = text; }

        let type = target.getAttribute('data-validator-type');

        if(type){ options.type = type; }

        new ValidatorComponent(target, options);
    });

    PipUI.body('submit', 'form', (e, target) => {

        let inputs = target.querySelectorAll('[data-validator]');

        let stop = false;

        inputs.forEach(input => {

            let id = input.getAttribute('data-validator-id');

            let validator;

            if(id){
                validator = PipUI.Storage.get('validator', id);
            }else{
                let options = {};

                let text = target.getAttribute('data-validator-text');

                if(text){ options.text = text; }

                let type = target.getAttribute('data-validator-type');

                if(type){ options.type = type; }

                validator = new ValidatorComponent(input, options);
            }

            if(!validator.validate()){
                stop = true;
            }
        });

        if(stop){ e.preventDefault(); e.stopPropagation(); }
    });

});


if(typeof PipUI != 'undefined'){
    PipUI.addComponent('Validator', ValidatorComponent.VERSION);
    PipUI.required('Validator', 'Storage', '1.0.0', '>=');
    /** @return {ValidatorComponent} */
    PipUI.Validator = ValidatorComponent;


    PipUI.i18n()
        .set('validator.d_create_instance', '[Validator] Создание объекта')
        .set('validator.d_element_not_found', '[Validator] Форма ввода не найдена')
        .set('validator.d_container_not_found', '[Validator] Контейнер не найден')
        .set('validator.d_validate', '[Validator] Проверка формы');
}